import React from 'react';
import styled from 'styled-components';

const DatepickerContent = styled.input`
  display: block;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  color: #4A4A4A;
  border: solid 1px #E0E0E0;
  background-image: url(/assets/img/svg/ic-daterange.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: right 0.75rem center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  padding: 7px 16px;
  height: 38px;
`

const CustomDatePicker = (props, openCalendar, closeCalendar) => (
    <DatepickerContent {...props} onClick={()=>openCalendar} />
  );

export default CustomDatePicker;
