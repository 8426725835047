  export const latest5VerifiedOrderColumns = [
    {
      Header: 'Order Number',
      accessor: 'order_number', // accessor is the "key" in the data
    },
    {
      Header: 'Thumbnail',
      accessor: 'image',
    },
    {
      Header: 'Clinic',
      accessor: 'clinic_name',
    },
    {
      Header: 'Order Status',
      accessor: 'order_status',
    },
    {
      Header: 'Estimated Delivery',
      accessor: 'date_of_estimated_delivery',
    },
  ];

  export const pendingCompletionColumns = [
    {
      Header: 'Order Number',
      accessor: 'order_number', // accessor is the "key" in the data
    },
    {
      Header: 'Order Status',
      accessor: 'order_status',
    },
    {
      Header: 'Delivered',
      accessor: 'date_delivered',
    },
  ];
