import React, { ReactNode } from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  border: 1px solid #E0E0E0;
  border-radius: 4px;
`
const CardHeaderWrapper = styled.div`
  background: #FFECCA;
  border-radius: 4px 4px 0px 0px;
  padding: 13px 0 13px 16px;
`
const CardHeader = styled.h2`
  margin-bottom: 0;
`
const CardContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`

interface Props {
  children?: ReactNode;
  header?: ReactNode | string;
  style?: any;
  headerStyle?: any;
}

export default function Card({ header, children, style, headerStyle }: Props) {
  return (
    <CardWrapper style={style}>
      <CardHeaderWrapper style={headerStyle}>
        <CardHeader>
          {header}
        </CardHeader>
      </CardHeaderWrapper>
      <CardContentWrapper>
        {children}
      </CardContentWrapper>
    </CardWrapper>
  )
}