import React, { useState, useEffect, useCallback } from 'react';
import DateTime from 'react-datetime'
import BounceLoader from 'react-spinners/BounceLoader';
import 'react-datetime/css/react-datetime.css';
import CustomSelect from '../../components/custom-select/custom-select.jsx';
import OrdersTable from './order-table.jsx';
import { toast } from 'react-toastify';
import Pagination from '../../components/pagination/pagination.jsx';
import { orderColumns2 } from './table-meta';
import { orderStatusOption } from '../../components/order-status/order-status.jsx';
import CustomDatePicker from '../../components/custom-datepicker/custom-datepicker.jsx';
import * as amberSdk from '../../open-api';
import { OrdersRetrievalOrders, OrderStatus, PagingMetadata } from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { getEncodeOrUndefined, getFromDateUndefinedOrISO, getRequestConfig, getSortDirection, getToDateUndefinedOrISO, ISelectOptionItem } from '../../common/utils';
import {
  EntriesDiv, FilterColumn, FilterRow, ListContentWrapper,
  ListHeader, ListHeaderWrapper, ListWrapper, LoaderWrapper,
  SearchDiv, SearchInput, SearchOptionRow
} from '../../common/styles';
import mainAxios from '../../config/axios-config';

const entryOptions = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
];

export function getStatusFilterArray(selectedStatus: OrderStatus | string | undefined) {
  if (selectedStatus === '' ||
    selectedStatus === undefined) {
    return [];
  }
  return [selectedStatus as OrderStatus];
}

export function getSortColumn(selectedColumn: string | undefined) {
  switch (selectedColumn) {
    case ('order_number'):
      return 'ORDER_NUMBER' as const;
    case ('status'):
      return 'STATUS' as const;
    case ('date_of_order'):
      return 'DATE_OF_ORDER' as const;
    case ('remarks'):
      return 'REMARKS' as const;
    case ('branch_code'):
      return 'BRANCH_CODE' as const;
    case ('clinic_name'):
      return 'CLINIC_NAME' as const;
    case ('date_of_estimated_delivery'):
      return 'DATE_OF_ESTIMATED_DELIVERY' as const;
    case ('order_status'):
      return 'STATUS' as const;
    default:
      return undefined;
  }
}

interface Props {
  compoundingTech_id?: string
}

function OrderList({ compoundingTech_id }: Props) {
  const [fromDate, setFromDate] = useState<string | undefined>(undefined);
  const [toDate, setToDate] = useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [statusFilter, setStatusFilter] = useState<OrderStatus | string | undefined>(undefined);
  const [branchFilter, setBranchFilter] = useState<string | undefined>(undefined);
  const [branchFilterList, setBranchFilterList] = useState<Array<ISelectOptionItem>>([]);
  const [sortColumn, setSortColumn] = useState<string | undefined>('date_of_order');
  const [sortDirection, setSortDirection] = useState<string | undefined>('desc');
  const [reloadData, setReloadData] = useState<boolean>(true);
  const [data, setData] = useState<Array<OrdersRetrievalOrders>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageMeta, setPageMeta] = useState<PagingMetadata>({
    page: 1,
    per_page: 10,
    total_page: 1,
    total_count: 0,
  });
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const setCurrentPageCallback = useCallback((selectedPage: number) => {
    setCurrentPage(selectedPage);
  }, []);

  function onSort(column: string) {
    if (column === 'image' ||
      column === 'order_status'
    ) {
      return;
    }
    if (sortColumn === column && sortDirection === 'asc') {
      setSortDirection('desc')
    } else {
      setSortDirection('asc')
    }
    if (sortColumn !== column) {
      setSortColumn(column);
    }
    setReloadData(!reloadData);
  }

  const userStore = useUserStore();

  useEffect(() => {
    let isSubscribed = true;

    if (!userStore.accessToken) {
      return;
    }
    let branchApi: amberSdk.BranchApi = new amberSdk.BranchApi(undefined, undefined, mainAxios);
    branchApi.branchesGET(1, 20, undefined, undefined, undefined, undefined, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed)
        setBranchFilterList(
          e.data.branches.map((e) => {
            return {
              value: e.name,
              label: e.name,
            }
          })
        )
    }).catch(() => { });

    return () => {
      isSubscribed = false
    };
  }, [userStore.accessToken])

  useEffect(() => {
    let isSubscribed = true;
    if (!userStore.accessToken) {
      return;
    }

    setLoading(true);

    // pull the data here and set loading to false when completed
    if (compoundingTech_id) {
      let compoundingTechApi: amberSdk.CompoundingTechApi = new amberSdk.CompoundingTechApi(undefined, undefined, mainAxios);

      // let compoundingTechApi: amberSdk.CompoundingTechApi = new amberSdk.CompoundingTechApi(undefined, undefined, mainAxios);
      compoundingTechApi.compoundingTechOrdersGET(compoundingTech_id,
        currentPage, rowsPerPage, getEncodeOrUndefined(searchTerm),
        getSortColumn(sortColumn), getSortDirection(sortDirection),
        getRequestConfig(userStore.accessToken)
      ).then((e) => {
        if (isSubscribed) {
          setData(e.data.orders)
          setPageMeta(e.data.paging_metadata)
        }
      }).catch((e) => {
        if (e.response && e.response.status === 500) {
          toast.error('An error has occured. Please try again later.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (isSubscribed)
          setData([])
      }).finally(() => {
        if (isSubscribed)
          setLoading(false);
      });
    } else {
      let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios);

      orderApi.ordersGET(currentPage, rowsPerPage,
        getFromDateUndefinedOrISO(fromDate), getToDateUndefinedOrISO(toDate),
        getStatusFilterArray(statusFilter), getEncodeOrUndefined(branchFilter), undefined,
        getEncodeOrUndefined(searchTerm), getSortColumn(sortColumn), getSortDirection(sortDirection),
        getRequestConfig(userStore.accessToken)
      ).then((e) => {
        if (isSubscribed) {
          setData(e.data.orders)
          setPageMeta(e.data.paging_metadata)
        }
      }).catch((e) => {
        if (e.response && e.response.status === 500) {
          toast.error('An error has occured. Please try again later.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (isSubscribed)
          setData([])
      }).finally(() => {
        if (isSubscribed)
          setLoading(false);
      });
    }

    return () => {
      isSubscribed = false
    };
  }, [userStore.accessToken, currentPage, rowsPerPage,
    fromDate, toDate, statusFilter, branchFilter,
    searchTerm, reloadData, sortColumn, sortDirection, compoundingTech_id])

  return (
    <div>
      <h1 className="page-header">Orders</h1>

      <ListWrapper>
        <ListHeaderWrapper>
          <ListHeader>List of Orders</ListHeader>
        </ListHeaderWrapper>

        <ListContentWrapper>
          {/* filters */}

          {!compoundingTech_id &&
            (<FilterRow>
              <FilterColumn>
                <h3>Date Filter</h3>
                <DateTime
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.toString());
                    setCurrentPage(1);
                  }}
                  inputProps={{ placeholder: 'From' }}
                  closeOnSelect={true}
                  timeFormat={false}
                  dateFormat="DD MMM YYYY"
                  renderInput={CustomDatePicker} />
              </FilterColumn>
              <FilterColumn>
                <h3>&nbsp;</h3>
                <DateTime
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                    setCurrentPage(1);
                  }}
                  inputProps={{ placeholder: 'To' }}
                  closeOnSelect={true}
                  timeFormat={false}
                  dateFormat="DD MMM YYYY"
                  renderInput={CustomDatePicker} />
              </FilterColumn>
              <FilterColumn>
                <h3>Order Status</h3>
                <CustomSelect
                  onChange={(e: any) => {
                    setStatusFilter(e ? e.value : undefined);
                    setCurrentPage(1);
                  }}
                  options={orderStatusOption}
                  isClearable={true}
                  placeholder="Select Order Status" />
              </FilterColumn>
              <FilterColumn>
                <h3>Amber Branch</h3>
                <CustomSelect
                  onChange={(e: any) => {
                    setBranchFilter(e ? e.value : undefined);
                    setCurrentPage(1);
                  }}
                  options={branchFilterList}
                  isClearable={true}
                  placeholder="Select Amber Branch" />
              </FilterColumn>
            </FilterRow>)
          }
          {/* options and search */}
          <SearchOptionRow>
            <div className="col-auto">
              <EntriesDiv>
                <span style={{ paddingRight: "8px" }} className='p1'>Show</span>
                <CustomSelect onChange={(e: any) => {
                  setRowsPerPage(e.value);
                  setCurrentPage(1);
                }} options={entryOptions} defaultValue={{ label: "10", value: 10 }} />
                <span style={{ paddingLeft: "8px" }} className='p1'>entries</span>
              </EntriesDiv>
            </div>
            <div className="col-auto">
              <SearchDiv>
                <span style={{ paddingRight: "8px" }} className='p1'>Search</span>
                <SearchInput onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  let newValue = e.currentTarget.value;
                  setSearchTerm(newValue);
                  setCurrentPage(1);
                }}
                  placeholder="Search Order # and Clinic Name" />
              </SearchDiv>
            </div>
          </SearchOptionRow>

          {/* main table */}
          <div>
            {
              loading ?
                <LoaderWrapper>
                  <BounceLoader
                    color={"#FFA300"}
                    loading={loading}
                    size={48}
                    speedMultiplier={1}
                  />
                </LoaderWrapper> :
                <OrdersTable data={data} columns={orderColumns2} onSort={onSort} sortOptions={{
                  sortColumn, sortDirection
                }} />
            }
          </div>

          {/* pagination */}
          <div style={{ marginTop: "16px" }}>
            <Pagination pageChangeHandler={setCurrentPageCallback} pageMeta={pageMeta} />
          </div>
        </ListContentWrapper>
      </ListWrapper>
    </div>
  )
};

export default OrderList;