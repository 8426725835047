import update from 'immutability-helper';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { containsLetter, containsNumber, countryCodeOptionList, getRequestConfig, ISelectOptionItem, IsValidEmail } from '../../common/utils';
import CustomSelect from '../../components/custom-select/custom-select';
import PasswordCriteria from '../../components/password/password-criteria';
import mainAxios from '../../config/axios-config';
import * as amberSdk from '../../open-api';
import { UserProfileUpdate, UserRetrievalRolesEnum } from '../../open-api';
import { useUserStore } from '../../store/userStore';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  width: 100%;
  height: 32px;
`
const UpdateButton = styled.button`
  padding: 8px 0;
  width: 100%;
  background: #FFA300;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const emptyUser: UserProfileUpdate = {
  email: '',
  name: '',
  mobile_contact: {
    country_calling_code: '+65',
    phone_number: '',
  },
  update_password: {
    current_password: '',
    new_password: '',
  }
}

function UpdateProfile() {
  const history = useHistory();

  const [newUser, setNewUser] = useState<UserProfileUpdate>(emptyUser);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Array<UserRetrievalRolesEnum>>([]);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const userStore = useUserStore();

  /**
   * load all page data
   */
  useEffect(() => {

    if (!userStore.accessToken) {
      return
    }

    let profileApi: amberSdk.ProfileApi = new amberSdk.ProfileApi(undefined, undefined, mainAxios)

    profileApi.meGET(getRequestConfig(userStore.accessToken)).then((e) => {
      setNewUser({
        name: e.data.name,
        mobile_contact: {
          country_calling_code: e.data.mobile_contact.country_calling_code,
          phone_number: e.data.mobile_contact.phone_number,
        },
        email: e.data.email,
        update_password: {
          current_password: '',
          new_password: '',
        }
      });
      setRoles(e.data.roles);
      setConfirmNewPassword('');
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_current_password') {
          setCurrentPasswordError(true);
        }
        if (e.response.data.error === 'invalid_new_password') {
          setNewPasswordError(true);
        }
      }
    }).finally(() => {
      setLoading(false);
    });

  }, [userStore.accessToken, reloadData])


  function handleInputChange(field: string, value: any) {
    let tempOrder = update(newUser, {
      [field]: { $set: value }
    })
    setNewUser(tempOrder);
    if (field === 'name') {
      if (value) {
        if (nameError) {
          setNameError(false)
        }
      } else {
        if (!nameError) {
          setNameError(true)
        }
      }
    } else if (field === 'email') {
      if (IsValidEmail(value)) {
        if (emailError) {
          setEmailError(false)
        }
      } else {
        if (!emailError) {
          setEmailError(true)
        }
      }
    }
  }

  function handleMobileChange(field: string, value: any) {
    let tempOrder = update(newUser, {
      mobile_contact: {
        [field]: { $set: value }
      }
    })
    setNewUser(tempOrder);
    if (mobileError) {
      setMobileError(false)
    }
  }

  function handlePasswordChange(field: string, value: any) {
    let tempOrder = update(newUser, {
      update_password: {
        [field]: { $set: value }
      }
    })
    setNewUser(tempOrder);
    if (field === 'confirmPassword') {
      if (value === newUser.update_password?.new_password) {
        setConfirmPasswordError(false)
      } else {
        setConfirmPasswordError(true)
      }
    }

    if (field === 'current_password') {
      if(currentPasswordError){
        setCurrentPasswordError(false)
      }
    }
    
    if (field === 'new_password') {
      if(newPasswordError){
        setNewPasswordError(false)
      }
    }
  }

  function validationError(): boolean {
    let haveError = false;
    if (!newUser.name) {
      if (!nameError) {
        setNameError(true)
      }
      haveError = true;
    } else {
      if (nameError) {
        setNameError(false)
      }
    }

    if (!newUser.mobile_contact.phone_number) {
      if (!mobileError) {
        setMobileError(true)
      }
      haveError = true;
    } else {
      if (mobileError) {
        setMobileError(false)
      }
    }

    if (!IsValidEmail(newUser.email)) {
      if (!emailError) {
        setEmailError(true)
      }
      haveError = true;
    } else {
      if (emailError) {
        setEmailError(false)
      }
    }

    if (newUser.update_password?.current_password
      || newUser.update_password?.new_password
      || confirmNewPassword
    ) {

      if (!newUser.update_password?.current_password) {
        if (!currentPasswordError) {
          setCurrentPasswordError(true);
        }
        haveError = true
      } else {
        if (currentPasswordError) {
          setCurrentPasswordError(false);
        }
      }

      if ((!containsLetter(newUser.update_password?.new_password)) || (!containsNumber(newUser.update_password?.new_password))) {
        if (!newPasswordError) {
          setNewPasswordError(true);
        }
        haveError = true;
      } else {
        if (newPasswordError) {
          setNewPasswordError(false);
        }
      }

      if (confirmNewPassword === newUser.update_password?.new_password) {
        if (confirmPasswordError) {
          setConfirmPasswordError(false);
        }
      } else {
        if (!confirmPasswordError) {
          setConfirmPasswordError(true);
        }
        haveError = true;
      }
    }

    return haveError;
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);

    if (validationError()) {
      setLoading(false);
      return;
    }

    let profileApi: amberSdk.ProfileApi = new amberSdk.ProfileApi(undefined, undefined, mainAxios)

    profileApi.meUpdatePOST({
      name: newUser.name,
      mobile_contact: {
        country_calling_code: newUser.mobile_contact.country_calling_code,
        phone_number: newUser.mobile_contact.phone_number,
      },
      email: newUser.email,
      update_password: newUser.update_password?.current_password ? newUser.update_password : undefined
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setReloadData(!reloadData);
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_mobile_contact') {
          setMobileError(true);
        } else if (e.response.data.error === 'invalid_email') {
          setEmailError(true);
        }
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <h1>Your User Profile</h1>
        <span className='p1'>
          Update your information
        </span>
      </HeaderWrapper>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            {`User Roles`}
          </Col>
          <Col md={4}>
            {roles.map((e, index) => {
              if (index > 0) {
                return `, ${e}`
              }
              return e
            })}
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Name*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${nameError ? 'is-invalid' : ''}`}
              placeholder="Name"
              id="name"
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={newUser.name}
            />
            <div className="invalid-feedback">Invalid name</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Email*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${emailError ? 'is-invalid' : ''}`}
              placeholder="Email"
              id="email"
              onChange={(e) => handleInputChange('email', e.target.value)}
              value={newUser.email}
            />
            <div className="invalid-feedback">Invalid email</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Mobile Number*
          </Col>
          <Col md={4} style={{ display: 'flex' }}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleMobileChange('country_calling_code', e.value)}
              options={countryCodeOptionList}
              value={countryCodeOptionList.find(e => e.value === newUser.mobile_contact.country_calling_code)}
            />
            <input
              type="text"
              className={`form-control fs-13px ${mobileError ? 'is-invalid' : ''}`}
              placeholder="Mobile Number"
              id="mobileNumber"
              onChange={(e) => handleMobileChange('phone_number', e.target.value)}
              value={newUser.mobile_contact.phone_number}
            />
            <div className="invalid-feedback">Invalid mobile contact</div>
          </Col>
        </Row>

        <HeaderWrapper>
          <h1>Change Password</h1>
        </HeaderWrapper>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Current Password*
          </Col>
          <Col md={4}>
            <input
              type="password"
              className={`form-control fs-13px ${currentPasswordError ? 'is-invalid' : ''}`}
              placeholder="Current Password"
              id="currentPassword"
              onChange={(e) => handlePasswordChange('current_password', e.target.value)}
              value={newUser.update_password ? newUser.update_password.current_password : ''}
            />
            <div className="invalid-feedback">Invalid current password</div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row style={{ marginBottom: "20px" }}>
              <Col md={4}>
                New Password*
              </Col>
              <Col md={8}>
                <input
                  type="password"
                  className={`form-control fs-13px ${newPasswordError ? 'is-invalid' : ''}`}
                  placeholder="New Password"
                  id="newPassword"
                  onChange={(e) => handlePasswordChange('new_password', e.target.value)}
                  value={newUser.update_password ? newUser.update_password.new_password : ''}
                />
                <div className="invalid-feedback">Invalid new password</div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                Confirm New Password*
              </Col>
              <Col md={8}>
                <input
                  type="password"
                  className={`form-control fs-13px ${confirmPasswordError ? 'is-invalid' : ''}`}
                  placeholder="Confirm New Password"
                  id="confirmPassword"
                  onChange={(e) => {setConfirmPasswordError(false);setConfirmNewPassword(e.target.value)}}
                  value={confirmNewPassword}
                />
                <div className="invalid-feedback">Password Different</div>
              </Col>
            </Row>
          </Col>
          <Col md={4} style={{
            borderLeft: '1px solid #E0E0E0',
          }}>
            <PasswordCriteria charactersError={!containsLetter(newUser.update_password?.new_password)} numbersError={!containsNumber(newUser.update_password?.new_password)} />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col md={3}>
          </Col>
          <Col md={1}>
            <CancelButton type='button' onClick={history.goBack}>
              Cancel
            </CancelButton>
          </Col>
          <Col md={2}>
            <UpdateButton type='submit' onClick={handleSubmit}>
              + Update User
            </UpdateButton>
          </Col>

        </Row>
      </Container>
    </div>
  )
};

export default UpdateProfile;