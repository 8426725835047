import create from 'zustand';
import { Profile } from '../open-api';
// import { mountStoreDevtool } from 'simple-zustand-devtools';

export interface UserState {
  user: Profile;
  setUser: (newUser: Profile) => void;
  unsetUser: () => void;
  isLoading: boolean;
  setIsLoading: (loadingState: boolean) => void;
  accessToken: string;
  setAccessToken: (token: string) => void;
}

let defaultUser = {
  id: '',
  roles: [],
  email: '',
  name: '',
  mobile_contact: {
    country_calling_code: '',
    phone_number: '',
  },
}

export const useUserStore = create<UserState>((set) => ({
  // initial state
  user: defaultUser,
  // methods for manipulating state
  setUser: (newUser: Profile) => {
    set((state) => ({
      user: newUser,
    }));
  },
  unsetUser: () => {
    set((state) => ({
      user: defaultUser,
      accessToken: '',
    }));
  },
  isLoading: true,
  setIsLoading: (loadingState: boolean) => {
    set((state) => ({
      isLoading: loadingState,
    }));
  },
  accessToken: '',
  setAccessToken: (token: string) => {
    set((state) => ({
      accessToken: token,
    }));
  },
}));

// if (process.env.NODE_ENV === 'development') {
//   mountStoreDevtool('Store', useStore);
// }