import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import BounceLoader from 'react-spinners/BounceLoader';
import { Col, Container, Row } from 'reactstrap';
import { DeliveryPersonnelCreation } from '../../open-api';
import * as amberSdk from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import { statusOptionList, countryCodeOptionList, IParamTypes, ISelectOptionItem, adminRoleExists, dispenserRoleExists } from '../../common/utils';
import CustomSelect from '../../components/custom-select/custom-select';
import { getRequestConfig } from '../../common/utils';
import { useHistory, useParams } from 'react-router-dom';
import { emptyCreateObject } from './create-delivery-personnel';
import mainAxios from '../../config/axios-config';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  width: 100%;
  height: 32px;
`
const UpdateButton = styled.button`
  padding: 8px 0;
  width: 100%;
  background: #FFA300;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

function UpdateDeliveryPersonnel() {
  const history = useHistory();
  const { id } = useParams<IParamTypes>();

  const [newCreateObject, setNewCreateObject] = useState<DeliveryPersonnelCreation>(emptyCreateObject);
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const userStore = useUserStore();

  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }

    if (userStore.user.id) {
      if (!(adminRoleExists(userStore.user.roles) || dispenserRoleExists(userStore.user.roles))) {
        history.push("/not-found");
        return;
      }
    }

  }, [userStore.accessToken, userStore.user, history])

  /**
   * load all page data
   */
  useEffect(() => {

    let isSubscribed = true;

    if (!userStore.accessToken) {
      return
    }

    let dpApi: amberSdk.DeliveryPersonnelApi = new amberSdk.DeliveryPersonnelApi(undefined, undefined, mainAxios)

    dpApi.deliveryPersonnelGET(id, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed)
        setNewCreateObject({
          name: e.data.name,
          mobile_contact: {
            country_calling_code: e.data.mobile_contact.country_calling_code,
            phone_number: e.data.mobile_contact.phone_number,
          },
          status: e.data.status,
        });
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).finally(() => {
      if (isSubscribed)
        setLoading(false);
    });

    return () => {
      isSubscribed = false
    };

  }, [id, userStore.accessToken, reloadData])

  function handleInputChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      [field]: { $set: value }
    })
    setNewCreateObject(tempOrder);
  }

  function handleMobileChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      mobile_contact: {
        [field]: { $set: value }
      }
    })
    setNewCreateObject(tempOrder);
    if (mobileError) {
      setMobileError(false)
    }
  }

  function validationError(): boolean {
    let haveError = false;
    if (!newCreateObject.name) {
      if (!nameError) {
        setNameError(true)
      }
      haveError = true;
    } else {
      if (nameError) {
        setNameError(false)
      }
    }

    if (!newCreateObject.mobile_contact.phone_number) {
      if (!mobileError) {
        setMobileError(true)
      }
      haveError = true;
    } else {
      if (mobileError) {
        setMobileError(false)
      }
    }

    return haveError;
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);

    if (validationError()) {
      setLoading(false);
      return;
    }

    let dpApi: amberSdk.DeliveryPersonnelApi = new amberSdk.DeliveryPersonnelApi(undefined, undefined, mainAxios)

    dpApi.deliveryPersonnelUpdatePOST(id, {
      name: newCreateObject.name,
      mobile_contact: {
        country_calling_code: newCreateObject.mobile_contact.country_calling_code,
        phone_number: newCreateObject.mobile_contact.phone_number,
      },
      status: newCreateObject.status,
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setReloadData(!reloadData);
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_mobile_contact') {
          setMobileError(true);
        }
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <h1 className="page-header">Update Delivery Personnel</h1>
      </HeaderWrapper>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "32px" }}>
          <Col md={6}>
            <span className='p1'>
              Update delivery personnel.
            </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Name*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${nameError ? 'is-invalid' : ''}`}
              placeholder="Name"
              id="name"
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={newCreateObject.name}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Mobile*
          </Col>
          <Col md={4} style={{ display: 'flex' }}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleMobileChange('country_calling_code', e.value)}
              options={countryCodeOptionList}
              value={countryCodeOptionList.find(e => e.value === newCreateObject.mobile_contact.country_calling_code)}
            />
            <input
              type="text"
              className={`form-control fs-13px ${mobileError ? 'is-invalid' : ''}`}
              placeholder="Mobile Number"
              id="mobileNumber"
              onChange={(e) => handleMobileChange('phone_number', e.target.value)}
              value={newCreateObject.mobile_contact.phone_number}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Account Status
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('status', e.value)}
              options={statusOptionList}
              placeholder='Status'
              value={statusOptionList.find(e => e.value === newCreateObject.status)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={3}>
          </Col>
          <Col md={1}>
            <CancelButton type='button' onClick={history.goBack}>
              Cancel
            </CancelButton>
          </Col>
          <Col md={2}>
            <UpdateButton type='submit' onClick={handleSubmit}>
              Update Delivery Personnel
            </UpdateButton>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default UpdateDeliveryPersonnel;