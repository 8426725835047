import React, { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { orderStatusEnum, OrderStatusIcon } from '../../components/order-status/order-status';
import { Link } from 'react-router-dom';
import ThumbnailModal from '../../components/modal/thumbnail-modal';
import moment from 'moment';
import { SortDirectionIcon } from '../../components/sort-arrow/sort-arrow';
import { StyledTable, ViewButton } from '../../common/styles';
import ThumbnailImage from '../../components/thumbnail/thumbnail';

const OrdersTable = ({
  columns,
  data,
  onSort,
  sortOptions,
}) => {
  const rowData = useMemo(() => data, [data]);
  const [thumbnail, setThumbnail] = useState({
    base64: "",
    id: ""
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: rowData,
    manualSortBy: true,
  }
  )

  return (
    <>
      <StyledTable {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  onClick={() => onSort(
                    column.id
                  )}
                >
                  {column.render('Header')}
                  <span>
                    <SortDirectionIcon sortOptions={sortOptions} column={column.id} />
                  </span>
                </th>
              ))}
              <th style={{ backgroundColor: '#F0F0F0' }}
                key="viewButton"
              />
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.length ?
              rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} style={row.original.order_status === orderStatusEnum.ORDER_CANCELLED ? { backgroundColor: '#F0F0F0' } : {}} >
                    {row.cells.map(cell => {
                      switch (cell.column.id) {
                        case 'remarks':
                          return (
                            <td
                              {...cell.getCellProps()}
                              style={
                                {
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '20px',
                                }
                              }
                            >
                              {cell.value}
                            </td>
                          )
                        case 'order_status':
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              <OrderStatusIcon status={cell.value} />
                            </td>
                          )
                        case 'image':
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              <ThumbnailImage orderNumber={row.original.order_number} />
                            </td>
                          )
                        case 'date_of_order':
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              {moment(cell.value).format('DD MMM YYYY [at] h:mmA')}
                            </td>
                          )
                        case 'date_of_estimated_delivery':
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              {cell.value ? moment(cell.value).format('DD MMM YYYY') : '-'}
                            </td>
                          )
                        default:
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                      }
                    })}

                    <td {...row.cells[0].getCellProps()}>
                      <Link to={`/order/${row.original.order_number}`}>
                        <ViewButton>
                          View
                        </ViewButton>
                      </Link>
                    </td>
                  </tr>
                )
              })
              :
              <tr>
                <td>
                  No records found.
                </td>
              </tr>
          }
        </tbody>
      </StyledTable>
      {
        thumbnail.id && <ThumbnailModal modalIsOpen={thumbnail.id !== ""} handleClose={() => setThumbnail({
          base64: "",
          id: ""
        })} data={thumbnail} />
      }
    </>
  )
}

export default OrdersTable;