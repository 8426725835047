const Menu = [
  { path: '/dashboard', img: '/assets/img/svg/ic-dashboard.svg', title: 'Dashboard', roles: ['ADMIN', 'DISPENSER'] },
  { path: '/orders', img: '/assets/img/svg/ic-orders.svg', title: 'Orders', roles: ['ADMIN', 'DISPENSER', 'PHARMACIST'] },
  { path: '/compounding-tech', img: '/assets/img/svg/ic-ct.svg', title: 'Compounding Techs',
    children: [
      { path: '/compounding-tech/all', title: 'All Compounding Techs' },
      { path: '/compounding-tech/create', title: '+ Create Compounding Tech' },
    ], 
    roles: ['ADMIN', 'DISPENSER']
  },
  { path: '/delivery-personnel', img: '/assets/img/svg/ic-delivery.svg', title: 'Delivery Personnel',
    children: [
      { path: '/delivery-personnel/all', title: 'All Delivery Personnel' },
      { path: '/delivery-personnel/create', title: '+ Create Delivery Personnel' },
    ], 
    roles: ['ADMIN', 'DISPENSER']
  },
  { path: '/amber-branches', img: '/assets/img/svg/ic-amber-branches.svg', title: 'Amber Branches',
    children: [
      { path: '/amber-branches/all', title: 'All Amber Branches' },
      { path: '/amber-branches/create', title: '+ Create Amber Branch' },
    ],
    roles: ['ADMIN']
  },
  { path: '/clinics', img: '/assets/img/svg/ic-clinics.svg', title: 'Clinics',
    children: [
      { path: '/clinics/all', title: 'All Clinics' },
      { path: '/clinics/create', title: '+ Create Clinic' },
      { path: '/clinics/purchasers/all', title: 'All Clinics Purchasers' },
      { path: '/clinics/purchasers/create', title: '+ Create Purchaser' },
    ],
    roles: ['ADMIN']
  },
  { path: '/users', img: '/assets/img/svg/ic-user-roles.svg', title: 'Users',
    children: [
      { path: '/users/all', title: 'All Users' },
      { path: '/users/create', title: '+ Create User' },
    ],
    roles: ['ADMIN']
  }
]

export default Menu;
