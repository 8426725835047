import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: styles => ({
    color: 'var(--app-component-color)',
    borderRadius: '6px',
    border: 'solid 1px #E0E0E0',
    display: 'flex',
    minWidth: '82px',
  }),
  indicatorSeparator: styles => ({
    backgroundColor: 'transparent'
  }),
  input: styles => ({
    color: '#4A4A4A',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    gridArea: '1/1/2/3',
    flex: '1 1 auto',
    display: 'inline-grid',
    gridTemplateColumns: '0 min-content',
    boxSizing: 'content-box',
    visibility: 'visible'
  }),
  singleValue: styles => ({
    color: '#4A4A4A',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    gridArea: '1/1/2/3',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  }),
  placeholder: styles => ({
    color: '#4A4A4A',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    gridArea: '1/1/2/3'
  }),
  menu: styles => ({
    backgroundColor: 'var(--app-component-dropdown-bg)',
    position: 'absolute',
    top: '100%',
    borderRadius: '4px',
    margin: '8px 0',
    zIndex: '1',
    boxSizing: 'border-box',
    width: '100%',
    border: '2px solid #F9F9F9',
    boxShadow: '3px 3px 2px rgba(0, 0, 0, 0.15)',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      backgroundColor: isFocused ? 'var(--app-component-dropdown-hover-bg)' : '',
      color: 'var(--app-component-color)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '8px 12px'
    };
  }
};


const errorStyles = {
  control: styles => ({
    color: 'var(--app-component-color)',
    borderRadius: '6px',
    border: 'solid 1px #ea4335',
    display: 'flex'
  }),
  indicatorSeparator: styles => ({
    backgroundColor: 'transparent'
  }),
  input: styles => ({
    color: '#4A4A4A',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    gridArea: '1/1/2/3',
    flex: '1 1 auto',
    display: 'inline-grid',
    gridTemplateColumns: '0 min-content',
    boxSizing: 'content-box',
    visibility: 'visible'
  }),
  singleValue: styles => ({
    color: '#4A4A4A',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    gridArea: '1/1/2/3',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box'
  }),
  placeholder: styles => ({
    color: '#4A4A4A',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    gridArea: '1/1/2/3'
  }),
  menu: styles => ({
    backgroundColor: 'var(--app-component-dropdown-bg)',
    position: 'absolute',
    top: '100%',
    borderRadius: '4px',
    margin: '8px 0',
    zIndex: '1',
    boxSizing: 'border-box',
    width: '100%',
    border: '2px solid #F9F9F9',
    boxShadow: '3px 3px 2px rgba(0, 0, 0, 0.15)',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      backgroundColor: isFocused ? 'var(--app-component-dropdown-hover-bg)' : '',
      color: 'var(--app-component-color)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '8px 12px'
    };
  }
};

const CustomSelect = (props) => (
  <Select {...props} styles={props.isError ? errorStyles : customStyles} />
);

export default CustomSelect;
