import React from 'react';
// import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import styled from 'styled-components'
import BounceLoader from 'react-spinners/BounceLoader';
import DefaultButton from '../../components/buttons/default-button.jsx';
import { useUserStore } from '../../store/userStore';
import * as amberSdk from '../../open-api';
import { containsLetter, containsNumber } from '../../common/utils';
import { getRequestConfig } from '../../common/utils'
import { toast } from 'react-toastify';
import PasswordCriteria from '../../components/password/password-criteria';

const FooterDiv = styled.div`
	margin-bottom: 3em;
  position: fixed;
  bottom: 0%;
`
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ResetPasswordContent = (props) => {
  if (props.isLoading) {
    return (
      <>
        <div>
          back
        </div>
        <div className="login-container">
          <LoaderWrapper>
            <BounceLoader
              color={"#FFA300"}
              loading={props.isLoading}
              size={48}
              speedMultiplier={1}
            />
          </LoaderWrapper>
        </div>
      </>
    );
  }
  return (
    <div className="login-container">
      <div className="login-header mb-30px">
        <div className="brand">
          <div className="d-flex align-items-center">
            <span className="logo"></span>
            Set Password
          </div>
          <span className='p1'>Set your new password</span>
        </div>
      </div>
      <div className="login-content">
        <form onSubmit={(e) => props.handleSubmit(e)} className="fs-13px">
          <div className="form mb-15px">
            <input
              type="password"
              className={`form-control fs-13px ${props.passwordError ? 'is-invalid' : ''}`}
              placeholder="New Password"
              id="password"
              onChange={(e) => props.handleUpdateStateValue('password', e.target.value)}
              value={props.password}
            />
            <div className="invalid-feedback">Invalid password</div>
          </div>
          <div className="form mb-15px">
            <input
              type="password"
              className={`form-control fs-13px ${props.confirmPasswordError ? 'is-invalid' : ''}`}
              placeholder="Confirm New Password"
              id="confirmPassword"
              onChange={(e) => props.handleUpdateStateValue('confirmPassword', e.target.value)}
              value={props.confirmPassword}
            />
            <div className="invalid-feedback">Password Different</div>
          </div>
          <PasswordCriteria style={{
            marginTop: '40px',
            marginBottom: '48px'
          }}
            charactersError={!containsLetter(props.password)} numbersError={!containsNumber(props.password)} />
          <div className="mb-15px">
            <DefaultButton type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px" onClick={(e) => props.handleSubmit(e)}>Submit</DefaultButton>
          </div>
          <FooterDiv>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              Copyright &copy; 2022 Amber Compounding Pharmacy Pte Ltd
            </div>
          </FooterDiv>
        </form>
      </div>
    </div>
  )
}

class ResetPassword extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPassword: '',
      resetSuccess: false,
      resetLoading: true,
      hasLetter: false,
      hasNumber: false,
      confirmPasswordError: false,
    }

    this.handleSubmitResetPassword = this.handleSubmitResetPassword.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
    if (this.props.userStore.accessToken) {
      let profileApi = new amberSdk.ProfileApi();
      profileApi.meGET(getRequestConfig(this.props.userStore.accessToken)
      ).then((e) => {
        this.props.userStore.setUser(e.data)
        this.props.history.push('/')
      })
    }

    // check for valid reset token before proceeding
    let token = new URLSearchParams(this.props.history.location.search).get('token');
    if (!token) {
      this.props.history.push('/login')
    } else {
      let passwordApi = new amberSdk.PasswordApi();
      passwordApi.passwordResetPOST({
        password: "",
        token,
      }).then((e) => {
        this.setState({
          resetLoading: false,
        });
      }).catch((e) => {
        if (e.response
          && (e.response.status === 400 || e.response.status === 403)
          && e.response.data.error === "invalid_token") {
          toast.error(
            (
              <p>
                {e.response.data.error_description}
                <br />Redirecting to login page.
              </p>
            )
            , {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          this.props.history.push('/login')
        }
        this.setState({
          resetLoading: false,
        });
      })
    }
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  handleSubmitResetPassword(event) {
    event.stopPropagation()
    event.preventDefault();

    if (!this.isValidForm()) {
      return;
    }

    this.setState({
      resetLoading: true,
    })


    let token = new URLSearchParams(this.props.history.location.search).get('token');

    let passwordApi = new amberSdk.PasswordApi();
    // login post api
    passwordApi.passwordResetPOST({
      password: this.state.password,
      token,
    }).then((e) => {
      this.props.history.push('/login?reset-password=success')
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        if (e.response.data.error === "invalid_token") {
          toast.error(
            (
              <p>
                {e.response.data.error_description}
                <br />Redirecting to login page.
              </p>
            )
            , {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          this.props.history.push('/login')
        } else {
          toast.error(e.response.data.error_description, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({
            passwordError: true,
          });
        }
      }
    }).finally((e) => {
      this.setState({
        resetLoading: false,
      });
    })
  }

  isValidForm() {
    let validForm = true;
    if ((!containsLetter(this.state.password)) || (!containsNumber(this.state.password))) {
      if (!this.state.passwordError) {
        this.setState({
          passwordError: true
        })
      }
      validForm = false;
    }
    if (this.state.password !== this.state.confirmPassword) {
      if (!this.state.confirmPasswordError) {
        this.setState({
          confirmPasswordError: true
        })
      }
      validForm = false;
    }
    return validForm;
  }

  updateStateValue(id, value) {
    this.setState({
      [id]: value
    })

    if (id === 'password') {
      if (!containsLetter(value)) {
        this.setState({
          passwordError: true
        })
      } else if (!containsNumber(value)) {
        this.setState({
          passwordError: true
        })
      } else {
        this.setState({
          passwordError: false
        })
      }
    }

    if (id === 'confirmPassword') {
      if (value === this.state.password) {
        this.setState({
          confirmPasswordError: false
        });
      } else {
        this.setState({
          confirmPasswordError: true
        });
      }
    }
  }

  render() {
    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/img-admin-bacgkground.jpg)' }}></div>
        </div>
        <ResetPasswordContent
          handleSubmit={this.handleSubmitResetPassword}
          handleUpdateStateValue={this.updateStateValue}
          password={this.state.password}
          isLoading={this.state.resetLoading}
          passwordError={this.state.passwordError}
          confirmPasswordError={this.state.confirmPasswordError}
        />
      </div>
    )
  }
}

const withStore = BaseComponent => props => {
  const userStore = useUserStore();
  return <BaseComponent {...props} userStore={userStore} />;
};

export default withRouter(withStore(ResetPassword));