import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";

import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`
const ContinueButton = styled.button`
  background-color: #FFA300;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 8px 26px;
  height: 32px;
`
const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 46px 19px 32px 19px
`

interface IProps {
  open: boolean;
}

const LogoutDialog = ({ open }: IProps) => {
  return (
    <Modal
      isOpen={open}
      centered
    >
      <ModalBody>
        <BodyWrapper>
          <img style={{ height: '78px', width: '78px', marginBottom: '29px' }} src="/assets/img/svg/ic-session-timeout.svg" alt="" />
          <h1 style={{ marginBottom: '16px' }}>
            Session Timeout
          </h1>
          <p className="p1" style={{ marginBottom: '24px' }}>
            Your session has expired.
            <br />
            Click continue to return to login page.
          </p>
          <ButtonGroup>
            <Link to="/login">
              <ContinueButton>
                Back to Login
              </ContinueButton>
            </Link>
          </ButtonGroup>
        </BodyWrapper>
      </ModalBody>
    </Modal>
  );
}
export default LogoutDialog;
