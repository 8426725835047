  export const orderColumns2 = [
    {
      Header: 'Order Number',
      accessor: 'order_number', // accessor is the "key" in the data
    },
    {
      Header: 'Thumbnail',
      accessor: 'image',
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
    {
      Header: 'Clinic',
      accessor: 'clinic_name',
    },
    {
      Header: 'Amber Branch',
      accessor: 'branch_code',
    },
    {
      Header: 'Order Status',
      accessor: 'order_status',
    },
    {
      Header: 'Date of Order',
      accessor: 'date_of_order',
    },
    {
      Header: 'Estimated Delivery',
      accessor: 'date_of_estimated_delivery',
    },
  ];
