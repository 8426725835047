/* tslint:disable */
/* eslint-disable */
/**
 * Amber API
 * This document describes the endpoints for the **web portal** implementation.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityLog
 */
export interface ActivityLog {
    /**
     * 
     * @type {Array<ActivityLogLogs>}
     * @memberof ActivityLog
     */
    'logs': Array<ActivityLogLogs>;
}
/**
 * 
 * @export
 * @interface ActivityLogLogs
 */
export interface ActivityLogLogs {
    /**
     * 
     * @type {string}
     * @memberof ActivityLogLogs
     */
    'id': string;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof ActivityLogLogs
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogLogs
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface BranchCreation
 */
export interface BranchCreation {
    /**
     * 
     * @type {string}
     * @memberof BranchCreation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchCreation
     */
    'code': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof BranchCreation
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof BranchCreation
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof BranchCreation
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof BranchCreation
     */
    'status': BranchCreationStatusEnum;
}

export const BranchCreationStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type BranchCreationStatusEnum = typeof BranchCreationStatusEnum[keyof typeof BranchCreationStatusEnum];

/**
 * 
 * @export
 * @interface BranchRetrieval
 */
export interface BranchRetrieval {
    /**
     * 
     * @type {string}
     * @memberof BranchRetrieval
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BranchRetrieval
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof BranchRetrieval
     */
    'mobile_contact': MobileContact;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof BranchRetrieval
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof BranchRetrieval
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof BranchRetrieval
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof BranchRetrieval
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof BranchRetrieval
     */
    'status': BranchRetrievalStatusEnum;
}

export const BranchRetrievalStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type BranchRetrievalStatusEnum = typeof BranchRetrievalStatusEnum[keyof typeof BranchRetrievalStatusEnum];

/**
 * 
 * @export
 * @interface BranchesRetrieval
 */
export interface BranchesRetrieval {
    /**
     * 
     * @type {PagingMetadata}
     * @memberof BranchesRetrieval
     */
    'paging_metadata': PagingMetadata;
    /**
     * 
     * @type {Array<BranchesRetrievalBranches>}
     * @memberof BranchesRetrieval
     */
    'branches': Array<BranchesRetrievalBranches>;
}
/**
 * 
 * @export
 * @interface BranchesRetrievalBranches
 */
export interface BranchesRetrievalBranches {
    /**
     * 
     * @type {string}
     * @memberof BranchesRetrievalBranches
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BranchesRetrievalBranches
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof BranchesRetrievalBranches
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof BranchesRetrievalBranches
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof BranchesRetrievalBranches
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof BranchesRetrievalBranches
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof BranchesRetrievalBranches
     */
    'clinic_counts': number;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof BranchesRetrievalBranches
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof BranchesRetrievalBranches
     */
    'status': BranchesRetrievalBranchesStatusEnum;
}

export const BranchesRetrievalBranchesStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type BranchesRetrievalBranchesStatusEnum = typeof BranchesRetrievalBranchesStatusEnum[keyof typeof BranchesRetrievalBranchesStatusEnum];

/**
 * 
 * @export
 * @interface ClientToken
 */
export interface ClientToken {
    /**
     * 
     * @type {string}
     * @memberof ClientToken
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof ClientToken
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface ClinicCreation
 */
export interface ClinicCreation {
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof ClinicCreation
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'postal': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'branch_id': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicCreation
     */
    'status': ClinicCreationStatusEnum;
}

export const ClinicCreationStatusEnum = {
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED'
} as const;

export type ClinicCreationStatusEnum = typeof ClinicCreationStatusEnum[keyof typeof ClinicCreationStatusEnum];

/**
 * 
 * @export
 * @interface ClinicPurchaserCreation
 */
export interface ClinicPurchaserCreation {
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserCreation
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof ClinicPurchaserCreation
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserCreation
     */
    'clinic_id': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserCreation
     */
    'status': ClinicPurchaserCreationStatusEnum;
}

export const ClinicPurchaserCreationStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ClinicPurchaserCreationStatusEnum = typeof ClinicPurchaserCreationStatusEnum[keyof typeof ClinicPurchaserCreationStatusEnum];

/**
 * 
 * @export
 * @interface ClinicPurchaserProfile
 */
export interface ClinicPurchaserProfile {
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserProfile
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof ClinicPurchaserProfile
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {ClinicRetrieval}
     * @memberof ClinicPurchaserProfile
     */
    'clinic': ClinicRetrieval;
}
/**
 * 
 * @export
 * @interface ClinicPurchaserRetrieval
 */
export interface ClinicPurchaserRetrieval {
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserRetrieval
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserRetrieval
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof ClinicPurchaserRetrieval
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {ClinicPurchaserRetrievalClinic}
     * @memberof ClinicPurchaserRetrieval
     */
    'clinic': ClinicPurchaserRetrievalClinic;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserRetrieval
     */
    'status': ClinicPurchaserRetrievalStatusEnum;
}

export const ClinicPurchaserRetrievalStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ClinicPurchaserRetrievalStatusEnum = typeof ClinicPurchaserRetrievalStatusEnum[keyof typeof ClinicPurchaserRetrievalStatusEnum];

/**
 * 
 * @export
 * @interface ClinicPurchaserRetrievalClinic
 */
export interface ClinicPurchaserRetrievalClinic {
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserRetrievalClinic
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchaserRetrievalClinic
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ClinicPurchasersRetrieval
 */
export interface ClinicPurchasersRetrieval {
    /**
     * 
     * @type {PagingMetadata}
     * @memberof ClinicPurchasersRetrieval
     */
    'paging_metadata': PagingMetadata;
    /**
     * 
     * @type {Array<ClinicPurchasersRetrievalClinicPurchasers>}
     * @memberof ClinicPurchasersRetrieval
     */
    'clinic_purchasers': Array<ClinicPurchasersRetrievalClinicPurchasers>;
}
/**
 * 
 * @export
 * @interface ClinicPurchasersRetrievalClinicPurchasers
 */
export interface ClinicPurchasersRetrievalClinicPurchasers {
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchasersRetrievalClinicPurchasers
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchasersRetrievalClinicPurchasers
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof ClinicPurchasersRetrievalClinicPurchasers
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchasersRetrievalClinicPurchasers
     */
    'clinic_name': string;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof ClinicPurchasersRetrievalClinicPurchasers
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicPurchasersRetrievalClinicPurchasers
     */
    'status': ClinicPurchasersRetrievalClinicPurchasersStatusEnum;
}

export const ClinicPurchasersRetrievalClinicPurchasersStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ClinicPurchasersRetrievalClinicPurchasersStatusEnum = typeof ClinicPurchasersRetrievalClinicPurchasersStatusEnum[keyof typeof ClinicPurchasersRetrievalClinicPurchasersStatusEnum];

/**
 * 
 * @export
 * @interface ClinicRetrieval
 */
export interface ClinicRetrieval {
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'id': string;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {any}
     * @memberof ClinicRetrieval
     */
    'date_created': any;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof ClinicRetrieval
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'postal': string;
    /**
     * 
     * @type {ClinicRetrievalBranch}
     * @memberof ClinicRetrieval
     */
    'branch': ClinicRetrievalBranch;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrieval
     */
    'status': ClinicRetrievalStatusEnum;
}

export const ClinicRetrievalStatusEnum = {
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED'
} as const;

export type ClinicRetrievalStatusEnum = typeof ClinicRetrievalStatusEnum[keyof typeof ClinicRetrievalStatusEnum];

/**
 * 
 * @export
 * @interface ClinicRetrievalBranch
 */
export interface ClinicRetrievalBranch {
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrievalBranch
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicRetrievalBranch
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface ClinicsRetrieval
 */
export interface ClinicsRetrieval {
    /**
     * 
     * @type {PagingMetadata}
     * @memberof ClinicsRetrieval
     */
    'paging_metadata': PagingMetadata;
    /**
     * 
     * @type {Array<ClinicsRetrievalClinics>}
     * @memberof ClinicsRetrieval
     */
    'clinics': Array<ClinicsRetrievalClinics>;
}
/**
 * 
 * @export
 * @interface ClinicsRetrievalClinics
 */
export interface ClinicsRetrievalClinics {
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'postal': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'branch_code': string;
    /**
     * 
     * @type {number}
     * @memberof ClinicsRetrievalClinics
     */
    'clinic_purchaser_counts': number;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'date_created': string;
    /**
     * 
     * @type {string}
     * @memberof ClinicsRetrievalClinics
     */
    'status': ClinicsRetrievalClinicsStatusEnum;
}

export const ClinicsRetrievalClinicsStatusEnum = {
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED'
} as const;

export type ClinicsRetrievalClinicsStatusEnum = typeof ClinicsRetrievalClinicsStatusEnum[keyof typeof ClinicsRetrievalClinicsStatusEnum];

/**
 * 
 * @export
 * @interface CompoundingTechCreation
 */
export interface CompoundingTechCreation {
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechCreation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechCreation
     */
    'email'?: string;
    /**
     * 
     * @type {MobileContact}
     * @memberof CompoundingTechCreation
     */
    'mobile_contact'?: MobileContact;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechCreation
     */
    'branch_id': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechCreation
     */
    'status': CompoundingTechCreationStatusEnum;
}

export const CompoundingTechCreationStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CompoundingTechCreationStatusEnum = typeof CompoundingTechCreationStatusEnum[keyof typeof CompoundingTechCreationStatusEnum];

/**
 * 
 * @export
 * @interface CompoundingTechRetrieval
 */
export interface CompoundingTechRetrieval {
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechRetrieval
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechRetrieval
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechRetrieval
     */
    'email'?: string;
    /**
     * 
     * @type {MobileContact}
     * @memberof CompoundingTechRetrieval
     */
    'mobile_contact'?: MobileContact;
    /**
     * 
     * @type {CompoundingTechRetrievalBranch}
     * @memberof CompoundingTechRetrieval
     */
    'branch': CompoundingTechRetrievalBranch;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechRetrieval
     */
    'status': CompoundingTechRetrievalStatusEnum;
}

export const CompoundingTechRetrievalStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CompoundingTechRetrievalStatusEnum = typeof CompoundingTechRetrievalStatusEnum[keyof typeof CompoundingTechRetrievalStatusEnum];

/**
 * 
 * @export
 * @interface CompoundingTechRetrievalBranch
 */
export interface CompoundingTechRetrievalBranch {
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechRetrievalBranch
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechRetrievalBranch
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CompoundingTechsRetrieval
 */
export interface CompoundingTechsRetrieval {
    /**
     * 
     * @type {PagingMetadata}
     * @memberof CompoundingTechsRetrieval
     */
    'paging_metadata': PagingMetadata;
    /**
     * 
     * @type {Array<CompoundingTechsRetrievalCompoundingTechs>}
     * @memberof CompoundingTechsRetrieval
     */
    'compounding_techs': Array<CompoundingTechsRetrievalCompoundingTechs>;
}
/**
 * 
 * @export
 * @interface CompoundingTechsRetrievalCompoundingTechs
 */
export interface CompoundingTechsRetrievalCompoundingTechs {
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechsRetrievalCompoundingTechs
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechsRetrievalCompoundingTechs
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechsRetrievalCompoundingTechs
     */
    'email'?: string;
    /**
     * 
     * @type {MobileContact}
     * @memberof CompoundingTechsRetrievalCompoundingTechs
     */
    'mobile_contact'?: MobileContact;
    /**
     * 
     * @type {number}
     * @memberof CompoundingTechsRetrievalCompoundingTechs
     */
    'orders_on_hand': number;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechsRetrievalCompoundingTechs
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof CompoundingTechsRetrievalCompoundingTechs
     */
    'status': CompoundingTechsRetrievalCompoundingTechsStatusEnum;
}

export const CompoundingTechsRetrievalCompoundingTechsStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CompoundingTechsRetrievalCompoundingTechsStatusEnum = typeof CompoundingTechsRetrievalCompoundingTechsStatusEnum[keyof typeof CompoundingTechsRetrievalCompoundingTechsStatusEnum];

/**
 * 
 * @export
 * @interface DeliveryLog
 */
export interface DeliveryLog {
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof DeliveryLog
     */
    'date': string;
    /**
     * The delivery status of the order
     * @type {string}
     * @memberof DeliveryLog
     */
    'status': DeliveryLogStatusEnum;
    /**
     * 
     * @type {DeliveryLogDetails}
     * @memberof DeliveryLog
     */
    'details': DeliveryLogDetails;
}

export const DeliveryLogStatusEnum = {
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED'
} as const;

export type DeliveryLogStatusEnum = typeof DeliveryLogStatusEnum[keyof typeof DeliveryLogStatusEnum];

/**
 * @type DeliveryLogDetails
 * @export
 */
export type DeliveryLogDetails = OrderDeliveredOfDeliveryLog | OrderDeliveryFailed;

/**
 * 
 * @export
 * @interface DeliveryPersonnelCreation
 */
export interface DeliveryPersonnelCreation {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelCreation
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof DeliveryPersonnelCreation
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelCreation
     */
    'status': DeliveryPersonnelCreationStatusEnum;
}

export const DeliveryPersonnelCreationStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type DeliveryPersonnelCreationStatusEnum = typeof DeliveryPersonnelCreationStatusEnum[keyof typeof DeliveryPersonnelCreationStatusEnum];

/**
 * 
 * @export
 * @interface DeliveryPersonnelProfile
 */
export interface DeliveryPersonnelProfile {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelProfile
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof DeliveryPersonnelProfile
     */
    'mobile_contact': MobileContact;
}
/**
 * 
 * @export
 * @interface DeliveryPersonnelRetrieval
 */
export interface DeliveryPersonnelRetrieval {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelRetrieval
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelRetrieval
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof DeliveryPersonnelRetrieval
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelRetrieval
     */
    'status': DeliveryPersonnelRetrievalStatusEnum;
}

export const DeliveryPersonnelRetrievalStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type DeliveryPersonnelRetrievalStatusEnum = typeof DeliveryPersonnelRetrievalStatusEnum[keyof typeof DeliveryPersonnelRetrievalStatusEnum];

/**
 * 
 * @export
 * @interface DeliveryPersonnelsRetrieval
 */
export interface DeliveryPersonnelsRetrieval {
    /**
     * 
     * @type {PagingMetadata}
     * @memberof DeliveryPersonnelsRetrieval
     */
    'paging_metadata': PagingMetadata;
    /**
     * 
     * @type {Array<DeliveryPersonnelsRetrievalDeliveryPersonnels>}
     * @memberof DeliveryPersonnelsRetrieval
     */
    'delivery_personnels': Array<DeliveryPersonnelsRetrievalDeliveryPersonnels>;
}
/**
 * 
 * @export
 * @interface DeliveryPersonnelsRetrievalDeliveryPersonnels
 */
export interface DeliveryPersonnelsRetrievalDeliveryPersonnels {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelsRetrievalDeliveryPersonnels
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelsRetrievalDeliveryPersonnels
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof DeliveryPersonnelsRetrievalDeliveryPersonnels
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPersonnelsRetrievalDeliveryPersonnels
     */
    'deliveries_on_hand': number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPersonnelsRetrievalDeliveryPersonnels
     */
    'status': DeliveryPersonnelsRetrievalDeliveryPersonnelsStatusEnum;
}

export const DeliveryPersonnelsRetrievalDeliveryPersonnelsStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type DeliveryPersonnelsRetrievalDeliveryPersonnelsStatusEnum = typeof DeliveryPersonnelsRetrievalDeliveryPersonnelsStatusEnum[keyof typeof DeliveryPersonnelsRetrievalDeliveryPersonnelsStatusEnum];

/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface HealthStatus
 */
export interface HealthStatus {
    /**
     * 
     * @type {string}
     * @memberof HealthStatus
     */
    'status': HealthStatusStatusEnum;
}

export const HealthStatusStatusEnum = {
    Up: 'UP',
    Down: 'DOWN'
} as const;

export type HealthStatusStatusEnum = typeof HealthStatusStatusEnum[keyof typeof HealthStatusStatusEnum];

/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginRefresh
 */
export interface LoginRefresh {
    /**
     * 
     * @type {string}
     * @memberof LoginRefresh
     */
    'refresh_token': string;
}
/**
 * 
 * @export
 * @interface MobileContact
 */
export interface MobileContact {
    /**
     * 
     * @type {string}
     * @memberof MobileContact
     */
    'country_calling_code': string;
    /**
     * 
     * @type {string}
     * @memberof MobileContact
     */
    'phone_number': string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'error_description': string;
}
/**
 * 
 * @export
 * @interface OTP
 */
export interface OTP {
    /**
     * 
     * @type {string}
     * @memberof OTP
     */
    'email': string;
    /**
     * _6_ digits code
     * @type {string}
     * @memberof OTP
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface OrderDeliveredOfDeliveryLog
 */
export interface OrderDeliveredOfDeliveryLog {
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveredOfDeliveryLog
     */
    'id': string;
    /**
     * The image of the order which is in base64
     * @type {string}
     * @memberof OrderDeliveredOfDeliveryLog
     */
    'image': string;
}
/**
 * 
 * @export
 * @interface OrderDeliveryFailed
 */
export interface OrderDeliveryFailed {
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryFailed
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDeliveryFailed
     */
    'reason': string;
}
/**
 * 
 * @export
 * @interface OrderNoteCreation
 */
export interface OrderNoteCreation {
    /**
     * 
     * @type {string}
     * @memberof OrderNoteCreation
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface OrderNotesRetrieval
 */
export interface OrderNotesRetrieval {
    /**
     * 
     * @type {Array<OrderNotesRetrievalNotes>}
     * @memberof OrderNotesRetrieval
     */
    'notes': Array<OrderNotesRetrievalNotes>;
}
/**
 * 
 * @export
 * @interface OrderNotesRetrievalNotes
 */
export interface OrderNotesRetrievalNotes {
    /**
     * 
     * @type {string}
     * @memberof OrderNotesRetrievalNotes
     */
    'id': string;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof OrderNotesRetrievalNotes
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof OrderNotesRetrievalNotes
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderNotesRetrievalNotes
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface OrderRetrieval
 */
export interface OrderRetrieval {
    /**
     * 
     * @type {string}
     * @memberof OrderRetrieval
     */
    'order_number': string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderRetrieval
     */
    'order_status': OrderStatus;
    /**
     * 
     * @type {boolean}
     * @memberof OrderRetrieval
     */
    'is_verified': boolean;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof OrderRetrieval
     */
    'date_of_order': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrieval
     */
    'remarks': string;
    /**
     * 
     * @type {OrderRetrievalBranch}
     * @memberof OrderRetrieval
     */
    'branch': OrderRetrievalBranch;
    /**
     * 
     * @type {OrderRetrievalClinic}
     * @memberof OrderRetrieval
     */
    'clinic': OrderRetrievalClinic;
    /**
     * 
     * @type {OrderRetrievalClinicPurchaser}
     * @memberof OrderRetrieval
     */
    'clinic_purchaser': OrderRetrievalClinicPurchaser;
    /**
     * 
     * @type {Array<OrderRetrievalCompoundingTech>}
     * @memberof OrderRetrieval
     */
    'compounding_techs'?: Array<OrderRetrievalCompoundingTech>;
    /**
     * 
     * @type {OrderRetrievalDeliveryPersonnel}
     * @memberof OrderRetrieval
     */
    'delivery_personnel'?: OrderRetrievalDeliveryPersonnel;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof OrderRetrieval
     */
    'date_of_estimated_delivery'?: string;
    /**
     * reason for order status of _ORDER_CANCELLED_ or _ON_HOLD_
     * @type {string}
     * @memberof OrderRetrieval
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface OrderRetrievalBranch
 */
export interface OrderRetrievalBranch {
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalBranch
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalBranch
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrderRetrievalClinic
 */
export interface OrderRetrievalClinic {
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinic
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinic
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof OrderRetrievalClinic
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinic
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinic
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinic
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinic
     */
    'unit': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinic
     */
    'postal': string;
}
/**
 * 
 * @export
 * @interface OrderRetrievalClinicPurchaser
 */
export interface OrderRetrievalClinicPurchaser {
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinicPurchaser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalClinicPurchaser
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof OrderRetrievalClinicPurchaser
     */
    'mobile_contact': MobileContact;
}
/**
 * 
 * @export
 * @interface OrderRetrievalCompoundingTech
 */
export interface OrderRetrievalCompoundingTech {
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalCompoundingTech
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalCompoundingTech
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OrderRetrievalDeliveryPersonnel
 */
export interface OrderRetrievalDeliveryPersonnel {
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalDeliveryPersonnel
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrderRetrievalDeliveryPersonnel
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    OrderSubmitted: 'ORDER_SUBMITTED',
    Acknowledged: 'ACKNOWLEDGED',
    InProcess: 'IN_PROCESS',
    ReadyForDelivery: 'READY_FOR_DELIVERY',
    DeliveryAssigned: 'DELIVERY_ASSIGNED',
    OutForDelivery: 'OUT_FOR_DELIVERY',
    Delivered: 'DELIVERED',
    Fulfilled: 'FULFILLED',
    OrderCancelled: 'ORDER_CANCELLED',
    OnHold: 'ON_HOLD',
    DeliveryFailed: 'DELIVERY_FAILED'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @interface OrderUpdate
 */
export interface OrderUpdate {
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderUpdate
     */
    'order_status'?: OrderStatus;
    /**
     * 
     * @type {boolean}
     * @memberof OrderUpdate
     */
    'is_verified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdate
     */
    'branch_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderUpdate
     */
    'compounding_techs_id'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderUpdate
     */
    'delivery_personnel_id'?: string;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof OrderUpdate
     */
    'date_of_estimated_delivery'?: string;
    /**
     * reason for order status of _ORDER_CANCELLED_ or _ON_HOLD_
     * @type {string}
     * @memberof OrderUpdate
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface OrdersRetrieval
 */
export interface OrdersRetrieval {
    /**
     * 
     * @type {PagingMetadata}
     * @memberof OrdersRetrieval
     */
    'paging_metadata': PagingMetadata;
    /**
     * 
     * @type {Array<OrdersRetrievalOrders>}
     * @memberof OrdersRetrieval
     */
    'orders': Array<OrdersRetrievalOrders>;
}
/**
 * 
 * @export
 * @interface OrdersRetrievalOrders
 */
export interface OrdersRetrievalOrders {
    /**
     * 
     * @type {string}
     * @memberof OrdersRetrievalOrders
     */
    'order_number': string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrdersRetrievalOrders
     */
    'order_status': OrderStatus;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof OrdersRetrievalOrders
     */
    'date_of_order': string;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof OrdersRetrievalOrders
     */
    'date_delivered'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersRetrievalOrders
     */
    'remarks': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersRetrievalOrders
     */
    'branch_code': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersRetrievalOrders
     */
    'clinic_name': string;
    /**
     * Format in _ISO8601_ with _UTC_ timezone 
     * @type {string}
     * @memberof OrdersRetrievalOrders
     */
    'date_of_estimated_delivery'?: string;
}
/**
 * 
 * @export
 * @interface PagingMetadata
 */
export interface PagingMetadata {
    /**
     * 
     * @type {number}
     * @memberof PagingMetadata
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PagingMetadata
     */
    'per_page': number;
    /**
     * 
     * @type {number}
     * @memberof PagingMetadata
     */
    'total_page': number;
    /**
     * 
     * @type {number}
     * @memberof PagingMetadata
     */
    'total_count': number;
}
/**
 * 
 * @export
 * @interface PhoneNumberEnding
 */
export interface PhoneNumberEnding {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumberEnding
     */
    'phone_number_ending'?: string;
}
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'id': string;
    /**
     * The roles of a user
     * @type {Array<string>}
     * @memberof Profile
     */
    'roles': Array<ProfileRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof Profile
     */
    'mobile_contact': MobileContact;
}

export const ProfileRolesEnum = {
    Admin: 'ADMIN',
    Dispenser: 'DISPENSER',
    Pharmacist: 'PHARMACIST'
} as const;

export type ProfileRolesEnum = typeof ProfileRolesEnum[keyof typeof ProfileRolesEnum];

/**
 * 
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface UserCreation
 */
export interface UserCreation {
    /**
     * The roles of a user
     * @type {Array<string>}
     * @memberof UserCreation
     */
    'roles': Array<UserCreationRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserCreation
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof UserCreation
     */
    'mobile_contact': MobileContact;
}

export const UserCreationRolesEnum = {
    Admin: 'ADMIN',
    Dispenser: 'DISPENSER',
    Pharmacist: 'PHARMACIST'
} as const;

export type UserCreationRolesEnum = typeof UserCreationRolesEnum[keyof typeof UserCreationRolesEnum];

/**
 * 
 * @export
 * @interface UserProfileUpdate
 */
export interface UserProfileUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdate
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof UserProfileUpdate
     */
    'mobile_contact': MobileContact;
    /**
     * 
     * @type {UserProfileUpdateUpdatePassword}
     * @memberof UserProfileUpdate
     */
    'update_password'?: UserProfileUpdateUpdatePassword;
}
/**
 * 
 * @export
 * @interface UserProfileUpdateUpdatePassword
 */
export interface UserProfileUpdateUpdatePassword {
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateUpdatePassword
     */
    'current_password': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateUpdatePassword
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface UserRetrieval
 */
export interface UserRetrieval {
    /**
     * 
     * @type {string}
     * @memberof UserRetrieval
     */
    'id': string;
    /**
     * The roles of a user
     * @type {Array<string>}
     * @memberof UserRetrieval
     */
    'roles': Array<UserRetrievalRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserRetrieval
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserRetrieval
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof UserRetrieval
     */
    'mobile_contact': MobileContact;
    /**
     * The user\'s account status
     * @type {string}
     * @memberof UserRetrieval
     */
    'status': UserRetrievalStatusEnum;
}

export const UserRetrievalRolesEnum = {
    Admin: 'ADMIN',
    Dispenser: 'DISPENSER',
    Pharmacist: 'PHARMACIST'
} as const;

export type UserRetrievalRolesEnum = typeof UserRetrievalRolesEnum[keyof typeof UserRetrievalRolesEnum];
export const UserRetrievalStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type UserRetrievalStatusEnum = typeof UserRetrievalStatusEnum[keyof typeof UserRetrievalStatusEnum];

/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * The roles of a user
     * @type {Array<string>}
     * @memberof UserUpdate
     */
    'roles': Array<UserUpdateRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    'name': string;
    /**
     * 
     * @type {MobileContact}
     * @memberof UserUpdate
     */
    'mobile_contact': MobileContact;
    /**
     * The user\'s account status
     * @type {string}
     * @memberof UserUpdate
     */
    'status': UserUpdateStatusEnum;
}

export const UserUpdateRolesEnum = {
    Admin: 'ADMIN',
    Dispenser: 'DISPENSER',
    Pharmacist: 'PHARMACIST'
} as const;

export type UserUpdateRolesEnum = typeof UserUpdateRolesEnum[keyof typeof UserUpdateRolesEnum];
export const UserUpdateStatusEnum = {
    Active: 'ACTIVE',
    Pending: 'PENDING',
    Inactive: 'INACTIVE'
} as const;

export type UserUpdateStatusEnum = typeof UserUpdateStatusEnum[keyof typeof UserUpdateStatusEnum];

/**
 * 
 * @export
 * @interface UsersRetrieval
 */
export interface UsersRetrieval {
    /**
     * 
     * @type {PagingMetadata}
     * @memberof UsersRetrieval
     */
    'paging_metadata': PagingMetadata;
    /**
     * 
     * @type {Array<UserRetrieval>}
     * @memberof UsersRetrieval
     */
    'users': Array<UserRetrieval>;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To validate otp
         * @param {OTP} oTP 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOtpPOST: async (oTP: OTP, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oTP' is not null or undefined
            assertParamExists('loginOtpPOST', 'oTP', oTP)
            const localVarPath = `/login/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oTP, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To perform login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPOST: async (login: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('loginPOST', 'login', login)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To perform login session refresh
         * @param {LoginRefresh} loginRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRefreshPOST: async (loginRefresh: LoginRefresh, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRefresh' is not null or undefined
            assertParamExists('loginRefreshPOST', 'loginRefresh', loginRefresh)
            const localVarPath = `/login/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To validate otp
         * @param {OTP} oTP 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginOtpPOST(oTP: OTP, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginOtpPOST(oTP, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To perform login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginPOST(login: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneNumberEnding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginPOST(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To perform login session refresh
         * @param {LoginRefresh} loginRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginRefreshPOST(loginRefresh: LoginRefresh, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginRefreshPOST(loginRefresh, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary To validate otp
         * @param {OTP} oTP 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginOtpPOST(oTP: OTP, options?: any): AxiosPromise<ClientToken> {
            return localVarFp.loginOtpPOST(oTP, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To perform login
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginPOST(login: Login, options?: any): AxiosPromise<PhoneNumberEnding> {
            return localVarFp.loginPOST(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To perform login session refresh
         * @param {LoginRefresh} loginRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRefreshPOST(loginRefresh: LoginRefresh, options?: any): AxiosPromise<ClientToken> {
            return localVarFp.loginRefreshPOST(loginRefresh, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary To validate otp
     * @param {OTP} oTP 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginOtpPOST(oTP: OTP, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginOtpPOST(oTP, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To perform login
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginPOST(login: Login, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginPOST(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To perform login session refresh
     * @param {LoginRefresh} loginRefresh 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginRefreshPOST(loginRefresh: LoginRefresh, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginRefreshPOST(loginRefresh, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BranchApi - axios parameter creator
 * @export
 */
export const BranchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To create a branch
         * @param {BranchCreation} branchCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchCreatePOST: async (branchCreation: BranchCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchCreation' is not null or undefined
            assertParamExists('branchCreatePOST', 'branchCreation', branchCreation)
            const localVarPath = `/branch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the details of a branch
         * @param {string} id The ID of a branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('branchGET', 'id', id)
            const localVarPath = `/branch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update details of a branch
         * @param {string} id The ID of a branch
         * @param {BranchCreation} branchCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchUpdatePOST: async (id: string, branchCreation: BranchCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('branchUpdatePOST', 'id', id)
            // verify required parameter 'branchCreation' is not null or undefined
            assertParamExists('branchUpdatePOST', 'branchCreation', branchCreation)
            const localVarPath = `/branch/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all branches
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'SINGAPORE' | 'MALAYSIA'} [country] To filter by country
         * @param {string} [search] To search by name
         * @param {'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchesGET: async (page: number, perPage: number, country?: 'SINGAPORE' | 'MALAYSIA', search?: string, sort?: 'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('branchesGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('branchesGET', 'perPage', perPage)
            const localVarPath = `/branches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BranchApi - functional programming interface
 * @export
 */
export const BranchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BranchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To create a branch
         * @param {BranchCreation} branchCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchCreatePOST(branchCreation: BranchCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchCreatePOST(branchCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the details of a branch
         * @param {string} id The ID of a branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update details of a branch
         * @param {string} id The ID of a branch
         * @param {BranchCreation} branchCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchUpdatePOST(id: string, branchCreation: BranchCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchUpdatePOST(id, branchCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all branches
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'SINGAPORE' | 'MALAYSIA'} [country] To filter by country
         * @param {string} [search] To search by name
         * @param {'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchesGET(page: number, perPage: number, country?: 'SINGAPORE' | 'MALAYSIA', search?: string, sort?: 'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchesRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchesGET(page, perPage, country, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BranchApi - factory interface
 * @export
 */
export const BranchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BranchApiFp(configuration)
    return {
        /**
         * 
         * @summary To create a branch
         * @param {BranchCreation} branchCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchCreatePOST(branchCreation: BranchCreation, options?: any): AxiosPromise<void> {
            return localVarFp.branchCreatePOST(branchCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the details of a branch
         * @param {string} id The ID of a branch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchGET(id: string, options?: any): AxiosPromise<BranchRetrieval> {
            return localVarFp.branchGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update details of a branch
         * @param {string} id The ID of a branch
         * @param {BranchCreation} branchCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchUpdatePOST(id: string, branchCreation: BranchCreation, options?: any): AxiosPromise<void> {
            return localVarFp.branchUpdatePOST(id, branchCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all branches
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'SINGAPORE' | 'MALAYSIA'} [country] To filter by country
         * @param {string} [search] To search by name
         * @param {'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchesGET(page: number, perPage: number, country?: 'SINGAPORE' | 'MALAYSIA', search?: string, sort?: 'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<BranchesRetrieval> {
            return localVarFp.branchesGET(page, perPage, country, search, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BranchApi - object-oriented interface
 * @export
 * @class BranchApi
 * @extends {BaseAPI}
 */
export class BranchApi extends BaseAPI {
    /**
     * 
     * @summary To create a branch
     * @param {BranchCreation} branchCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchApi
     */
    public branchCreatePOST(branchCreation: BranchCreation, options?: AxiosRequestConfig) {
        return BranchApiFp(this.configuration).branchCreatePOST(branchCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the details of a branch
     * @param {string} id The ID of a branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchApi
     */
    public branchGET(id: string, options?: AxiosRequestConfig) {
        return BranchApiFp(this.configuration).branchGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update details of a branch
     * @param {string} id The ID of a branch
     * @param {BranchCreation} branchCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchApi
     */
    public branchUpdatePOST(id: string, branchCreation: BranchCreation, options?: AxiosRequestConfig) {
        return BranchApiFp(this.configuration).branchUpdatePOST(id, branchCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all branches
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {'SINGAPORE' | 'MALAYSIA'} [country] To filter by country
     * @param {string} [search] To search by name
     * @param {'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchApi
     */
    public branchesGET(page: number, perPage: number, country?: 'SINGAPORE' | 'MALAYSIA', search?: string, sort?: 'NAME' | 'BRANCH_CODE' | 'COUNTRY' | 'ADDRESS' | 'CLINIC_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return BranchApiFp(this.configuration).branchesGET(page, perPage, country, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClinicApi - axios parameter creator
 * @export
 */
export const ClinicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To get all clinic purchasers of a clinic
         * @param {string} id The ID of a clinic
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicClinicPurchasersGET: async (id: string, page: number, perPage: number, search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clinicClinicPurchasersGET', 'id', id)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('clinicClinicPurchasersGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('clinicClinicPurchasersGET', 'perPage', perPage)
            const localVarPath = `/clinic/{id}/clinic-purchasers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To create a clinic
         * @param {ClinicCreation} clinicCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicCreatePOST: async (clinicCreation: ClinicCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicCreation' is not null or undefined
            assertParamExists('clinicCreatePOST', 'clinicCreation', clinicCreation)
            const localVarPath = `/clinic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clinicCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the details of a clinic
         * @param {string} id The ID of a clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clinicGET', 'id', id)
            const localVarPath = `/clinic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update details of a clinic
         * @param {string} id The ID of a clinic
         * @param {ClinicCreation} clinicCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicUpdatePOST: async (id: string, clinicCreation: ClinicCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clinicUpdatePOST', 'id', id)
            // verify required parameter 'clinicCreation' is not null or undefined
            assertParamExists('clinicUpdatePOST', 'clinicCreation', clinicCreation)
            const localVarPath = `/clinic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clinicCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all clinics
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'SUSPENDED'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicsGET: async (page: number, perPage: number, status?: 'ACTIVE' | 'SUSPENDED', search?: string, sort?: 'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('clinicsGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('clinicsGET', 'perPage', perPage)
            const localVarPath = `/clinics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClinicApi - functional programming interface
 * @export
 */
export const ClinicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClinicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To get all clinic purchasers of a clinic
         * @param {string} id The ID of a clinic
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicClinicPurchasersGET(id: string, page: number, perPage: number, search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicPurchasersRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicClinicPurchasersGET(id, page, perPage, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To create a clinic
         * @param {ClinicCreation} clinicCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicCreatePOST(clinicCreation: ClinicCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicCreatePOST(clinicCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the details of a clinic
         * @param {string} id The ID of a clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update details of a clinic
         * @param {string} id The ID of a clinic
         * @param {ClinicCreation} clinicCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicUpdatePOST(id: string, clinicCreation: ClinicCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicUpdatePOST(id, clinicCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all clinics
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'SUSPENDED'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicsGET(page: number, perPage: number, status?: 'ACTIVE' | 'SUSPENDED', search?: string, sort?: 'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicsRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicsGET(page, perPage, status, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClinicApi - factory interface
 * @export
 */
export const ClinicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClinicApiFp(configuration)
    return {
        /**
         * 
         * @summary To get all clinic purchasers of a clinic
         * @param {string} id The ID of a clinic
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicClinicPurchasersGET(id: string, page: number, perPage: number, search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<ClinicPurchasersRetrieval> {
            return localVarFp.clinicClinicPurchasersGET(id, page, perPage, search, sort, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To create a clinic
         * @param {ClinicCreation} clinicCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicCreatePOST(clinicCreation: ClinicCreation, options?: any): AxiosPromise<void> {
            return localVarFp.clinicCreatePOST(clinicCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the details of a clinic
         * @param {string} id The ID of a clinic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicGET(id: string, options?: any): AxiosPromise<ClinicRetrieval> {
            return localVarFp.clinicGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update details of a clinic
         * @param {string} id The ID of a clinic
         * @param {ClinicCreation} clinicCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicUpdatePOST(id: string, clinicCreation: ClinicCreation, options?: any): AxiosPromise<void> {
            return localVarFp.clinicUpdatePOST(id, clinicCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all clinics
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'SUSPENDED'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicsGET(page: number, perPage: number, status?: 'ACTIVE' | 'SUSPENDED', search?: string, sort?: 'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<ClinicsRetrieval> {
            return localVarFp.clinicsGET(page, perPage, status, search, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClinicApi - object-oriented interface
 * @export
 * @class ClinicApi
 * @extends {BaseAPI}
 */
export class ClinicApi extends BaseAPI {
    /**
     * 
     * @summary To get all clinic purchasers of a clinic
     * @param {string} id The ID of a clinic
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {string} [search] To search by name
     * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicClinicPurchasersGET(id: string, page: number, perPage: number, search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return ClinicApiFp(this.configuration).clinicClinicPurchasersGET(id, page, perPage, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To create a clinic
     * @param {ClinicCreation} clinicCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicCreatePOST(clinicCreation: ClinicCreation, options?: AxiosRequestConfig) {
        return ClinicApiFp(this.configuration).clinicCreatePOST(clinicCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the details of a clinic
     * @param {string} id The ID of a clinic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicGET(id: string, options?: AxiosRequestConfig) {
        return ClinicApiFp(this.configuration).clinicGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update details of a clinic
     * @param {string} id The ID of a clinic
     * @param {ClinicCreation} clinicCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicUpdatePOST(id: string, clinicCreation: ClinicCreation, options?: AxiosRequestConfig) {
        return ClinicApiFp(this.configuration).clinicUpdatePOST(id, clinicCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all clinics
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {'ACTIVE' | 'SUSPENDED'} [status] To filter by status
     * @param {string} [search] To search by name
     * @param {'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicApi
     */
    public clinicsGET(page: number, perPage: number, status?: 'ACTIVE' | 'SUSPENDED', search?: string, sort?: 'NAME' | 'EMAIL' | 'ADDRESS' | 'COUNTRY' | 'POSTAL' | 'BRANCH_CODE' | 'CLINIC_PURCHASER_COUNTS' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return ClinicApiFp(this.configuration).clinicsGET(page, perPage, status, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClinicPurchaserApi - axios parameter creator
 * @export
 */
export const ClinicPurchaserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To create a clinic purchaser
         * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchaserCreatePOST: async (clinicPurchaserCreation: ClinicPurchaserCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clinicPurchaserCreation' is not null or undefined
            assertParamExists('clinicPurchaserCreatePOST', 'clinicPurchaserCreation', clinicPurchaserCreation)
            const localVarPath = `/clinic-purchaser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clinicPurchaserCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the details of a clinic purchaser
         * @param {string} id The ID of a clinic purchaser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchaserGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clinicPurchaserGET', 'id', id)
            const localVarPath = `/clinic-purchaser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update details of a clinic purchaser
         * @param {string} id The ID of a clinic purchaser
         * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchaserUpdatePOST: async (id: string, clinicPurchaserCreation: ClinicPurchaserCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clinicPurchaserUpdatePOST', 'id', id)
            // verify required parameter 'clinicPurchaserCreation' is not null or undefined
            assertParamExists('clinicPurchaserUpdatePOST', 'clinicPurchaserCreation', clinicPurchaserCreation)
            const localVarPath = `/clinic-purchaser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clinicPurchaserCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all clinic purchasers
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchasersGET: async (page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('clinicPurchasersGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('clinicPurchasersGET', 'perPage', perPage)
            const localVarPath = `/clinic-purchasers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClinicPurchaserApi - functional programming interface
 * @export
 */
export const ClinicPurchaserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClinicPurchaserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To create a clinic purchaser
         * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicPurchaserCreatePOST(clinicPurchaserCreation: ClinicPurchaserCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicPurchaserCreatePOST(clinicPurchaserCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the details of a clinic purchaser
         * @param {string} id The ID of a clinic purchaser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicPurchaserGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicPurchaserRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicPurchaserGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update details of a clinic purchaser
         * @param {string} id The ID of a clinic purchaser
         * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicPurchaserUpdatePOST(id: string, clinicPurchaserCreation: ClinicPurchaserCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicPurchaserUpdatePOST(id, clinicPurchaserCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all clinic purchasers
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clinicPurchasersGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicPurchasersRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clinicPurchasersGET(page, perPage, status, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClinicPurchaserApi - factory interface
 * @export
 */
export const ClinicPurchaserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClinicPurchaserApiFp(configuration)
    return {
        /**
         * 
         * @summary To create a clinic purchaser
         * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchaserCreatePOST(clinicPurchaserCreation: ClinicPurchaserCreation, options?: any): AxiosPromise<void> {
            return localVarFp.clinicPurchaserCreatePOST(clinicPurchaserCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the details of a clinic purchaser
         * @param {string} id The ID of a clinic purchaser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchaserGET(id: string, options?: any): AxiosPromise<ClinicPurchaserRetrieval> {
            return localVarFp.clinicPurchaserGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update details of a clinic purchaser
         * @param {string} id The ID of a clinic purchaser
         * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchaserUpdatePOST(id: string, clinicPurchaserCreation: ClinicPurchaserCreation, options?: any): AxiosPromise<void> {
            return localVarFp.clinicPurchaserUpdatePOST(id, clinicPurchaserCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all clinic purchasers
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clinicPurchasersGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<ClinicPurchasersRetrieval> {
            return localVarFp.clinicPurchasersGET(page, perPage, status, search, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClinicPurchaserApi - object-oriented interface
 * @export
 * @class ClinicPurchaserApi
 * @extends {BaseAPI}
 */
export class ClinicPurchaserApi extends BaseAPI {
    /**
     * 
     * @summary To create a clinic purchaser
     * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicPurchaserApi
     */
    public clinicPurchaserCreatePOST(clinicPurchaserCreation: ClinicPurchaserCreation, options?: AxiosRequestConfig) {
        return ClinicPurchaserApiFp(this.configuration).clinicPurchaserCreatePOST(clinicPurchaserCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the details of a clinic purchaser
     * @param {string} id The ID of a clinic purchaser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicPurchaserApi
     */
    public clinicPurchaserGET(id: string, options?: AxiosRequestConfig) {
        return ClinicPurchaserApiFp(this.configuration).clinicPurchaserGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update details of a clinic purchaser
     * @param {string} id The ID of a clinic purchaser
     * @param {ClinicPurchaserCreation} clinicPurchaserCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicPurchaserApi
     */
    public clinicPurchaserUpdatePOST(id: string, clinicPurchaserCreation: ClinicPurchaserCreation, options?: AxiosRequestConfig) {
        return ClinicPurchaserApiFp(this.configuration).clinicPurchaserUpdatePOST(id, clinicPurchaserCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all clinic purchasers
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
     * @param {string} [search] To search by name
     * @param {'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClinicPurchaserApi
     */
    public clinicPurchasersGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'CLINIC_NAME' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return ClinicPurchaserApiFp(this.configuration).clinicPurchasersGET(page, perPage, status, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompoundingTechApi - axios parameter creator
 * @export
 */
export const CompoundingTechApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To create a compounding tech
         * @param {CompoundingTechCreation} compoundingTechCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechCreatePOST: async (compoundingTechCreation: CompoundingTechCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'compoundingTechCreation' is not null or undefined
            assertParamExists('compoundingTechCreatePOST', 'compoundingTechCreation', compoundingTechCreation)
            const localVarPath = `/compounding-tech`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compoundingTechCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the details of a compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('compoundingTechGET', 'id', id)
            const localVarPath = `/compounding-tech/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all orders of an compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [search] To search by name or clinic name
         * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechOrdersGET: async (id: string, page: number, perPage: number, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('compoundingTechOrdersGET', 'id', id)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('compoundingTechOrdersGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('compoundingTechOrdersGET', 'perPage', perPage)
            const localVarPath = `/compounding-tech/{id}/orders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update details of a compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {CompoundingTechCreation} compoundingTechCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechUpdatePOST: async (id: string, compoundingTechCreation: CompoundingTechCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('compoundingTechUpdatePOST', 'id', id)
            // verify required parameter 'compoundingTechCreation' is not null or undefined
            assertParamExists('compoundingTechUpdatePOST', 'compoundingTechCreation', compoundingTechCreation)
            const localVarPath = `/compounding-tech/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compoundingTechCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all compounding techs
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechsGET: async (page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('compoundingTechsGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('compoundingTechsGET', 'perPage', perPage)
            const localVarPath = `/compounding-techs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompoundingTechApi - functional programming interface
 * @export
 */
export const CompoundingTechApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompoundingTechApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To create a compounding tech
         * @param {CompoundingTechCreation} compoundingTechCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compoundingTechCreatePOST(compoundingTechCreation: CompoundingTechCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compoundingTechCreatePOST(compoundingTechCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the details of a compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compoundingTechGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompoundingTechRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compoundingTechGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all orders of an compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [search] To search by name or clinic name
         * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compoundingTechOrdersGET(id: string, page: number, perPage: number, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compoundingTechOrdersGET(id, page, perPage, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update details of a compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {CompoundingTechCreation} compoundingTechCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compoundingTechUpdatePOST(id: string, compoundingTechCreation: CompoundingTechCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compoundingTechUpdatePOST(id, compoundingTechCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all compounding techs
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compoundingTechsGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompoundingTechsRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compoundingTechsGET(page, perPage, status, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompoundingTechApi - factory interface
 * @export
 */
export const CompoundingTechApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompoundingTechApiFp(configuration)
    return {
        /**
         * 
         * @summary To create a compounding tech
         * @param {CompoundingTechCreation} compoundingTechCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechCreatePOST(compoundingTechCreation: CompoundingTechCreation, options?: any): AxiosPromise<void> {
            return localVarFp.compoundingTechCreatePOST(compoundingTechCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the details of a compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechGET(id: string, options?: any): AxiosPromise<CompoundingTechRetrieval> {
            return localVarFp.compoundingTechGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all orders of an compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [search] To search by name or clinic name
         * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechOrdersGET(id: string, page: number, perPage: number, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<OrdersRetrieval> {
            return localVarFp.compoundingTechOrdersGET(id, page, perPage, search, sort, direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update details of a compounding tech
         * @param {string} id The ID of a compounding tech
         * @param {CompoundingTechCreation} compoundingTechCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechUpdatePOST(id: string, compoundingTechCreation: CompoundingTechCreation, options?: any): AxiosPromise<void> {
            return localVarFp.compoundingTechUpdatePOST(id, compoundingTechCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all compounding techs
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compoundingTechsGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<CompoundingTechsRetrieval> {
            return localVarFp.compoundingTechsGET(page, perPage, status, search, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompoundingTechApi - object-oriented interface
 * @export
 * @class CompoundingTechApi
 * @extends {BaseAPI}
 */
export class CompoundingTechApi extends BaseAPI {
    /**
     * 
     * @summary To create a compounding tech
     * @param {CompoundingTechCreation} compoundingTechCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundingTechApi
     */
    public compoundingTechCreatePOST(compoundingTechCreation: CompoundingTechCreation, options?: AxiosRequestConfig) {
        return CompoundingTechApiFp(this.configuration).compoundingTechCreatePOST(compoundingTechCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the details of a compounding tech
     * @param {string} id The ID of a compounding tech
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundingTechApi
     */
    public compoundingTechGET(id: string, options?: AxiosRequestConfig) {
        return CompoundingTechApiFp(this.configuration).compoundingTechGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all orders of an compounding tech
     * @param {string} id The ID of a compounding tech
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {string} [search] To search by name or clinic name
     * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundingTechApi
     */
    public compoundingTechOrdersGET(id: string, page: number, perPage: number, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return CompoundingTechApiFp(this.configuration).compoundingTechOrdersGET(id, page, perPage, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update details of a compounding tech
     * @param {string} id The ID of a compounding tech
     * @param {CompoundingTechCreation} compoundingTechCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundingTechApi
     */
    public compoundingTechUpdatePOST(id: string, compoundingTechCreation: CompoundingTechCreation, options?: AxiosRequestConfig) {
        return CompoundingTechApiFp(this.configuration).compoundingTechUpdatePOST(id, compoundingTechCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all compounding techs
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
     * @param {string} [search] To search by name
     * @param {'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompoundingTechApi
     */
    public compoundingTechsGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'EMAIL' | 'MOBILE' | 'ORDERS_ON_HAND' | 'BRANCH_CODE' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return CompoundingTechApiFp(this.configuration).compoundingTechsGET(page, perPage, status, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To view the server health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To view the server health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary To view the server health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGET(options?: any): AxiosPromise<HealthStatus> {
            return localVarFp.healthGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary To view the server health status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthGET(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeliveryPersonnelApi - axios parameter creator
 * @export
 */
export const DeliveryPersonnelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To create a delivery personnel
         * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelCreatePOST: async (deliveryPersonnelCreation: DeliveryPersonnelCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryPersonnelCreation' is not null or undefined
            assertParamExists('deliveryPersonnelCreatePOST', 'deliveryPersonnelCreation', deliveryPersonnelCreation)
            const localVarPath = `/delivery-personnel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryPersonnelCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the details of a delivery personnel
         * @param {string} id The ID of a delivery personnel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deliveryPersonnelGET', 'id', id)
            const localVarPath = `/delivery-personnel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update details of a delivery personnel
         * @param {string} id The ID of a delivery personnel
         * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelUpdatePOST: async (id: string, deliveryPersonnelCreation: DeliveryPersonnelCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deliveryPersonnelUpdatePOST', 'id', id)
            // verify required parameter 'deliveryPersonnelCreation' is not null or undefined
            assertParamExists('deliveryPersonnelUpdatePOST', 'deliveryPersonnelCreation', deliveryPersonnelCreation)
            const localVarPath = `/delivery-personnel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryPersonnelCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all delivery personnels
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelsGET: async (page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('deliveryPersonnelsGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('deliveryPersonnelsGET', 'perPage', perPage)
            const localVarPath = `/delivery-personnels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryPersonnelApi - functional programming interface
 * @export
 */
export const DeliveryPersonnelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryPersonnelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To create a delivery personnel
         * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryPersonnelCreatePOST(deliveryPersonnelCreation: DeliveryPersonnelCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryPersonnelCreatePOST(deliveryPersonnelCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the details of a delivery personnel
         * @param {string} id The ID of a delivery personnel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryPersonnelGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryPersonnelRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryPersonnelGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update details of a delivery personnel
         * @param {string} id The ID of a delivery personnel
         * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryPersonnelUpdatePOST(id: string, deliveryPersonnelCreation: DeliveryPersonnelCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryPersonnelUpdatePOST(id, deliveryPersonnelCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all delivery personnels
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliveryPersonnelsGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryPersonnelsRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryPersonnelsGET(page, perPage, status, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeliveryPersonnelApi - factory interface
 * @export
 */
export const DeliveryPersonnelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryPersonnelApiFp(configuration)
    return {
        /**
         * 
         * @summary To create a delivery personnel
         * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelCreatePOST(deliveryPersonnelCreation: DeliveryPersonnelCreation, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryPersonnelCreatePOST(deliveryPersonnelCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the details of a delivery personnel
         * @param {string} id The ID of a delivery personnel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelGET(id: string, options?: any): AxiosPromise<DeliveryPersonnelRetrieval> {
            return localVarFp.deliveryPersonnelGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update details of a delivery personnel
         * @param {string} id The ID of a delivery personnel
         * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelUpdatePOST(id: string, deliveryPersonnelCreation: DeliveryPersonnelCreation, options?: any): AxiosPromise<void> {
            return localVarFp.deliveryPersonnelUpdatePOST(id, deliveryPersonnelCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all delivery personnels
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliveryPersonnelsGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<DeliveryPersonnelsRetrieval> {
            return localVarFp.deliveryPersonnelsGET(page, perPage, status, search, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryPersonnelApi - object-oriented interface
 * @export
 * @class DeliveryPersonnelApi
 * @extends {BaseAPI}
 */
export class DeliveryPersonnelApi extends BaseAPI {
    /**
     * 
     * @summary To create a delivery personnel
     * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryPersonnelApi
     */
    public deliveryPersonnelCreatePOST(deliveryPersonnelCreation: DeliveryPersonnelCreation, options?: AxiosRequestConfig) {
        return DeliveryPersonnelApiFp(this.configuration).deliveryPersonnelCreatePOST(deliveryPersonnelCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the details of a delivery personnel
     * @param {string} id The ID of a delivery personnel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryPersonnelApi
     */
    public deliveryPersonnelGET(id: string, options?: AxiosRequestConfig) {
        return DeliveryPersonnelApiFp(this.configuration).deliveryPersonnelGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update details of a delivery personnel
     * @param {string} id The ID of a delivery personnel
     * @param {DeliveryPersonnelCreation} deliveryPersonnelCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryPersonnelApi
     */
    public deliveryPersonnelUpdatePOST(id: string, deliveryPersonnelCreation: DeliveryPersonnelCreation, options?: AxiosRequestConfig) {
        return DeliveryPersonnelApiFp(this.configuration).deliveryPersonnelUpdatePOST(id, deliveryPersonnelCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all delivery personnels
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {'ACTIVE' | 'INACTIVE'} [status] To filter by status
     * @param {string} [search] To search by name
     * @param {'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryPersonnelApi
     */
    public deliveryPersonnelsGET(page: number, perPage: number, status?: 'ACTIVE' | 'INACTIVE', search?: string, sort?: 'NAME' | 'MOBILE' | 'DELIVERIES_ON_HAND' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return DeliveryPersonnelApiFp(this.configuration).deliveryPersonnelsGET(page, perPage, status, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To get all activities of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderActivityLogGET: async (orderNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderActivityLogGET', 'orderNumber', orderNumber)
            const localVarPath = `/order/{order_number}/activity-log`
                .replace(`{${"order_number"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get delivery log of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDeliveryLogGET: async (orderNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderDeliveryLogGET', 'orderNumber', orderNumber)
            const localVarPath = `/order/{order_number}/delivery-log`
                .replace(`{${"order_number"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the details of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGET: async (orderNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderGET', 'orderNumber', orderNumber)
            const localVarPath = `/order/{order_number}`
                .replace(`{${"order_number"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the image of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderImageGET: async (orderNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderImageGET', 'orderNumber', orderNumber)
            const localVarPath = `/order/{order_number}/image`
                .replace(`{${"order_number"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To add a note to an order
         * @param {string} orderNumber 
         * @param {OrderNoteCreation} orderNoteCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderNotesCreatePOST: async (orderNumber: string, orderNoteCreation: OrderNoteCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderNotesCreatePOST', 'orderNumber', orderNumber)
            // verify required parameter 'orderNoteCreation' is not null or undefined
            assertParamExists('orderNotesCreatePOST', 'orderNoteCreation', orderNoteCreation)
            const localVarPath = `/order/{order_number}/notes`
                .replace(`{${"order_number"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderNoteCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all notes of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderNotesGET: async (orderNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderNotesGET', 'orderNumber', orderNumber)
            const localVarPath = `/order/{order_number}/notes`
                .replace(`{${"order_number"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update the details of an order
         * @param {string} orderNumber 
         * @param {OrderUpdate} orderUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdatePOST: async (orderNumber: string, orderUpdate: OrderUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderNumber' is not null or undefined
            assertParamExists('orderUpdatePOST', 'orderNumber', orderNumber)
            // verify required parameter 'orderUpdate' is not null or undefined
            assertParamExists('orderUpdatePOST', 'orderUpdate', orderUpdate)
            const localVarPath = `/order/{order_number}`
                .replace(`{${"order_number"}}`, encodeURIComponent(String(orderNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all orders
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [startDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
         * @param {string} [endDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
         * @param {Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>} [includeStatuses] To filter orders by indicating statuses to be returned 
         * @param {string} [branch] To filter orders by branch name
         * @param {boolean} [isVerified] To filter orders by verified state  Leave this field empty to get both verified and non verified orders 
         * @param {string} [search] To search by order number or clinic name
         * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGET: async (page: number, perPage: number, startDate?: string, endDate?: string, includeStatuses?: Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>, branch?: string, isVerified?: boolean, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('ordersGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('ordersGET', 'perPage', perPage)
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (includeStatuses) {
                localVarQueryParameter['include_statuses'] = includeStatuses;
            }

            if (branch !== undefined) {
                localVarQueryParameter['branch'] = branch;
            }

            if (isVerified !== undefined) {
                localVarQueryParameter['is_verified'] = isVerified;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To get all activities of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderActivityLogGET(orderNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderActivityLogGET(orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get delivery log of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderDeliveryLogGET(orderNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderDeliveryLogGET(orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the details of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderGET(orderNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderGET(orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the image of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderImageGET(orderNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderImageGET(orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To add a note to an order
         * @param {string} orderNumber 
         * @param {OrderNoteCreation} orderNoteCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderNotesCreatePOST(orderNumber: string, orderNoteCreation: OrderNoteCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderNotesCreatePOST(orderNumber, orderNoteCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all notes of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderNotesGET(orderNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderNotesRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderNotesGET(orderNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update the details of an order
         * @param {string} orderNumber 
         * @param {OrderUpdate} orderUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUpdatePOST(orderNumber: string, orderUpdate: OrderUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUpdatePOST(orderNumber, orderUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all orders
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [startDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
         * @param {string} [endDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
         * @param {Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>} [includeStatuses] To filter orders by indicating statuses to be returned 
         * @param {string} [branch] To filter orders by branch name
         * @param {boolean} [isVerified] To filter orders by verified state  Leave this field empty to get both verified and non verified orders 
         * @param {string} [search] To search by order number or clinic name
         * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersGET(page: number, perPage: number, startDate?: string, endDate?: string, includeStatuses?: Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>, branch?: string, isVerified?: boolean, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersGET(page, perPage, startDate, endDate, includeStatuses, branch, isVerified, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary To get all activities of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderActivityLogGET(orderNumber: string, options?: any): AxiosPromise<ActivityLog> {
            return localVarFp.orderActivityLogGET(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get delivery log of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderDeliveryLogGET(orderNumber: string, options?: any): AxiosPromise<string> {
            return localVarFp.orderDeliveryLogGET(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the details of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderGET(orderNumber: string, options?: any): AxiosPromise<OrderRetrieval> {
            return localVarFp.orderGET(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the image of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderImageGET(orderNumber: string, options?: any): AxiosPromise<File> {
            return localVarFp.orderImageGET(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To add a note to an order
         * @param {string} orderNumber 
         * @param {OrderNoteCreation} orderNoteCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderNotesCreatePOST(orderNumber: string, orderNoteCreation: OrderNoteCreation, options?: any): AxiosPromise<void> {
            return localVarFp.orderNotesCreatePOST(orderNumber, orderNoteCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all notes of an order
         * @param {string} orderNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderNotesGET(orderNumber: string, options?: any): AxiosPromise<OrderNotesRetrieval> {
            return localVarFp.orderNotesGET(orderNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update the details of an order
         * @param {string} orderNumber 
         * @param {OrderUpdate} orderUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdatePOST(orderNumber: string, orderUpdate: OrderUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.orderUpdatePOST(orderNumber, orderUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all orders
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {string} [startDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
         * @param {string} [endDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
         * @param {Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>} [includeStatuses] To filter orders by indicating statuses to be returned 
         * @param {string} [branch] To filter orders by branch name
         * @param {boolean} [isVerified] To filter orders by verified state  Leave this field empty to get both verified and non verified orders 
         * @param {string} [search] To search by order number or clinic name
         * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersGET(page: number, perPage: number, startDate?: string, endDate?: string, includeStatuses?: Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>, branch?: string, isVerified?: boolean, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<OrdersRetrieval> {
            return localVarFp.ordersGET(page, perPage, startDate, endDate, includeStatuses, branch, isVerified, search, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @summary To get all activities of an order
     * @param {string} orderNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderActivityLogGET(orderNumber: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderActivityLogGET(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get delivery log of an order
     * @param {string} orderNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderDeliveryLogGET(orderNumber: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderDeliveryLogGET(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the details of an order
     * @param {string} orderNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderGET(orderNumber: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderGET(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the image of an order
     * @param {string} orderNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderImageGET(orderNumber: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderImageGET(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To add a note to an order
     * @param {string} orderNumber 
     * @param {OrderNoteCreation} orderNoteCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderNotesCreatePOST(orderNumber: string, orderNoteCreation: OrderNoteCreation, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderNotesCreatePOST(orderNumber, orderNoteCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all notes of an order
     * @param {string} orderNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderNotesGET(orderNumber: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderNotesGET(orderNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update the details of an order
     * @param {string} orderNumber 
     * @param {OrderUpdate} orderUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public orderUpdatePOST(orderNumber: string, orderUpdate: OrderUpdate, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).orderUpdatePOST(orderNumber, orderUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all orders
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {string} [startDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
     * @param {string} [endDate] To filter orders after this date  To be in ISO8601 format with timezone offset 
     * @param {Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>} [includeStatuses] To filter orders by indicating statuses to be returned 
     * @param {string} [branch] To filter orders by branch name
     * @param {boolean} [isVerified] To filter orders by verified state  Leave this field empty to get both verified and non verified orders 
     * @param {string} [search] To search by order number or clinic name
     * @param {'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public ordersGET(page: number, perPage: number, startDate?: string, endDate?: string, includeStatuses?: Array<'ORDER_SUBMITTED' | 'ACKNOWLEDGED' | 'IN_PROCESS' | 'READY_FOR_DELIVERY' | 'DELIVERY_ASSIGNED' | 'OUT_FOR_DELIVERY' | 'DELIVERED' | 'FULFILLED' | 'ORDER_CANCELLED' | 'ON_HOLD' | 'DELIVERY_FAILED'>, branch?: string, isVerified?: boolean, search?: string, sort?: 'ORDER_NUMBER' | 'STATUS' | 'DATE_OF_ORDER' | 'REMARKS' | 'BRANCH_CODE' | 'CLINIC_NAME' | 'DATE_OF_ESTIMATED_DELIVERY', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).ordersGET(page, perPage, startDate, endDate, includeStatuses, branch, isVerified, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To request for reset password link when the user forgets the account password
         * @param {Email} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordForgotPOST: async (email: Email, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('passwordForgotPOST', 'email', email)
            const localVarPath = `/password/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(email, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To reset the user account password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetPOST: async (resetPassword: ResetPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassword' is not null or undefined
            assertParamExists('passwordResetPOST', 'resetPassword', resetPassword)
            const localVarPath = `/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To request for reset password link when the user forgets the account password
         * @param {Email} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordForgotPOST(email: Email, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordForgotPOST(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To reset the user account password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordResetPOST(resetPassword: ResetPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordResetPOST(resetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordApiFp(configuration)
    return {
        /**
         * 
         * @summary To request for reset password link when the user forgets the account password
         * @param {Email} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordForgotPOST(email: Email, options?: any): AxiosPromise<void> {
            return localVarFp.passwordForgotPOST(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To reset the user account password
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordResetPOST(resetPassword: ResetPassword, options?: any): AxiosPromise<void> {
            return localVarFp.passwordResetPOST(resetPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * 
     * @summary To request for reset password link when the user forgets the account password
     * @param {Email} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public passwordForgotPOST(email: Email, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).passwordForgotPOST(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To reset the user account password
     * @param {ResetPassword} resetPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public passwordResetPOST(resetPassword: ResetPassword, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).passwordResetPOST(resetPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To get the profile of current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update the profile of current user
         * @param {UserProfileUpdate} userProfileUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUpdatePOST: async (userProfileUpdate: UserProfileUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userProfileUpdate' is not null or undefined
            assertParamExists('meUpdatePOST', 'userProfileUpdate', userProfileUpdate)
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To get the profile of current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update the profile of current user
         * @param {UserProfileUpdate} userProfileUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meUpdatePOST(userProfileUpdate: UserProfileUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meUpdatePOST(userProfileUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary To get the profile of current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meGET(options?: any): AxiosPromise<Profile> {
            return localVarFp.meGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update the profile of current user
         * @param {UserProfileUpdate} userProfileUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meUpdatePOST(userProfileUpdate: UserProfileUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.meUpdatePOST(userProfileUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary To get the profile of current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public meGET(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).meGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update the profile of current user
     * @param {UserProfileUpdate} userProfileUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public meUpdatePOST(userProfileUpdate: UserProfileUpdate, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).meUpdatePOST(userProfileUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary To create a user
         * @param {UserCreation} userCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreatePOST: async (userCreation: UserCreation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreation' is not null or undefined
            assertParamExists('userCreatePOST', 'userCreation', userCreation)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get the details of an user
         * @param {string} id The ID of an user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userGET', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To update details of an user
         * @param {string} id The ID of an user
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePOST: async (id: string, userUpdate: UserUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userUpdatePOST', 'id', id)
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('userUpdatePOST', 'userUpdate', userUpdate)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get all users
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ADMIN' | 'DISPENSER' | 'PHARMACIST'} [role] To filter by user role
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGET: async (page: number, perPage: number, role?: 'ADMIN' | 'DISPENSER' | 'PHARMACIST', search?: string, sort?: 'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('usersGET', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('usersGET', 'perPage', perPage)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary To create a user
         * @param {UserCreation} userCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCreatePOST(userCreation: UserCreation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCreatePOST(userCreation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get the details of an user
         * @param {string} id The ID of an user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To update details of an user
         * @param {string} id The ID of an user
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdatePOST(id: string, userUpdate: UserUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdatePOST(id, userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary To get all users
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ADMIN' | 'DISPENSER' | 'PHARMACIST'} [role] To filter by user role
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGET(page: number, perPage: number, role?: 'ADMIN' | 'DISPENSER' | 'PHARMACIST', search?: string, sort?: 'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersRetrieval>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGET(page, perPage, role, search, sort, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary To create a user
         * @param {UserCreation} userCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCreatePOST(userCreation: UserCreation, options?: any): AxiosPromise<void> {
            return localVarFp.userCreatePOST(userCreation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get the details of an user
         * @param {string} id The ID of an user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGET(id: string, options?: any): AxiosPromise<UserRetrieval> {
            return localVarFp.userGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To update details of an user
         * @param {string} id The ID of an user
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdatePOST(id: string, userUpdate: UserUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.userUpdatePOST(id, userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get all users
         * @param {number} page To indicates the current page
         * @param {number} perPage To indicates the numbers of items to be returned
         * @param {'ADMIN' | 'DISPENSER' | 'PHARMACIST'} [role] To filter by user role
         * @param {string} [search] To search by name
         * @param {'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
         * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGET(page: number, perPage: number, role?: 'ADMIN' | 'DISPENSER' | 'PHARMACIST', search?: string, sort?: 'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: any): AxiosPromise<UsersRetrieval> {
            return localVarFp.usersGET(page, perPage, role, search, sort, direction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary To create a user
     * @param {UserCreation} userCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCreatePOST(userCreation: UserCreation, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCreatePOST(userCreation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get the details of an user
     * @param {string} id The ID of an user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGET(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To update details of an user
     * @param {string} id The ID of an user
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdatePOST(id: string, userUpdate: UserUpdate, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdatePOST(id, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary To get all users
     * @param {number} page To indicates the current page
     * @param {number} perPage To indicates the numbers of items to be returned
     * @param {'ADMIN' | 'DISPENSER' | 'PHARMACIST'} [role] To filter by user role
     * @param {string} [search] To search by name
     * @param {'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS'} [sort] To indicates the field which the returning result to be sorted against
     * @param {'ASC' | 'DESC'} [direction] To indicates the direction of sorted items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public usersGET(page: number, perPage: number, role?: 'ADMIN' | 'DISPENSER' | 'PHARMACIST', search?: string, sort?: 'NAME' | 'MOBILE' | 'EMAIL' | 'ROLES' | 'DATE_CREATED' | 'STATUS', direction?: 'ASC' | 'DESC', options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).usersGET(page, perPage, role, search, sort, direction, options).then((request) => request(this.axios, this.basePath));
    }
}


