import React from 'react';
import styled from 'styled-components';
import { BsCheckCircleFill } from 'react-icons/bs'

const CardWrapper = styled.div`
  padding: 12px;
  background: #F0F0F0;
  border-radius: 4px;
`
const CriteriaText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4A4A4A;
  padding-left : 10px;
`

interface Props {
  charactersError?: boolean;
  numbersError?: boolean;
  style?: any;
}

export default function PasswordCriteria({ charactersError, numbersError, style }: Props) {
  return (
    <CardWrapper style={style}>
      <p className='p1'>
        Password must contain 8 or more characters with the following criterion:
      </p>
      <div style={{ paddingTop: '4px' }}>
        <BsCheckCircleFill color={charactersError ? "grey" : "orange"} />
        <CriteriaText>
          Characters A through Z
        </CriteriaText>
      </div>
      <div>
        <BsCheckCircleFill color={numbersError ? "grey" : "orange"} />
        <CriteriaText>
          Numbers 0 to 9
        </CriteriaText>
      </div>
    </CardWrapper>)
}