import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../components/card/card';
import ThumbnailModal from '../../components/modal/thumbnail-modal';
import { useUserStore } from '../../store/userStore';
import * as amberSdk from '../../open-api';
import BounceLoader from 'react-spinners/BounceLoader';
import mainAxios from '../../config/axios-config';

const PhotoWrapper = styled.div`
  img {
    width: 100%;
    cursor: pointer;
  }
  @media (max-width: 1300) {
    width: 100%
  }
`

interface Props {
  orderNumber: string;
}

function OrderPhoto({ orderNumber }: Props) {

  const userStore = useUserStore();
  const [loading, setLoading] = useState(true);
  const [imageBase64, setImageBase64] = useState('');
  const [thumbnail, setThumbnail] = useState({
    blob: "",
    id: ""
  });



  useEffect(() => {
    let isSubscribed = true;

    if (!userStore.accessToken) {
      return
    }
    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios)
    orderApi.orderImageGET(orderNumber,
      {
        headers: {
          Authorization: `Bearer ${userStore.accessToken}`,
        },
        responseType: 'blob'
      }
    ).then((e) => {
      if (isSubscribed)
        setImageBase64(URL.createObjectURL(e.data));
    }).finally(() => {
      if (isSubscribed)
        setLoading(false);
    });

    return () => {
      isSubscribed = false
    };

  }, [userStore.accessToken, orderNumber])

  if (loading) {
    return (
      <Card header="Order Photo">
        <BounceLoader
          color={"#66bcff"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </Card>
    )
  }
  return (
    <Card header="Order Photo">
      <PhotoWrapper onClick={() => setThumbnail({ blob: imageBase64, id: orderNumber })}>
        {imageBase64 && <img src={imageBase64} alt='order content' />}
      </PhotoWrapper>

      {
        thumbnail.id && <ThumbnailModal title={'Order Photo'} modalIsOpen={thumbnail.id !== ""} handleClose={() => setThumbnail({
          blob: "",
          id: ""
        })} data={thumbnail} />
      }
    </Card>
  )
};

export default OrderPhoto;