import React from 'react';
import styled from 'styled-components'

const StatusIcon = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
`

export const clinicStatusOption = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'SUSPENDED', label: 'Suspended' },
];

export const userStatusOption = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
];

export const AccountStatusIcon = ({ status }) => {
  switch (status) {
    case "ACTIVE":
      return (<><StatusIcon style={{ backgroundColor: '#FFA300' }} />Active </>)

    case "SUSPENDED":
      return (<><StatusIcon style={{ backgroundColor: '#C50000' }} />Suspended </>)

    case "INACTIVE":
      return (<><StatusIcon style={{ backgroundColor: '#F0F0F0' }} />Inactive </>)

    default:
      return (<><StatusIcon style={{ backgroundColor: '#FFFFFF' }} />{status} </>)
  }
}