export const columnsDefinition = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Mobile',
    accessor: 'mobile_contact',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'User Role',
    accessor: 'roles',
  },
  {
    Header: 'Date Created',
    accessor: 'date_created',
  },
  {
    Header: 'Account Status',
    accessor: 'status',
  },
];