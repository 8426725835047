import axios from "axios";
import { useUserStore } from "../store/userStore";
import * as amberSdk from '../open-api';
import { getRequestConfig } from "../common/utils";
import { useHistory } from "react-router";
import { useEffect } from "react";


const mainAxios = axios.create();

const AxiosInterceptor = ({ children }: any) => {

  const userStore = useUserStore();
  const history = useHistory();
  useEffect(() => {

    // Request interceptor for API calls
    const requestInterceptor = mainAxios.interceptors.request.use(
      async config => {
        return config;
      },
      error => {
        Promise.reject(error)
      });

    // Response interceptor for API calls 
    const responseInterceptor = mainAxios.interceptors.response.use((response) => {
      return response
    }, async function (error) {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        let refreshToken = localStorage.getItem('amber_refresh_token') || '';

        if (!refreshToken) {
          return;
        }

        let authApi: amberSdk.AuthApi = new amberSdk.AuthApi();

        authApi.loginRefreshPOST({
          refresh_token: refreshToken
        }).then((e) => {
          userStore.setAccessToken(e.data.access_token);
          localStorage.setItem('amber_refresh_token', e.data.refresh_token);

          // pull redirect to login if not authenticated
          let profileApi: amberSdk.ProfileApi = new amberSdk.ProfileApi()
          profileApi.meGET(
            getRequestConfig(e.data.access_token)
          ).then((e) => {
            userStore.setUser(e.data);
          }).catch((error) => {
            if (error.response)
              console.log(error.response);
            userStore.unsetUser();
            history.push('/login');
          });

        }).catch((error) => {
          if (error.response)
            console.log(error);
          userStore.unsetUser();
          history.push('/login');
        })
      } else {
        return Promise.reject(error)
      }

      return () => {
        mainAxios.interceptors.response.eject(requestInterceptor);
        mainAxios.interceptors.response.eject(responseInterceptor);
      }
    })
  });

  return children;
}


export default mainAxios;
export { AxiosInterceptor };