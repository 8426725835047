import update from 'immutability-helper';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { adminRoleExists, countryCodeOptionList, dispenserRoleExists, getRequestConfig, IParamTypes, ISelectOptionItem, IsValidEmail } from '../../common/utils';
import { userStatusOption } from '../../components/account-status/account-status';
import CustomSelect from '../../components/custom-select/custom-select';
import mainAxios from '../../config/axios-config';
import * as amberSdk from '../../open-api';
import { CompoundingTechCreation } from '../../open-api';
import { useUserStore } from '../../store/userStore';
import OrderList from '../order-list/order-list';
import { emptyCreateObject } from './create-compounding-tech';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  width: 100%;
  height: 32px;
`
const UpdateButton = styled.button`
  padding: 8px 0;
  width: 100%;
  background: #FFA300;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

function UpdateCompoundingTech() {
  const history = useHistory();
  const { id } = useParams<IParamTypes>();

  const [newCreateObject, setNewCreateObject] = useState<CompoundingTechCreation>(emptyCreateObject);
  const [loading, setLoading] = useState(true);
  const [branchOptionList, setBranchOptionList] = useState<Array<ISelectOptionItem>>([]);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [branchError, setBranchError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const userStore = useUserStore();

  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }

    if (userStore.user.id) {
      if (!(adminRoleExists(userStore.user.roles) || dispenserRoleExists(userStore.user.roles))) {
        history.push("/not-found");
        return;
      }
    }

  }, [userStore.accessToken, userStore.user, history])

  /**
   * load all page data
   */
  useEffect(() => {

    if (!userStore.accessToken) {
      return
    }

    let branchApi: amberSdk.BranchApi = new amberSdk.BranchApi(undefined, undefined, mainAxios);
    branchApi.branchesGET(1, 3000, undefined, undefined, undefined, undefined, getRequestConfig(userStore.accessToken)).then((e) => {
      setBranchOptionList(
        e.data.branches.map((e) => {
          return {
            value: e.id,
            label: e.code,
          }
        })
      )
    }).catch((e) => {
    }).finally(() => {
      setLoading(false);
    })

    let compoundingTechApi: amberSdk.CompoundingTechApi = new amberSdk.CompoundingTechApi(undefined, undefined, mainAxios)
    compoundingTechApi.compoundingTechGET(id, getRequestConfig(userStore.accessToken)).then((e) => {
      setNewCreateObject({
        name: e.data.name,
        mobile_contact: e.data.mobile_contact,
        status: e.data.status,
        branch_id: e.data.branch.id,
        email: e.data.email,
      });
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).finally(() => {
      setLoading(false);
    });

  }, [id, userStore.accessToken, reloadData])

  function handleInputChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      [field]: { $set: value }
    })
    setNewCreateObject(tempOrder);

    if (field === 'name') {
      if (nameError) {
        setNameError(false);
      }
    } else if (field === 'branch_id') {
      if (branchError) {
        setBranchError(false);
      }
    } else if (field === 'email') {
      if (emailError) {
        setEmailError(false)
      }
    }
  }

  function handleMobileChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      mobile_contact: {
        [field]: { $set: value }
      }
    })
    setNewCreateObject(tempOrder);
    if (mobileError) {
      setMobileError(false)
    }
  }

  function validationError(): boolean {
    let haveError = false;
    if (!newCreateObject.name) {
      if (!nameError) {
        setNameError(true)
      }
      haveError = true;
    } else {
      if (nameError) {
        setNameError(false)
      }
    }

    if (!newCreateObject.branch_id) {
      if (!branchError) {
        setBranchError(true)
      }
      haveError = true;
    } else {
      if (branchError) {
        setBranchError(false)
      }
    }

    if (newCreateObject.email) {
      if (!IsValidEmail(newCreateObject.email)) {
        if (!emailError) {
          setEmailError(true)
        }
        haveError = true;
      } else {
        if (emailError) {
          setEmailError(false)
        }
      }
    } else {
      if (emailError) {
        setEmailError(false)
      }
    }

    return haveError;
  }

  function getMobile() {
    if (newCreateObject.mobile_contact) {
      if (newCreateObject.mobile_contact.phone_number) {
        return {
          country_calling_code: newCreateObject.mobile_contact.country_calling_code,
          phone_number: newCreateObject.mobile_contact.phone_number,
        }
      }
    }
    return undefined
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);

    if (validationError()) {
      setLoading(false);
      return;
    }

    let compoundingTechApi: amberSdk.CompoundingTechApi = new amberSdk.CompoundingTechApi(undefined, undefined, mainAxios)

    compoundingTechApi.compoundingTechUpdatePOST(id, {
      name: newCreateObject.name,
      mobile_contact: getMobile(),
      branch_id: newCreateObject.branch_id,
      status: newCreateObject.status,
      email: newCreateObject.email ? newCreateObject.email : undefined,
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setReloadData(!reloadData);
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_mobile_contact') {
          setMobileError(true);
        }
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  function getMobileDetails(detail: string) {
    if (newCreateObject.mobile_contact && detail === 'country') {
      return newCreateObject.mobile_contact.country_calling_code
    } else if (newCreateObject.mobile_contact && detail === 'phone') {
      return newCreateObject.mobile_contact.phone_number
    }
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <h1 className="page-header">Manage Compounding Tech</h1>
      </HeaderWrapper>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "32px" }}>
          <Col md={12}>
            <span className='p1'>
              Update Compounding Technician
            </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Name*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${nameError ? 'is-invalid' : ''}`}
              placeholder="Name"
              id="name"
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={newCreateObject.name}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Phone Number
          </Col>
          <Col md={4} style={{display: 'flex'}}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleMobileChange('country_calling_code', e.value)}
              options={countryCodeOptionList}
              value={countryCodeOptionList.find(e => e.value === getMobileDetails('country'))}
            />
            <input
              type="text"
              className={`form-control fs-13px ${mobileError ? 'is-invalid' : ''}`}
              placeholder="Phone Number"
              id="mobileNumber"
              onChange={(e) => handleMobileChange('phone_number', e.target.value)}
              value={getMobileDetails('phone')}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Email
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${emailError ? 'is-invalid' : ''}`}
              placeholder="Email"
              id="clinicName"
              onChange={(e) => handleInputChange('email', e.target.value)}
              value={newCreateObject.email}
            />
            <div className="invalid-feedback">Invalid email</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Amber Branch*
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('branch_id', e.value)}
              options={branchOptionList}
              placeholder='Branch Code'
              value={branchOptionList.find(e => e.value === newCreateObject.branch_id)}
              isError={branchError}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Status*
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('status', e.value)}
              options={userStatusOption}
              placeholder='Status'
              value={userStatusOption.find(e => e.value === newCreateObject.status)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={9}>
          </Col>
          <Col md={1}>
            <CancelButton type='button' onClick={history.goBack}>
              Cancel
            </CancelButton>
          </Col>
          <Col md={2}>
            <UpdateButton type='submit' onClick={handleSubmit}>
              Update Compounding Tech
            </UpdateButton>
          </Col>
        </Row>
      </Container>
      <hr />
      <OrderList compoundingTech_id={id} />
    </div>
  )
};

export default UpdateCompoundingTech;