import { OrderRetrieval, OrderRetrievalCompoundingTech, OrderStatus } from '../../open-api';
import { Moment } from 'moment';
import { ChangeEvent } from 'react';
import DateTime from 'react-datetime'
import styled from 'styled-components';
import { FormatNameIdToSelectMultiOption, FormatNameIdToSelectOption, ISelectOptionItem } from '../../common/utils';
import Card from '../../components/card/card';
import CustomDatePicker from '../../components/custom-datepicker/custom-datepicker';
import CustomSelect from '../../components/custom-select/custom-select';
import { GetSelectedStatusOption, orderStatusEnum, OrderStatusIcon, orderStatusOption } from '../../components/order-status/order-status';
import { useUserStore } from '../../store/userStore';
import moment from 'moment';
import CustomSelectCT from '../../components/custom-select/custom-select-ct';

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const StatusWrapper = styled.div`
  width: 136px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h5 {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`
const VerifyOrderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #4A4A4A;
  label {
    padding-left: 8px;
  }
`
const CancelHoldReasonText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: #4A4A4A;
`
const CancelHoldReasonTextArea = styled.textarea`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  font-style: normal;
  border: 1.5px solid #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  ::placeholder {
    color: #D1D1D1;
  }
`
const TextLabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 5px;
`
const TextLabel = styled.span`
  background: #E9E9E9;
  border-radius: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #4A4A4A;
  padding: 3px 8px;
`

interface OrderUpdateProps {
  currentOrder: OrderRetrieval
  newOrder: OrderRetrieval
  setEstimatedDate: (e: string | Moment) => void;
  setAmberBranch: (e: string) => void;
  setCompoundingTechs: (e: Array<string>) => void;
  setReason: (e: string) => void;
  setDeliveryPersonnel: (e: string) => void;
  setStatus: (e: string) => void;
  setVerifyOrder: (e: boolean) => void;

  branchOptionList: Array<ISelectOptionItem>;
  CTOptionList: Array<ISelectOptionItem>;
  DPOptionList: Array<ISelectOptionItem>;
}

interface OrderUpdateIndividualProps {
  currentStatus?: OrderStatus;
  value?: any;
  disabled?: boolean;
  optionList?: Array<ISelectOptionItem>;
  setValue: (e: any) => void;
}

function EstimatedDelivery({ value, setValue, disabled }: OrderUpdateIndividualProps) {
  return <>
    <h5>Estimated Delivery Date</h5>
    <DateTime
      value={value ? moment(value).format('DD MMM YYYY') : ''}
      onChange={setValue}
      inputProps={{ placeholder: 'Estimated Date', disabled: disabled }}
      closeOnSelect={true}
      timeFormat={false}
      dateFormat={"DD MMM YYYY"}
      renderInput={CustomDatePicker} />
  </>
}

function AmberBranch({ value, setValue, disabled, optionList }: OrderUpdateIndividualProps) {
  return <>
    <h5>Amber Branch</h5>
    <CustomSelect
      onChange={(e: ISelectOptionItem) => setValue({ id: e.value, name: e.label })}
      options={optionList}
      value={{ value: value.id, label: value.name }}
      isDisabled={disabled} />
  </>
}

function CompundingTechAssignment({ value, setValue, disabled, optionList }: OrderUpdateIndividualProps) {
  if (disabled) {
    return <>
      <h5>Assigned Compounding Tech</h5>
      <TextLabelWrapper>
        {
          value ? value.map(
            (e: OrderRetrievalCompoundingTech) =>
            (<TextLabel key={e.id}>
              {e.name}
            </TextLabel>)
          ) : '-'
        }
      </TextLabelWrapper>
    </>
  }
  let handleChange = (elementArray: { value: string, label: string }[]) => {
    const tempValue = elementArray.map(e => {
      return { id: e.value, name: e.label }
    })
    setValue(tempValue)
  }
  return <>
    <h5>Assigned Compounding Tech</h5>
    <CustomSelectCT
      closeMenuOnSelect={false}
      isMulti
      onChange={handleChange}
      options={optionList}
      defaultValue={FormatNameIdToSelectMultiOption(value)}
      isDisabled={disabled} />
  </>
}

function VerifiedText({value}: {value: boolean}) {
  if(value) {
    return <>The order has been checked and approved</>;
  } 
  return <>The order has not been checked and approved</>;
}

function VerifyOrder({ currentStatus, value, setValue, disabled }: OrderUpdateIndividualProps) {
  if (currentStatus === orderStatusEnum.ORDER_SUBMITTED ||
    currentStatus === orderStatusEnum.ACKNOWLEDGED ||
    currentStatus === orderStatusEnum.ON_HOLD ||
    currentStatus === orderStatusEnum.ORDER_CANCELLED ||
    currentStatus === orderStatusEnum.FULFILLED
  ) {
    return <></>
  }

  return (<VerifyOrderWrapper>
    {disabled ?
      <VerifiedText value={value} /> :
      (<>
        <input
          type="checkbox"
          id="verifyOrderCheckbox"
          name="verifyOrderCheckbox"
          onChange={setValue}
          checked={value} />
        <label htmlFor="verifyOrderCheckbox">The order has been checked and approved</label>
      </>
      )}
  </VerifyOrderWrapper>)
}

function CancelHoldReason({ value, setValue, disabled }: OrderUpdateIndividualProps) {
  if (disabled) {
    return <>
      <h5>Reasons for Cancellation &#47; On Hold</h5>
      <CancelHoldReasonText>
        {value}
      </CancelHoldReasonText>
    </>
  }

  return <>
    <h5>Reasons for Cancellation &#47; On Hold</h5>
    <CancelHoldReasonTextArea
      rows={4}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}
      placeholder="i.e. Ran out of ingredients"
      value={value ? value : ''} />
  </>
}

function AssignDelivery({ value, setValue, disabled, optionList }: OrderUpdateIndividualProps) {
  if (disabled) {
    return <>
      <h5>Assigned Delivery Personnel</h5>
      {value ?
        <TextLabelWrapper>
          <TextLabel>{value.name}</TextLabel>
        </TextLabelWrapper>
        : '-'
      }
    </>
  }
  return <>
    <h5>Assign a Delivery Personnel</h5>
    <CustomSelect
      onChange={(e: { value: string, label: string }) => setValue({ id: e.value, name: e.label })}
      options={optionList}
      defaultValue={FormatNameIdToSelectOption(value)} />
  </>
}

function OrderUpdateContent({
  newOrder,
  currentOrder,
  setEstimatedDate,
  setAmberBranch,
  setCompoundingTechs,
  setReason,
  setDeliveryPersonnel,
  setVerifyOrder,
  branchOptionList,
  CTOptionList,
  DPOptionList,
}: OrderUpdateProps) {
  const userStore = useUserStore();
  // get admin from state
  const isAdmin = userStore.user && userStore.user.roles.find(e => e === 'ADMIN');
  const isDispenser = userStore.user && userStore.user.roles.find(e => e === 'DISPENSER');
  const isAdminOrDispenser = isAdmin || isDispenser;

  switch (newOrder.order_status) {
    case orderStatusEnum.ORDER_SUBMITTED:
      return <></>
    case orderStatusEnum.ACKNOWLEDGED:
      return <>
        <EstimatedDelivery value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} />
      </>
    case orderStatusEnum.IN_PROCESS:
      // for admin
      if (isAdminOrDispenser) {
        return <>
          <EstimatedDelivery value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} />
          <AmberBranch optionList={branchOptionList} value={newOrder.branch} setValue={setAmberBranch} />
          <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} />
          <VerifyOrder currentStatus={currentOrder.order_status} value={newOrder.is_verified} setValue={setVerifyOrder} />
        </>
      }
      // for pharmacist
      return <>
        <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} disabled />
        <EstimatedDelivery value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} disabled />
        <VerifyOrder value={newOrder.is_verified} setValue={setVerifyOrder} />
      </>
    case orderStatusEnum.ORDER_CANCELLED:
      return <>
        <CancelHoldReason value={newOrder.reason} setValue={setReason} disabled={orderStatusEnum.ORDER_CANCELLED === currentOrder.order_status} />
      </>
    case orderStatusEnum.ON_HOLD:
      return <>
        <CancelHoldReason value={newOrder.reason} setValue={setReason} disabled={orderStatusEnum.ORDER_CANCELLED === currentOrder.order_status} />
      </>
    case orderStatusEnum.READY_FOR_DELIVERY:
      // admin or dispenser
      if (isAdminOrDispenser) {
        return <>
          <EstimatedDelivery value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} />
          <AmberBranch optionList={branchOptionList} value={newOrder.branch} setValue={setAmberBranch} disabled />
          <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} disabled />
          <VerifyOrder currentStatus={currentOrder.order_status} value={newOrder.is_verified} setValue={setVerifyOrder} />
        </>
      }
      // non-admin pharmacist
      return <>
        <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} disabled />
        <EstimatedDelivery value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} disabled />
        <VerifyOrder currentStatus={currentOrder.order_status} value={newOrder.is_verified} setValue={setVerifyOrder} disabled />
      </>
    case orderStatusEnum.DELIVERY_ASSIGNED:
      return <>
        <AssignDelivery disabled={!isAdminOrDispenser} optionList={DPOptionList} value={newOrder.delivery_personnel} setValue={setDeliveryPersonnel} />
        <EstimatedDelivery disabled={!isAdminOrDispenser} value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} />
        <AmberBranch optionList={branchOptionList} value={newOrder.branch} setValue={setAmberBranch} disabled />
        <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} disabled />
        <VerifyOrder currentStatus={currentOrder.order_status} value={newOrder.is_verified} setValue={setVerifyOrder} disabled={!isAdminOrDispenser} />
      </>
    case orderStatusEnum.OUT_FOR_DELIVERY:
      return <>
        <AssignDelivery optionList={DPOptionList} value={newOrder.delivery_personnel} setValue={setDeliveryPersonnel} disabled />
        <EstimatedDelivery value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} />
        <AmberBranch optionList={branchOptionList} value={newOrder.branch} setValue={setAmberBranch} disabled />
        <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} disabled />
        <VerifyOrder currentStatus={currentOrder.order_status} value={newOrder.is_verified} setValue={setVerifyOrder} disabled={!isAdminOrDispenser} />
      </>
    case orderStatusEnum.DELIVERED:
    case orderStatusEnum.DELIVERY_FAILED:
      return <>
        <AssignDelivery optionList={DPOptionList} value={newOrder.delivery_personnel} setValue={setDeliveryPersonnel} disabled />
        <EstimatedDelivery value={newOrder.date_of_estimated_delivery} setValue={setEstimatedDate} />
        <AmberBranch optionList={branchOptionList} value={newOrder.branch} setValue={setAmberBranch} disabled />
        <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} disabled />
        <VerifyOrder currentStatus={currentOrder.order_status} value={newOrder.is_verified} setValue={setVerifyOrder} disabled={!isAdminOrDispenser} />
      </>
    case orderStatusEnum.FULFILLED:
      return <>
        <AssignDelivery optionList={DPOptionList} value={newOrder.delivery_personnel} setValue={setDeliveryPersonnel} disabled />
        <CompundingTechAssignment optionList={CTOptionList} value={newOrder.compounding_techs} setValue={setCompoundingTechs} disabled />
      </>
    default:
      return <></>
  }
}

function OrderUpdate({ currentOrder, newOrder, ...props }: OrderUpdateProps) {
  const userStore = useUserStore();
  // get admin from state
  const isAdmin = userStore.user && userStore.user.roles.find(e => e === 'ADMIN');
  const isDispenser = userStore.user && userStore.user.roles.find(e => e === 'DISPENSER');
  const isAdminOrDispenser = isAdmin || isDispenser;
  return (
    <Card header={
      <HeaderContent>
        <span>Order Update</span>
        <StatusWrapper>
          <OrderStatusIcon status={currentOrder.order_status} />
        </StatusWrapper>
      </HeaderContent>
    } style={{ marginBottom: '10px' }}>
      <BodyWrapper>
        <h5 style={{ marginTop: "0px" }}>Order Status</h5>
        <CustomSelect
          onChange={(e: { value: any; }) => props.setStatus(e.value)}
          value={GetSelectedStatusOption(newOrder.order_status)}
          options={orderStatusOption}
          isDisabled={
            currentOrder.order_status === orderStatusEnum.ORDER_CANCELLED ||
            currentOrder.order_status === orderStatusEnum.FULFILLED ||
            !isAdminOrDispenser
          } />
        <OrderUpdateContent currentOrder={currentOrder} newOrder={newOrder} {...props} />
      </BodyWrapper>
    </Card>
  )
};

export default OrderUpdate;