import { Col, Container, Row } from 'reactstrap';
import Card from '../../components/card/card';
import { OrderRetrievalClinic, OrderRetrievalClinicPurchaser } from '../../open-api';


interface Props {
  clinic: OrderRetrievalClinic;
  purchaser: OrderRetrievalClinicPurchaser;
}

function ClinicDetails({
  clinic,
  purchaser,
}: Props) {
  return (
    <Card header="Clinic Purchaser &#38; Clinic Details">
      <Container style={{ padding: '0px' }}>
        <Row>
          <Col xs={12} md={7} className="flex-column">
            <h5>Contact Person</h5>
            <p className="p4">{purchaser.name}</p>
          </Col>
          <Col xs={12} md={5} className="flex-column">
            <h5>Mobile Number</h5>
            {purchaser.mobile_contact &&
              <p className="p4">{`${purchaser.mobile_contact.country_calling_code} ${purchaser.mobile_contact.phone_number}`}</p>
            }
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="flex-column">
            <h5>Clinic Name</h5>
            <p className="p4">{clinic.name}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} md={7} className="flex-column">
            <h5>Email</h5>
            <p className="p4">{clinic.email}</p>
          </Col>
          <Col xs={12} md={5} className="flex-column">
            <h5>Phone</h5>
            {purchaser.mobile_contact &&
              <p className="p4">{`${clinic.mobile_contact.country_calling_code} ${clinic.mobile_contact.phone_number}`}</p>
            }
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} md={7} className="flex-column">
            <h5>Address</h5>
            <p className="p4">{clinic.address}</p>
          </Col>
          <Col xs={12} md={5} className="flex-column">
            <h5>Unit</h5>
            <p className="p4">{clinic.unit}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} md={7} className="flex-column">
            <h5>Postal Code</h5>
            <p className="p4">{clinic.postal}</p>
          </Col>
          <Col xs={12} md={5} className="flex-column">
            <h5>Country</h5>
            <p className="p4">{clinic.country}</p>
          </Col>
        </Row>
      </Container>
    </Card>
  )
};

export default ClinicDetails;