import React from 'react';

interface ISortDirectionIcon {
  sortOptions: {
    sortDirection: string;
    sortColumn: string;
  };
  column: string;
}

export const SortDirectionIcon = ({ sortOptions, column }: ISortDirectionIcon) => {
  // console.log(column);
  if (sortOptions === null ||
    sortOptions === undefined) {
    return <></>
  } else if (sortOptions.sortColumn === column) {
    if (sortOptions.sortDirection === 'asc') {
      return <img src="/assets/img/svg/ic-sort-arrow-up.svg" alt="" />
    } else {
      return <img src="/assets/img/svg/ic-sort-arrow-down.svg" alt="" />
    }
  }
  return <></>
}