export const properties = {
  // localhost 
  localServerUrl: "http://192.168.18.5:3200/web/api",
  // localServerUrl: "http://192.168.1.164:3200/web/api",

  // aws dev env 
  devServerUrl: "http://amberbackenddev-env.eba-zwyhnwji.ap-southeast-1.elasticbeanstalk.com/web/api",

  // aws staging/SIT env 
  stagingServerUrl: "http://amberbackendsitenv-env.eba-ie4rmh5p.ap-southeast-1.elasticbeanstalk.com/web/api",

  // aws production env 
  prodServerUrl: "https://backend.amberpharmacyonline.com/web/api"
};