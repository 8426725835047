import React from 'react';
import { AppSettings } from '../config/app-settings';
import styled from 'styled-components';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 240px;
    width: 240px;
    margin-bottom: 64px;
  }
`

const PageDescription = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #3A3A3A;
  margin-top: 24px;
`

class NotFoundPage extends React.Component {
  static contextType = AppSettings;


  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
  }

  render() {
    return <NotFoundWrapper>

      <img src="/assets/img/svg/403-404.svg" alt="" />

      <h1>
        Opps..medication not found.
      </h1>
      <PageDescription>
        The page you are trying to access is either missing or has restricted access.
      </PageDescription>

    </NotFoundWrapper>
  }

}

export default NotFoundPage;