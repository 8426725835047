import styled from "styled-components"

export const StyledTable = styled.table`
  border-spacing: 0;
  border: 1px solid #E0E0E0;
  border-radius: 40px;
  width: 100%;

  th {
    background-color: #F0F0F0;
    padding: 10px 0px 10px 16px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #3A3A3A;
  }

  td {
    margin: 0;
    padding: 10px 0px 10px 16px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #4A4A4A;
  }
`
export const ViewButton = styled.button`
  padding: 4px 8px;
  background-color: #7DC6FF;
  border: none;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  color: #FFFFFF;
`
export const SearchDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0px 16px 16px;
`
export const ListWrapper = styled.div`
	border: 1px solid #E0E0E0;
	border-radius: 4px;
`
export const ListHeaderWrapper = styled.div`
  background: #FFECCA;
  border-radius: 4px 4px 0px 0px;
  padding: 13px 0 13px 16px;
`
export const ListHeader = styled.h2`
  margin-bottom: 0;
`
export const ListContentWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`
export const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const FilterColumn = styled.div`
  width: 216px;
`
export const SearchOptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const EntriesDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0px 16px;
`
export const SearchInput = styled.input`
  width: 318px;
  height: 30px;
  border: solid 1px #E0E0E0;
  border-radius: 4px;
  padding-left: 16px;
`
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CreateButton = styled.button`
  padding: 8px 59px;
  background: #005FAA;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  float: right;
`
export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`
export const PageFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`