import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import styled from 'styled-components'
import BounceLoader from 'react-spinners/BounceLoader';
import DefaultButton from '../../components/buttons/default-button.jsx';
import { useUserStore } from '../../store/userStore';
import * as amberSdk from '../../open-api';
import { IsValidEmail } from '../../common/utils';
import { getRequestConfig } from '../../common/utils'
import { toast } from 'react-toastify';

const FooterDiv = styled.div`
	margin-bottom: 3em;
  position: fixed;
  bottom: 0%;
`
const BackButtonDiv = styled.div`
  margin-top: 2em;
  position: fixed;
  top: 0%;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  a {
    color: #4A4A4A;
    text-decoration: none;
  }
`
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ForgetPasswordContent = (props) => {
  if (props.isLoading) {
    return (
      <div className="login-container">
        <LoaderWrapper>
          <BounceLoader
            color={"#FFA300"}
            loading={props.isLoading}
            size={48}
            speedMultiplier={1}
          />
        </LoaderWrapper>
      </div>
    );
  }
  return (
    <div className="login-container">
      <BackButtonDiv onClick={props.handleBackButtonClick}>
        <Link to='/login'>
          <img src='/assets/img/svg/ic-back-button.svg' alt='' /> Back
        </Link>
      </BackButtonDiv>
      <div className="login-header mb-30px">
        <div className="brand">
          <div className="d-flex align-items-center">
            <span className="logo"></span>
            Forget Password
          </div>
          <span className='p1'>Enter your email to get a password reset link.</span>
        </div>
      </div>
      <div className="login-content">
        <form onSubmit={(e) => props.handleSubmit(e)} className="fs-13px">
          <div className="form mb-15px">
            <input
              type="text"
              className={`form-control fs-13px ${props.emailError ? 'is-invalid' : ''}`}
              placeholder="Email Address"
              id="emailAddress"
              onChange={(e) => props.handleUpdateStateValue('loginEmail', e.target.value)}
              value={props.loginEmail}
            />
            <div className="invalid-feedback">Invalid email</div>
          </div>
          <div className="mb-15px" style={{
            marginTop: '48px'
          }}>
            <DefaultButton type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px" onClick={(e) => props.handleSubmit(e)}>Get Password Reset Link</DefaultButton>
          </div>
          <FooterDiv>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              Copyright &copy; 2022 Amber Compounding Pharmacy Pte Ltd
            </div>
          </FooterDiv>
        </form>
      </div>
    </div>
  )
}

const PasswordResetSuccessContent = (props) => {
  if (props.isLoading) {
    return (
      <div className="login-container">
        <LoaderWrapper>
          <BounceLoader
            color={"#FFA300"}
            loading={props.isLoading}
            size={48}
            speedMultiplier={1}
          />
        </LoaderWrapper>
      </div>
    );
  }
  return (
    <div className="login-container">
      <BackButtonDiv onClick={props.handleBackButtonClick}>
        <Link to='/login'>
          <img src='/assets/img/svg/ic-back-button.svg' alt='' /> Back
        </Link>
      </BackButtonDiv>
        <div className="login-header">
          <div className="brand">
            <div className="d-flex align-items-center">
              <span className="logo"></span>
              Forget Password
            </div>
            <p className='p1'>A password reset link has been sent to your email address.</p>
          </div>
        </div>
        <div className="login-content" style={{
          marginTop: '48px'
        }}>
          <Link to='/login' className="mb-15px">
            <DefaultButton type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">Go to Login</DefaultButton>
          </Link>
          <FooterDiv>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              Copyright &copy; 2022 Amber Compounding Pharmacy Pte Ltd
            </div>
          </FooterDiv>
        </div>
    </div>
  )
}

class ForgetPassword extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      loginEmail: '',
      loginSuccess: false,
      loginLoading: false,
      emailError: false,
    }

    this.handleSubmitForgetPassword = this.handleSubmitForgetPassword.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
    if (this.props.userStore.accessToken) {
      let profileApi = new amberSdk.ProfileApi();
      profileApi.meGET(getRequestConfig(this.props.userStore.accessToken)
      ).then((e) => {
        this.props.userStore.setUser(e.data)
        this.props.history.push('/')
      })
    }
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  handleSubmitForgetPassword(event) {
    event.stopPropagation()
    event.preventDefault();

    this.setState({
      loginLoading: true,
    })

    let passwordApi = new amberSdk.PasswordApi();
    // login post api
    passwordApi.passwordForgotPOST({
      email: this.state.loginEmail,
    }).then((e) => {
      this.setState({
        loginLoading: false,
        loginSuccess: true,
      })
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.setState({
        emailError: true,
      });
    }).finally((e) => {
      this.setState({
        loginLoading: false,
      });
    })
  }

  updateStateValue(id, value) {
    this.setState({
      [id]: value
    })

    if (id === 'loginEmail') {
      if (IsValidEmail(value)) {
        this.setState({
          emailError: false
        })
      } else {
        this.setState({
          emailError: true
        })
      }
    }
  }

  render() {
    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/img-admin-bacgkground.jpg)' }}></div>
        </div>
        {this.state.loginSuccess ?
          <PasswordResetSuccessContent />
          :
          <ForgetPasswordContent
            handleSubmit={this.handleSubmitForgetPassword}
            handleUpdateStateValue={this.updateStateValue}
            loginEmail={this.state.loginEmail}
            isLoading={this.state.loginLoading}
            emailError={this.state.emailError}
          />
        }
      </div>
    )
  }
}

const withStore = BaseComponent => props => {
  const userStore = useUserStore();
  return <BaseComponent {...props} userStore={userStore} />;
};

export default withRouter(withStore(ForgetPassword));