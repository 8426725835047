import React from 'react';
import { Link } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import styled from 'styled-components'
import { LoaderWrapper } from '../../common/styles';


const HeaderCardWrapper = styled.div`
    background: rgba(102, 188, 255, 0.15);
    font-size: 14px; !important
    line-height: 16px;
    border: none;
`

const HeaderCard = (props) => {
    return (
        <HeaderCardWrapper className="widget widget-stats">
            <div className="stats-icon"></div>
            <div className="stats-info">
                <h2>{props.title}</h2>
                {props.dateRange ? <h3>{props.dateRange}</h3> : <h3>&nbsp;</h3>}

                {props.loading ?
                    (<LoaderWrapper>
                        <BounceLoader
                            color={"#66bcff"}
                            loading={props.isLoading}
                            size={48}
                            speedMultiplier={1}
                        />
                    </LoaderWrapper>)
                    :
                    (
                        <h4>{props.value}</h4>
                    )}
            </div>
            <div className="stats-link">
                <Link to={props.viewLink}>View All</Link>
            </div>
        </HeaderCardWrapper>
    );
}

export default HeaderCard;