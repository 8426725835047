import React from 'react';
import styled from 'styled-components'

const DefaultStatusStyle = styled.div`
  letter-spacing: -0.02em;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  border-radius: 10px;
  padding: 2px 6px;
  text-align: center;
`

export const orderStatusOption = [
  { value: 'ORDER_SUBMITTED', label: 'Order Submitted' },
  { value: 'ACKNOWLEDGED', label: 'Acknowledged' },
  { value: 'IN_PROCESS', label: 'In-Process' },
  { value: 'ORDER_CANCELLED', label: 'Cancelled' },
  { value: 'ON_HOLD', label: 'On Hold' },
  { value: 'READY_FOR_DELIVERY', label: 'Ready for Delivery' },
  { value: 'DELIVERY_ASSIGNED', label: 'Delivery Assigned' },
  { value: 'OUT_FOR_DELIVERY', label: 'Out for Delivery' },
  { value: 'DELIVERED', label: 'Pending Completion' },
  { value: 'DELIVERY_FAILED', label: 'Delivery Failed' },
  { value: 'FULFILLED', label: 'Fulfilled' },
];

export function GetSelectedStatusOption(value) {
  return orderStatusOption.find(e => e.value === value)
}

export const orderStatusEnum = {
  ORDER_SUBMITTED: 'ORDER_SUBMITTED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  IN_PROCESS: 'IN_PROCESS',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  ON_HOLD: 'ON_HOLD',
  READY_FOR_DELIVERY: 'READY_FOR_DELIVERY',
  DELIVERY_ASSIGNED: 'DELIVERY_ASSIGNED',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  DELIVERED: 'DELIVERED',
  DELIVERY_FAILED: 'DELIVERY_FAILED',
  FULFILLED: 'FULFILLED',
}

export const OrderStatusIcon = ({status}) => {
  switch (status) {
    case "ORDER_SUBMITTED":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#209CFF'
      }}>ORDER SUBMITTED</DefaultStatusStyle>)

    case "ACKNOWLEDGED":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#AAB440'
      }}>ACKNOWLEDGED</DefaultStatusStyle>)

    case "IN_PROCESS":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#057171'
      }}>IN-PROCESS</DefaultStatusStyle>)

    case "ORDER_CANCELLED":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#898989'
      }}>ORDER CANCELLED</DefaultStatusStyle>)

    case "ON_HOLD":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#DA68E3'
      }}>ON HOLD</DefaultStatusStyle>)

    case "READY_FOR_DELIVERY":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#6C7FE7'
      }}>READY FOR DELIVERY</DefaultStatusStyle>)

    case "DELIVERY_ASSIGNED":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#F18181'
      }}>DELIVERY ASSIGNED</DefaultStatusStyle>)

    case "OUT_FOR_DELIVERY":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#5FAE67'
      }}>OUT FOR DELIVERY</DefaultStatusStyle>)

    case "DELIVERED":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#FFB634'
      }}>PENDING COMPLETION</DefaultStatusStyle>)

    case "DELIVERY_FAILED":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#C50000'
      }}>DELIVERY FAILED</DefaultStatusStyle>)

    case "FULFILLED":
      return (<DefaultStatusStyle style={{
        backgroundColor: '#80A3BF'
      }}>FULFILLED</DefaultStatusStyle>)

    default:
      return (<DefaultStatusStyle style={{
        backgroundColor: '#000000'
      }}>{status}</DefaultStatusStyle>)

  }
}