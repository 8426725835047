import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import {
  useParams
} from "react-router-dom";
import BounceLoader from 'react-spinners/BounceLoader';
import { Col, Container, Row } from 'reactstrap';
import { ActivityLog, DeliveryLog, OrderRetrieval } from '../../open-api';
import OrderPhoto from './order-photo';
import OrderUpdate from './order-update';
import OrderDetails from './order-details';
import ClinicDetails from './clinic-details';
import ActivityLogView from './activity-log';
import NotesView from './notes';
import DeliveryLogView from './delivery-log';
import * as amberSdk from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import { getRequestConfig, IParamTypes, ISelectOptionItem } from '../../common/utils';
import { orderStatusEnum } from '../../components/order-status/order-status';
import mainAxios from '../../config/axios-config';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between
`
const HeaderButtonsWrapper = styled.div`
  display: flex;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  height: 32px;
`
const UpdateButton = styled.button`
  background-color: #FFA300;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 8px 26px;
  height: 32px;
  :disabled {
    background-color: #D7D7D7
  }
`
const OrderClinicDetailsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

function Order() {

  const { id } = useParams<IParamTypes>();
  const [currentOrder, setCurrentOrder] = useState<OrderRetrieval | undefined>(undefined);
  const [newOrder, setNewOrder] = useState<OrderRetrieval | undefined>(undefined);
  const [activityLog, setActivityLog] = useState<ActivityLog | undefined>(undefined);
  const [deliveryLog, setDeliveryLog] = useState<DeliveryLog | undefined>(undefined);
  const [branchOptionList, setBranchOptionList] = useState<Array<ISelectOptionItem>>([]);
  const [CTOptionList, setCTOptionList] = useState<Array<ISelectOptionItem>>([]);
  const [DPOptionList, setDPOptionList] = useState<Array<ISelectOptionItem>>([]);
  const [loading, setLoading] = useState(true);
  const [activityLoading, setActivityLoading] = useState(true);
  const [reloadEverything, setReloadEverything] = useState(false);
  const userStore = useUserStore();

  /**
   * load all page data
   */
  useEffect(() => {

    let isSubscribed = true;

    if (!userStore.accessToken) {
      return
    }

    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios)

    orderApi.orderGET(id, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed) {
        setCurrentOrder(e.data)
        setNewOrder(e.data)
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).finally(() => {
      if (isSubscribed)
        setLoading(false);
    });

    orderApi.orderActivityLogGET(id, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed) {
        setActivityLog(e.data)
        setActivityLoading(false);
      }
    }).catch((e) => { });

    orderApi.orderDeliveryLogGET(id, getRequestConfig(userStore.accessToken)).then((e) => {
      if (typeof e.data === 'string') {
        if (isSubscribed)
          setDeliveryLog(undefined);
      } else {
        if (isSubscribed)
          setDeliveryLog(e.data);
      }
    }).catch((e) => { });

    return () => {
      isSubscribed = false
    };
  }, [id, userStore.accessToken, reloadEverything])

  /**
   * get all select dropdown options
   */
  useEffect(() => {
    let isSubscribed = true;

    if (!userStore.accessToken) {
      return;
    }
    let branchApi: amberSdk.BranchApi = new amberSdk.BranchApi(undefined, undefined, mainAxios);
    branchApi.branchesGET(1, 1000, undefined, undefined, undefined, undefined, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed)
        setBranchOptionList(
          e.data.branches.map((e) => {
            return {
              value: e.id,
              label: e.name,
            }
          })
        )
    }).catch((e) => { })

    let ctApi: amberSdk.CompoundingTechApi = new amberSdk.CompoundingTechApi(undefined, undefined, mainAxios);
    ctApi.compoundingTechsGET(1, 1000, undefined, undefined, undefined, undefined, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed)
        if (e.data.compounding_techs) {
          setCTOptionList(
            e.data.compounding_techs.map((e) => {
              return {
                value: e.id,
                label: e.name,
                orders: e.orders_on_hand,
                branch: e.branch_code,
              }
            })
          )
        }
    }).catch((e) => { })

    let dpApi: amberSdk.DeliveryPersonnelApi = new amberSdk.DeliveryPersonnelApi(undefined, undefined, mainAxios);
    dpApi.deliveryPersonnelsGET(1, 1000, undefined, undefined, undefined, undefined, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed)
        if (e.data.delivery_personnels) {
          setDPOptionList(
            e.data.delivery_personnels.map((e) => {
              return {
                value: e.id,
                label: e.name,
              }
            })
          )
        }
    }).catch((e) => { })

    return () => {
      isSubscribed = false
    };
  }, [userStore.accessToken])

  function updateNewOrder(field: string, value: any) {
    let tempOrder = update(newOrder, {
      [field]: { $set: value }
    })
    setNewOrder(tempOrder);
  }

  function handleSubmitUpdatedOrder(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);
    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios)

    orderApi.orderUpdatePOST(id, {
      order_status: newOrder?.order_status,
      is_verified: newOrder?.is_verified,
      branch_id: newOrder?.branch.id,
      compounding_techs_id: newOrder?.compounding_techs?.map((e) => e.id),
      delivery_personnel_id: newOrder?.delivery_personnel?.id,
      date_of_estimated_delivery: newOrder?.date_of_estimated_delivery,
      reason: newOrder?.reason,
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403 || e.response.status === 500)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).finally(() => {
      setReloadEverything(!reloadEverything)
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  if (currentOrder === null ||
    currentOrder === undefined ||
    newOrder === null ||
    newOrder === undefined
  ) {
    return (
      <HeaderWrapper>
        <h1 className="page-header">Order #{id} is invalid</h1>
      </HeaderWrapper>
    )
  }

  function updateDisabledStatus() {
    const isAdmin = userStore.user && userStore.user.roles.find(e => e === 'ADMIN');
    const isDispenser = userStore.user && userStore.user.roles.find(e => e === 'DISPENSER');
    const isAdminOrDispenser = isAdmin || isDispenser;
    if (isAdminOrDispenser) {
      return false;
    } else if (currentOrder?.order_status === orderStatusEnum.IN_PROCESS) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div>
      <HeaderWrapper>
        <h1 className="page-header">Order #{id}</h1>
        <HeaderButtonsWrapper>
          <CancelButton onClick={() => { setNewOrder(currentOrder) }}>
            Cancel
          </CancelButton>
          <UpdateButton onClick={(e) => handleSubmitUpdatedOrder(e)} disabled={updateDisabledStatus()}>
            Update Order
          </UpdateButton>
        </HeaderButtonsWrapper>
      </HeaderWrapper>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={3} className="order-lg-3">
            <OrderClinicDetailsWrapper>
              <OrderUpdate
                currentOrder={currentOrder}
                newOrder={newOrder}
                setStatus={(e: any) => { updateNewOrder('order_status', e) }}
                setEstimatedDate={(e: any) => { updateNewOrder('date_of_estimated_delivery', e) }}
                setAmberBranch={(e: any) => { updateNewOrder('branch', e) }}
                setCompoundingTechs={(e: any) => { updateNewOrder('compounding_techs', e) }}
                setReason={(e: any) => { updateNewOrder('reason', e) }}
                setDeliveryPersonnel={(e: any) => { updateNewOrder('delivery_personnel', e) }}
                setVerifyOrder={(e: any) => { updateNewOrder('is_verified', e.target.checked) }}

                branchOptionList={branchOptionList}
                CTOptionList={CTOptionList}
                DPOptionList={DPOptionList}
              />
              {/* {(typeof deliveryLog === 'string') ? <></> : <DeliveryLogView logsWrapper={deliveryLog} />} */}
              <DeliveryLogView logsWrapper={deliveryLog} status={currentOrder.order_status} />
            </OrderClinicDetailsWrapper>
          </Col>
          <Col md={5} className="order-lg-2">
            <OrderClinicDetailsWrapper>
              <OrderDetails dateOfOrder={currentOrder.date_of_order} id={id} remarks={currentOrder.remarks} />
              <ClinicDetails clinic={currentOrder.clinic} purchaser={currentOrder.clinic_purchaser} />
            </OrderClinicDetailsWrapper>
          </Col>
          <Col md={4} className="order-lg-1">
            <OrderPhoto orderNumber={currentOrder.order_number} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ActivityLogView logsWrapper={activityLog} isLoading={activityLoading} />
          </Col>
          <Col md={6}>
            <NotesView orderId={id} />
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default Order;