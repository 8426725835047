import React, { useState, useEffect } from 'react';
import HeaderCard from '../../components/dashboard/header-card.jsx';
import moment from 'moment';
import LatestVerifiedOrdersList from './latest-5-verified-listing';
import { useUserStore } from '../../store/userStore';
import * as amberSdk from '../../open-api';
import styled from 'styled-components';
import PendingCompletionListing from './pending-completion-listing';
import LatestOrdersList from './latest-5-listing';
import { adminRoleExists, dispenserRoleExists, getRequestConfig } from '../../common/utils';
import { toast } from 'react-toastify';
import mainAxios from '../../config/axios-config';

const getLastMonthDateRange = () => {
  return `${moment(moment()).subtract(1, 'months').startOf('month').format('DD MMM')} - ${moment(moment()).subtract(1, 'months').endOf('month').format('DD MMM')}`;
}

const getLastMonthStartDate = () => {
  return moment(moment()).subtract(1, 'months').startOf('month').format()
}

const getThisMonthStartDate = () => {
  return moment(moment()).startOf('month').format()
}

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

function Dashboard() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDispenser, setIsDispenser] = useState(false);
  const [totalOrdersCount, setTotalOrdersCount] = useState<string | number>('-');
  const [fulfilledOrdersCount, setFulfilledOrdersCount] = useState<string | number>('-');
  const [totalClinicsCount, setTotalClinicsCount] = useState<string | number>('-');
  const [ordersOnHoldCount, setOrdersOnHoldCount] = useState<string | number>('-');
  const [totalOrdersCountLoading, setTotalOrdersCountLoading] = useState(true);
  const [fulfilledOrdersCountLoading, setFulfilledOrdersCountLoading] = useState(true);
  const [totalClinicsCountLoading, setTotalClinicsCountLoading] = useState(true);
  const [ordersOnHoldCountLoading, setOrdersOnHoldCountLoading] = useState(true);

  const userStore = useUserStore();

  useEffect(() => {

    let isSubscribed = true;

    if (!userStore.accessToken) {
      return
    }

    let profileApi: amberSdk.ProfileApi = new amberSdk.ProfileApi(undefined, undefined, mainAxios)
    profileApi.meGET(
      getRequestConfig(userStore.accessToken)
    ).then((e) => {
      if (adminRoleExists(e.data.roles)) {
        if (isSubscribed)
          setIsAdmin(true)
      }
      if (dispenserRoleExists(e.data.roles)) {
        if (isSubscribed)
          setIsDispenser(true)
      }
    })

    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios)
    // get all orders
    orderApi.ordersGET(1, 1, getLastMonthStartDate(),
      getThisMonthStartDate(), undefined, undefined,
      undefined, undefined, undefined, undefined,
      getRequestConfig(userStore.accessToken)
    ).then((e) => {
      if (isSubscribed)
        setTotalOrdersCount(e.data.paging_metadata.total_count);
    }).catch((e) => {
      if (e.response && e.response.status === 500) {
        toast.error('An error has occured. Please try again later.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (isSubscribed)
          setTotalOrdersCount('-');
      }
    }).finally(() => {
      if (isSubscribed)
        setTotalOrdersCountLoading(false);
    });

    // get all fulfilled orders
    orderApi.ordersGET(1, 1, getLastMonthStartDate(),
      getThisMonthStartDate(), ["FULFILLED"], undefined,
      undefined, undefined, undefined, undefined,
      getRequestConfig(userStore.accessToken)
    ).then((e) => {
      if (isSubscribed)
        setFulfilledOrdersCount(e.data.paging_metadata.total_count);
    }).catch((e) => {
      if (e.response && e.response.status === 500) {
        toast.error('An error has occured. Please try again later.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (isSubscribed)
          setFulfilledOrdersCount('-');
      }
    }).finally(() => {
      if (isSubscribed)
        setFulfilledOrdersCountLoading(false);
    });

    // get all on_hold orders
    orderApi.ordersGET(1, 1, getLastMonthStartDate(),
      getThisMonthStartDate(), ["ON_HOLD"], undefined,
      undefined, undefined, undefined, undefined,
      getRequestConfig(userStore.accessToken)
    ).then((e) => {
      if (isSubscribed)
        setOrdersOnHoldCount(e.data.paging_metadata.total_count);
    }).catch((e) => {
      if (e.response && e.response.status === 500) {
        toast.error('An error has occured. Please try again later.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (isSubscribed)
          setOrdersOnHoldCount('-');
      }
    }).finally(() => {
      if (isSubscribed)
        setOrdersOnHoldCountLoading(false);
    });

    return () => {
      isSubscribed = false
    };

  }, [userStore.accessToken])

  useEffect(() => {

    let isSubscribed = true;

    if (!isAdmin) {
      return;
    }

    if (!userStore.accessToken) {
      return
    }
    // get all clinic count
    let clinicApi: amberSdk.ClinicApi = new amberSdk.ClinicApi(undefined, undefined, mainAxios)
    clinicApi.clinicsGET(1, 1, undefined, undefined, undefined, undefined,
      getRequestConfig(userStore.accessToken)
    ).then((e) => {
      if (isSubscribed)
        setTotalClinicsCount(e.data.paging_metadata.total_count);
    }).catch((e) => {
      if (e.response && e.response.status === 500) {
        toast.error('An error has occured. Please try again later.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (isSubscribed)
          setTotalClinicsCount('-');
      }
    }).finally(() => {
      if (isSubscribed)
        setTotalClinicsCountLoading(false);
    });

    return () => {
      isSubscribed = false
    };

  }, [userStore.accessToken, isAdmin])

  return (
    <div>
      <DashboardHeader>
        <h1 className="page-header">Dashboard</h1>
        <h1 className="page-header">{moment().format('DD MMM YYYY')}</h1>
      </DashboardHeader>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <HeaderCard
            title="Total Orders (last month)"
            value={totalOrdersCount}
            loading={totalOrdersCountLoading}
            dateRange={getLastMonthDateRange()}
            viewLink="/orders" />
        </div>
        <div className="col-xl-3 col-md-6">
          <HeaderCard
            title="Fulfilled Orders (last month)"
            value={fulfilledOrdersCount}
            loading={fulfilledOrdersCountLoading}
            dateRange={getLastMonthDateRange()}
            viewLink="/orders" />
        </div>
        {isAdmin &&
          (
            <div className="col-xl-3 col-md-6">
              <HeaderCard
                title="Total Number of Clinics"
                value={totalClinicsCount}
                loading={totalClinicsCountLoading}
                viewLink="/clinics/all" />
            </div>)
        }
        <div className="col-xl-3 col-md-6">
          <HeaderCard
            title="Orders on Hold"
            value={ordersOnHoldCount}
            loading={ordersOnHoldCountLoading}
            viewLink="/orders" />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-8'>
          {isDispenser &&
            (
              <div style={{ marginBottom: '20px' }}>
                <LatestOrdersList />
              </div>
            )
          }
          <div>
            <LatestVerifiedOrdersList />
          </div>
        </div>
        <div className='col-md-4'>
          <div>
            <PendingCompletionListing />
          </div>
        </div>
      </div>
    </div>
  )
};

export default Dashboard;