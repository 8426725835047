import React from 'react';
import { Redirect } from 'react-router-dom';
import ClinicList from '../pages/clinic-list/clinic-list';
import CreateClinicPurchaser from '../pages/clinic-purchaser/create-clinic-purchaser';
import UpdateClinicPurchaser from '../pages/clinic-purchaser/update-clinic-purchaser';

import Dashboard from '../pages/dashboard/dashboard';
import OrderList from '../pages/order-list/order-list';
import Order from '../pages/order/order';
import PurchaserList from '../pages/purchaser-list/purchaser-list';
import LoginV3 from '../pages/login/login-v3';
import CreateUser from '../pages/user-management/create-user';
import UpdateUser from '../pages/user-management/update-user';
import UserList from '../pages/user-list/user-list';
import ForgetPassword from '../pages/password-reset/forget-password';
import ResetPassword from '../pages/password-reset/reset-password';
import CreateBranch from '../pages/amber-branch/create-branch';
import UpdateBranch from '../pages/amber-branch/update-branch';
import BranchList from '../pages/branch-list/branch-list';
import CreateClinic from '../pages/clinic/create-clinic';
import UpdateClinic from '../pages/clinic/update-clinic';
import DeliveryPersonnelList from '../pages/delivery-personnel-list/delivery-personnel-list';
import CreateDeliveryPersonnel from '../pages/delivery-personnel/create-delivery-personnel';
import UpdateDeliveryPersonnel from '../pages/delivery-personnel/update-delivery-personnel';
import CreateCompoundingTech from '../pages/compounding-tech/create-compounding-tech';
import CompoundingTechList from '../pages/compounding-tech-list/compounding-tech-list';
import UpdateCompoundingTech from '../pages/compounding-tech/update-compounding-tech';
import UpdateProfile from '../pages/profile/update-profile';
import NotFoundPage from '../pages/not-found';

export interface IRouteArrayItem {
  path: string;
  exact?: boolean;
  title: string;
  component: () => any;
}

const routes: Array<IRouteArrayItem> = [
  {
    path: '/',
    exact: true,
    title: '',
    component: () => <Redirect to='/dashboard'/>
  },
  {
    path: '/dashboard',
    exact: true,
    title: 'Dashboard',
    component: () => <Dashboard />
  },
  {
    path: '/login',
    exact: true,
    title: 'Login',
    component: () => <LoginV3 />,
  },
  {
    path: '/password/forget',
    exact: true,
    title: 'Forget Password',
    component: () => <ForgetPassword />,
  },
  {
    path: '/password/reset',
    exact: true,
    title: 'Set Password',
    component: () => <ResetPassword />,
  },
  {
    path: '/orders',
    title: 'Orders',
    component: () => <OrderList />,
  },
  {
    path: '/order/:id',
    title: 'Order',
    component: () => <Order />,
  },
  {
    path: '/clinics/all',
    title: 'Clinics',
    component: () => <ClinicList />,
  },
  {
    path: '/clinics/create',
    title: 'Create Clinic',
    component: () => <CreateClinic />,
  },
  {
    path: '/clinics/purchasers/all',
    title: 'Purchasers',
    component: () => <PurchaserList />,
  },
  {
    path: '/clinics/purchasers/create',
    title: 'Create Purchaser',
    exact: true,
    component: () => <CreateClinicPurchaser />,
  },
  {
    path: '/clinic/purchaser/:id',
    title: 'Update Purchaser',
    component: () => <UpdateClinicPurchaser />,
  },
  {
    path: '/clinics/:id',
    title: 'Update Clinic',
    component: () => <UpdateClinic />,
  },
  {
    path: '/amber-branches/create',
    title: 'Create Branch',
    exact: true,
    component: () => <CreateBranch />,
  },
  {
    path: '/amber-branches/all',
    title: 'Branches',
    component: () => <BranchList />,
  },
  {
    path: '/amber-branches/:id',
    title: 'Update Branch',
    component: () => <UpdateBranch />,
  },
  {
    path: '/delivery-personnel/create',
    title: 'Create Delivery Personnel',
    exact: true,
    component: () => <CreateDeliveryPersonnel />,
  },
  {
    path: '/delivery-personnel/all',
    title: 'Delivery Personnel',
    component: () => <DeliveryPersonnelList />,
  },
  {
    path: '/delivery-personnel/:id',
    title: 'Update Delivery Personnel',
    component: () => <UpdateDeliveryPersonnel />,
  },
  {
    path: '/compounding-tech/create',
    title: 'Create Compounding Technician',
    exact: true,
    component: () => <CreateCompoundingTech />,
  },
  {
    path: '/compounding-tech/all',
    title: 'Compounding Technicians',
    component: () => <CompoundingTechList />,
  },
  {
    path: '/compounding-tech/:id',
    title: 'Update Compounding Technician',
    component: () => <UpdateCompoundingTech />,
  },
  {
    path: '/users/create',
    title: 'Create User',
    exact: true,
    component: () => <CreateUser />,
  },
  {
    path: '/users/all',
    title: 'Users',
    exact: true,
    component: () => <UserList />,
  },
  {
    path: '/user/:id',
    title: 'Update User',
    component: () => <UpdateUser />,
  },
  {
    path: '/profile',
    title: 'Update Profile',
    component: () => <UpdateProfile />,
  },
  {
    path: '/*',
    title: '404',
    component: () => <NotFoundPage />,
  }
];

export default routes;