export const columnsDefinition = [
  {
    Header: 'Contact Name',
    accessor: 'name',
  },
  {
    Header: 'Mobile Number',
    accessor: 'mobile_contact',
  },
  {
    Header: 'Deliveries on Hand',
    accessor: 'deliveries_on_hand',
  },
  {
    Header: 'Account Status',
    accessor: 'status',
  },
];
