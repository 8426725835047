import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

const HeaderText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`
const BodyOptions = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }
`
const BodyOptionsWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
`
const BodyOptionsText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.01em;
  padding-left: 10px;
`
const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`
const RotateButton = styled.button`
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  cursor: pointer;
  color: #3A3A3A;
  padding: 4px 8px;

  background: transparent;
  &:hover {
    background: #D7D7D7;
  }
`
const PrintButton = styled.button`
  border: 1px solid #D7D7D7;
  border-radius: 4px;
  cursor: pointer;
  color: #3A3A3A;
  padding: 4px 8px;
  background: #D7D7D7;
  margin-right: 8px;
  &:hover {
    background: #B6B6B6;
  }
`
const DownloadButton = styled.button`
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #FFFFFF;
  padding: 4px 8px;
  background: #FFA300;
  
  &:hover {
    background: #EFB349;
  }

  &:active {
    background: #CB8200;
  }
`

function ThumbnailModal({ modalIsOpen, handleClose, data, title }) {
  const [rotate, setRotate] = useState(0);
  const [zoom, setZoom] = useState(false);
  function handleRotate() {
    if (rotate > 180) {
      setRotate(0);
    }
    setRotate(rotate + 90);
  }

  function printImg(url) {
    var win = window.open('');
    win.document.write('<img style="height:950px" src="' + url + '" onload="window.print();window.close()" />');
    win.focus();
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      style={{
        maxWidth: '70vw',
        width: '100%',
      }}
      scrollable
      autoFocus={false}
      toggle={handleClose}
    >
      <ModalHeader
        style={{
          backgroundColor: '#FFEBC9',
        }}
        toggle={handleClose}
      >
        <HeaderText>
          {title ? title : 'Order Photo'}{data.base64 ? '' : ` | ${data.id}`}
        </HeaderText>
      </ModalHeader>
      <ModalBody>
        <BodyOptionsWrapper>
          <RotateButton onClick={handleRotate}>
            <img src='/assets/img/svg/ic-rotate-photo.svg' alt="rotate" />
            <BodyOptionsText>Rotate Photo</BodyOptionsText>
          </RotateButton>
          <BodyOptions>
          {data.base64 ?
            <PrintButton onClick={()=>printImg(`data:image/jpeg;base64,${data.base64}`)}>
              <img src='/assets/img/svg/ic-printer.svg' alt="" />
              <BodyOptionsText>Print</BodyOptionsText>
            </PrintButton>
            :
            <PrintButton onClick={()=>printImg(data.blob)}>
              <img src='/assets/img/svg/ic-printer.svg' alt="" />
              <BodyOptionsText>Print</BodyOptionsText>
            </PrintButton>
          }
          {data.base64 ?
            <a href={`data:image/jpeg;base64,${data.base64}`} download={`${data.id}.png`}>
              <DownloadButton>
                <img src='/assets/img/svg/ic-download.svg' alt="" />
                <BodyOptionsText>Download</BodyOptionsText>
              </DownloadButton>
            </a>
            :
            <a href={data.blob} download={`${data.id}.png`}>
              <DownloadButton>
                <img src='/assets/img/svg/ic-download.svg' alt="" />
                <BodyOptionsText>Download</BodyOptionsText>
              </DownloadButton>
            </a>
          }
          </BodyOptions>
        </BodyOptionsWrapper>
        <PhotoWrapper>
          {data.base64 && <img
            src={`data:image/jpeg;base64,${data.base64}`}
            style={{
              transform: `rotate(${rotate}deg)`
              , maxHeight: `${zoom ? 'none' : '80vh'}`
              , width: `${zoom ? '80%' : 'none'}`
              , cursor: `${zoom ? 'zoom-out' : 'zoom-in'}`
              , objectFit: 'contain'
            }}
            alt="order"
            onClick={() => {
              setZoom(!zoom);
            }}
          />
          }
          {data.blob && <img
            src={data.blob}
            style={{
              transform: `rotate(${rotate}deg)`
              , maxHeight: `${zoom ? 'none' : '80vh'}`
              , width: `${zoom ? '80%' : 'none'}`
              , cursor: `${zoom ? 'zoom-out' : 'zoom-in'}`
              , objectFit: 'contain'
            }}
            alt="order"
            onClick={() => {
              setZoom(!zoom);
            }}
          />
          }
        </PhotoWrapper>
      </ModalBody>
    </Modal>
  );
};

export default ThumbnailModal;