import { OrderNotesRetrieval } from '../../open-api';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../components/card/card';
import NotesTable from './notes-table';
import BounceLoader from 'react-spinners/BounceLoader';
import * as amberSdk from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { getRequestConfig } from '../../common/utils';
import mainAxios from '../../config/axios-config';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const NoteTextArea = styled.textarea`
  resize: none;
  border: solid 1px #E0E0E0;
  border-radius: 4px;
  height: 72px;
  width: 100%;
  margin-bottom: 20px;
  padding: 8px 12px; 
`
const AddButton = styled.button`
  background-color: #FFA300;
  border-radius: 4px;
  border: none;
  padding: 4px 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  float: right;
  margin-bottom: 20px;
`

interface Props {
  orderId: string;
}

function Notes({ orderId }: Props) {

  const [message, setMessage] = useState("");
  const [orderNotes, setOrderNotes] = useState<OrderNotesRetrieval | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);
  const [reloadNotes, setReloadNotes] = useState(true);
  const userStore = useUserStore();

  function addNotesSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);
    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios);
    orderApi.orderNotesCreatePOST(orderId, { message: message }, getRequestConfig(userStore.accessToken)).then((e)=>{
      setReloadNotes(true);
      setMessage('');
    })
  }

  useEffect(() => {
    if(!userStore.accessToken) {
      return;
    }
    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios)

    orderApi.orderNotesGET(orderId, getRequestConfig(userStore.accessToken)).then((e) => {
      setOrderNotes(e.data)
      setLoading(false);
      setReloadNotes(false);
    });
  }, [orderId, reloadNotes, userStore.accessToken])
  
  if (isLoading) {
    <Card style={{ flexGrow: 1 }} header="Notes">
      <BounceLoader
        color={"#FFA300"}
        loading={isLoading}
        size={48}
        speedMultiplier={1}
      />
    </Card>
  }

  return (
    <Card style={{ flexGrow: 1 }} header="Notes">
      <BodyWrapper>
        <form>
          <NoteTextArea value={message} onChange={(e) => setMessage(e.target.value)} />
          <br />
          <AddButton onClick={addNotesSubmit}>
            Add
          </AddButton>
        </form>
        {orderNotes?.notes === undefined ? '' : (<NotesTable notesWrapper={orderNotes} />)}

      </BodyWrapper>
    </Card>
  )
};

export default Notes;