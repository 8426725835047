import React from "react";
import { useHistory } from "react-router";
import { Modal, ModalBody } from "reactstrap";

import styled from 'styled-components';
import { useUserStore } from "../../store/userStore";

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`
const LogoutButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  height: 32px;
`
const ContinueButton = styled.button`
  background-color: #FFA300;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  padding: 8px 26px;
  height: 32px;
`
const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 46px 19px 32px 19px
`

interface IProps {
  open: boolean;
  countdown: number;
  onContinue: () => void;
}

const SessionTimeoutDialog = ({ open, countdown, onContinue }: IProps) => {

  const userStore = useUserStore();
  const history = useHistory();
  
  function onLogout() {
    userStore.unsetUser();
    localStorage.removeItem('amber_refresh_token');
		history.push('/login');
  }

  return (
    <Modal
      isOpen={open}
      centered
    >
      <ModalBody>
        <BodyWrapper>
          <img style={{ height: '78px', width: '78px', marginBottom: '29px' }} src="/assets/img/svg/ic-session-timeout.svg" alt="" />
          <h1 style={{ marginBottom: '16px' }}>
            Session Timeout
          </h1>
          <p className="p1" style={{ marginBottom: '24px' }}>
            Your session is about to expire.
            <br />
            Click continue to extend session.
          </p>
          <ButtonGroup>
            <LogoutButton
              onClick={onLogout}>
              Logout
            </LogoutButton>
            <ContinueButton
              onClick={onContinue}>
              Continue Session  {`(${countdown})`}
            </ContinueButton>
          </ButtonGroup>
        </BodyWrapper>
      </ModalBody>
    </Modal>
  );
}
export default SessionTimeoutDialog;
