import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import BounceLoader from 'react-spinners/BounceLoader';
import { Col, Container, Row } from 'reactstrap';
import { ClinicCreation } from '../../open-api';
import * as amberSdk from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import { countryCodeOptionList, countryOptionList, ISelectOptionItem, IsValidEmail } from '../../common/utils';
import CustomSelect from '../../components/custom-select/custom-select';
import { getRequestConfig } from '../../common/utils';
import { useHistory } from 'react-router-dom';
import { clinicStatusOption } from '../../components/account-status/account-status';
import mainAxios from '../../config/axios-config';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  width: 100%;
  height: 32px;
`
const CreateButton = styled.button`
  padding: 8px 0;
  width: 100%;
  background: #005FAA;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const emptyCreateObject: ClinicCreation = {
  country: '',
  unit: '',
  address: '',
  postal: '',
  branch_id: '',
  name: '',
  mobile_contact: {
    country_calling_code: '+65',
    phone_number: '',
  },
  email: '',
  status: 'ACTIVE',
}
function CreateClinic() {
  const history = useHistory();

  const [newCreateObject, setNewCreateObject] = useState<ClinicCreation>(emptyCreateObject);
  const [loading, setLoading] = useState(false);
  const [branchOptionList, setBranchOptionList] = useState<Array<ISelectOptionItem>>([]);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [branchError, setBranchError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [postalError, setPostalError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const userStore = useUserStore();

  /**
   * get all select dropdown options
   */
   useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }
    let branchApi: amberSdk.BranchApi = new amberSdk.BranchApi(undefined, undefined, mainAxios);
    branchApi.branchesGET(1, 3000, undefined, undefined, undefined, undefined, getRequestConfig(userStore.accessToken)).then((e) => {
      setBranchOptionList(
        e.data.branches.map((e) => {
          return {
            value: e.id,
            label: e.code,
          }
        })
      )
    }).catch((e) => {
    }).finally(() => {
      setLoading(false);
    })
  }, [userStore.accessToken])
  
  function handleInputChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      [field]: { $set: value }
    })
    setNewCreateObject(tempOrder);

    if (field === 'name') {
      if (nameError) {
        setNameError(false);
      }
    } else if (field === 'branch_id') {
      if (branchError) {
        setBranchError(false);
      }
    } else if (field === 'country') {
      if (countryError) {
        setCountryError(false);
      }
    } else if (field === 'postal') {
      if (postalError) {
        setPostalError(false);
      }
    } else if (field === 'unit') {
      if (unitError) {
        setUnitError(false);
      }
    } else if (field === 'address') {
      if (addressError) {
        setAddressError(false);
      }
    } else if (field === 'email') {
      if (emailError) {
        setEmailError(false)
      }
    }
  }

  function handleMobileChange(field: string, value: any) {
    let tempOrder = update(newCreateObject, {
      mobile_contact: {
        [field]: { $set: value }
      }
    })
    setNewCreateObject(tempOrder);
    if (mobileError) {
      setMobileError(false)
    }
  }

  function validationError(): boolean {
    let haveError = false;
    if (!newCreateObject.name) {
      if (!nameError) {
        setNameError(true)
      }
      haveError = true;
    } else {
      if (nameError) {
        setNameError(false)
      }
    }

    if (!newCreateObject.mobile_contact.phone_number) {
      if (!mobileError) {
        setMobileError(true)
      }
      haveError = true;
    } else {
      if (mobileError) {
        setMobileError(false)
      }
    }

    if (!newCreateObject.branch_id) {
      if (!branchError) {
        setBranchError(true)
      }
      haveError = true;
    } else {
      if (branchError) {
        setBranchError(false)
      }
    }

    if (!newCreateObject.country) {
      if (!countryError) {
        setCountryError(true)
      }
      haveError = true;
    } else {
      if (countryError) {
        setCountryError(false)
      }
    }

    if (!newCreateObject.address) {
      if (!addressError) {
        setAddressError(true)
      }
      haveError = true;
    } else {
      if (addressError) {
        setAddressError(false)
      }
    }

    if (!newCreateObject.postal) {
      if (!postalError) {
        setPostalError(true)
      }
      haveError = true;
    } else {
      if (postalError) {
        setPostalError(false)
      }
    }

    if (!newCreateObject.unit) {
      if (!unitError) {
        setUnitError(true)
      }
      haveError = true;
    } else {
      if (unitError) {
        setUnitError(false)
      }
    }

    if(!newCreateObject.email) {
      if (!emailError) {
        setEmailError(true)
      }
      haveError = true;
    } else {
      if(!IsValidEmail(newCreateObject.email)){
        if (!emailError) {
          setEmailError(true)
        }
        haveError = true;
      } else {
        if (emailError) {
          setEmailError(false)
        }
      }
    }

    return haveError;
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);

    if (validationError()) {
      setLoading(false);
      return;
    }

    let clinicApi: amberSdk.ClinicApi = new amberSdk.ClinicApi(undefined, undefined, mainAxios)

    clinicApi.clinicCreatePOST({
      name: newCreateObject.name,
      mobile_contact: {
        country_calling_code: newCreateObject.mobile_contact.country_calling_code,
        phone_number: newCreateObject.mobile_contact.phone_number,
      },
      branch_id: newCreateObject.branch_id,
      status: newCreateObject.status,
      country: newCreateObject.country,
      address: newCreateObject.address,
      unit: newCreateObject.unit, 
      postal: newCreateObject.postal,
      email: newCreateObject.email,
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully created', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNewCreateObject(emptyCreateObject);
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_mobile_contact') {
          setMobileError(true);
        } 
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <h1 className="page-header">Create Clinic</h1>
      </HeaderWrapper>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "32px" }}>
          <Col md={6}>
            <span className='p1'>
              Create a new clinic
            </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={1}>
            Clinic Name*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${nameError ? 'is-invalid' : ''}`}
              placeholder="Clinic Name"
              id="clinicName"
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={newCreateObject.name}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
          <Col md={1}/>
          <Col md={2}>
            Clinic Account Status*
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('status', e.value)}
              options={clinicStatusOption}
              placeholder='Status'
              value={clinicStatusOption.find(e => e.value === newCreateObject.status)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={1}>
            Phone Number*
          </Col>
          <Col md={4} style={{display: 'flex'}}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleMobileChange('country_calling_code', e.value)}
              options={countryCodeOptionList}
              value={countryCodeOptionList.find(e => e.value === newCreateObject.mobile_contact.country_calling_code)}
            />
            <input
              type="text"
              className={`form-control fs-13px ${mobileError ? 'is-invalid' : ''}`}
              placeholder="Phone Number"
              id="mobileNumber"
              onChange={(e) => handleMobileChange('phone_number', e.target.value)}
              value={newCreateObject.mobile_contact.phone_number}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
          <Col md={1}/>
          <Col md={2}>
          Email*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${emailError ? 'is-invalid' : ''}`}
              placeholder="Email"
              id="email"
              onChange={(e) => handleInputChange('email', e.target.value)}
              value={newCreateObject.email}
            />
            <div className="invalid-feedback">Invalid email</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={1}>
            Address*
          </Col>
          <Col md={4}>
            <textarea
              className={`form-control fs-13px ${addressError ? 'is-invalid' : ''}`}
              placeholder="Address"
              id="address"
              onChange={(e) => handleInputChange('address', e.target.value)}
              value={newCreateObject.address}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
          <Col md={1}/>
          <Col md={2}>
            Unit*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${unitError ? 'is-invalid' : ''}`}
              placeholder="Unit"
              id="unit"
              onChange={(e) => handleInputChange('unit', e.target.value)}
              value={newCreateObject.unit}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={1}>
            Country*
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('country', e.value)}
              options={countryOptionList}
              isSearchable
              placeholder='Country'
              value={countryOptionList.find(e => e.value === newCreateObject.country)}
              isError={countryError}
            />
          </Col>
          <Col md={1}/>
          <Col md={2}>
            Postal Code*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${postalError ? 'is-invalid' : ''}`}
              placeholder="Postal Code"
              id="postal"
              onChange={(e) => handleInputChange('postal', e.target.value)}
              value={newCreateObject.postal}
            />
            <div className="invalid-feedback">Required field</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={1}>
            Amber Branch*
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('branch_id', e.value)}
              options={branchOptionList}
              placeholder='Branch Code'
              value={branchOptionList.find(e => e.value === newCreateObject.branch_id)}
              isError={branchError}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={9} />
          <Col md={1}>
            <CancelButton type='button' onClick={history.goBack}>
              Cancel
            </CancelButton>
          </Col>
          <Col md={2}>
            <CreateButton type='submit' onClick={handleSubmit}>
              + Create Clinic
            </CreateButton>
          </Col>
        </Row>
      </Container>

    </div>
  )
};

export default CreateClinic;