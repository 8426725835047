export const columnsDefinition = [
  {
    Header: 'Branch Name',
    accessor: 'name',
  },
  {
    Header: 'Branch Code',
    accessor: 'code',
  },
  {
    Header: 'Phone Number',
    accessor: 'mobile_contact',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Clinics',
    accessor: 'clinic_counts',
  },
  {
    Header: 'Date Created',
    accessor: 'date_created',
  },
  {
    Header: 'Account Status',
    accessor: 'status',
  },
];
