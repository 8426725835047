import styled from 'styled-components';
import Card from '../../components/card/card';
import moment from 'moment';
import { DeliveryLog, DeliveryLogStatusEnum, OrderDeliveredOfDeliveryLog, OrderDeliveryFailed, OrderStatus } from '../../open-api/api';
import { useState } from 'react';
import ThumbnailModal from '../../components/modal/thumbnail-modal';
import { orderStatusEnum } from '../../components/order-status/order-status';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div:first-child {
    margin-top: 0;
  }
`
const DateStatusWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #4A4A4A;
`
const ContentTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4A4A4A;
`
const ContentText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3A3A3A;
`
const Thumbnail = styled.img`
  width: 24px;
  height: 24px;
  background-color: #E9E9E9;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  cursor: pointer;
`

interface Props {
  logsWrapper: DeliveryLog | undefined;
  status: OrderStatus;
}
interface DeliveryContentProps {
  orderContent?: DeliveryLog;
}
interface IThumbnailState {
  base64: string;
  id: string;
}

function getDeliveryStatusMessage(logsWrapper: DeliveryLog | undefined) {
  if (logsWrapper === undefined || logsWrapper === null) {
    return ` | Pending Delivery`;
  } else if ((logsWrapper as DeliveryLog).status === DeliveryLogStatusEnum.Failed) {
    return `${moment((logsWrapper as DeliveryLog).date).format('DD MMM YYYY')} | Failed Delivery`;
  } else if ((logsWrapper as DeliveryLog).status === DeliveryLogStatusEnum.Succeeded) {
    return `${moment((logsWrapper as DeliveryLog).date).format('DD MMM YYYY')} | Order Delivered`;
  }
}

function DeliveryContent({ orderContent }: DeliveryContentProps) {
  const [thumbnail, setThumbnail] = useState<IThumbnailState>({
    base64: "",
    id: ""
  });
  if (orderContent === undefined) {
    return <>-</>
  }
  if (orderContent.status === DeliveryLogStatusEnum.Failed) {
    return (
      <>
        <ContentTitle>
          Reason for Failure to Deliver
        </ContentTitle>
        <br />
        <ContentText>
          {(orderContent.details as OrderDeliveryFailed).reason}
        </ContentText>
      </>
    )
  } else if (orderContent.status === DeliveryLogStatusEnum.Succeeded) {
    return (
      <div>
        <Thumbnail src={`data:image/jpeg;base64,${(orderContent.details as OrderDeliveredOfDeliveryLog).image}`} onClick={() => setThumbnail({ base64: (orderContent.details as OrderDeliveredOfDeliveryLog).image, id: orderContent.details.id })} />
        <ContentTitle>
          Proof of Delivery
        </ContentTitle>
        {
          thumbnail.id && <ThumbnailModal title="Proof of Delivery" modalIsOpen={thumbnail.id !== ""} handleClose={() => setThumbnail({
            base64: "",
            id: "",
          })} data={thumbnail} />
        }
      </div>
    )
  }
  return <>-</>
}

function DeliveryLogView({ logsWrapper, status }: Props) {
  if (logsWrapper !== undefined) {
    return (
      <Card style={{ flexGrow: 1 }} headerStyle={{ backgroundColor: "#CBE8FF" }} header="Delivery Log" >
        <BodyWrapper>
          <DateStatusWrapper>
            {/* {moment(logsWrapper.date).format('DD MMM YYYY')} |  */}
            {getDeliveryStatusMessage(logsWrapper)}
          </DateStatusWrapper>
          <DeliveryContent orderContent={logsWrapper} />
        </BodyWrapper>
      </Card>
    )
  } else if (status === orderStatusEnum.DELIVERED ||
    status === orderStatusEnum.DELIVERY_FAILED ||
    status === orderStatusEnum.FULFILLED
  ) {
    return (
      <Card style={{ flexGrow: 1 }} headerStyle={{ backgroundColor: "#CBE8FF" }} header="Delivery Log" >
        <BodyWrapper>
          -
        </BodyWrapper>
      </Card>
    )
  } else {
    return <></>;
  }
};

export default DeliveryLogView;