
export const columnsDefinition = [
  {
    Header: 'Clinic Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Postal Code',
    accessor: 'postal',
  },
  {
    Header: 'Amber Branch',
    accessor: 'branch_code',
  },
  {
    Header: 'Purchasers',
    accessor: 'clinic_purchaser_counts',
  },
  {
    Header: 'Date Created',
    accessor: 'date_created',
  },
  {
    Header: 'Account Status',
    accessor: 'status',
  },
];
