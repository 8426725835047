import styled from 'styled-components';
import Card from '../../components/card/card';
import moment from 'moment';
import { ActivityLog } from '../../open-api';
import BounceLoader from 'react-spinners/BounceLoader';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #4A4A4A;
    margin-right: 16px;
    margin-bottom: 0;
  }
  hr {
    margin: 0;
  }
  div:first-child {
    margin-top: 0;
  }
`
const SingleLog = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
`

interface Props {
  logsWrapper: ActivityLog | undefined;
  isLoading: boolean;
}

function ActivityLogView({ logsWrapper, isLoading }: Props) {

  if (isLoading) {
    <Card style={{ flexGrow: 1 }} header="Activity Log">
      <BounceLoader
          color={"#FFA300"}
          loading={isLoading}
          size={48}
          speedMultiplier={1}
        />
    </Card>
  }

  return (
    <Card style={{ flexGrow: 1 }} header="Activity Log">
      <BodyWrapper>
        {logsWrapper && logsWrapper.logs.map((log, index) => (
          <div key={log.id}>
            {index>0 && <hr/>}
            <SingleLog style={index === 0 ? { paddingTop: 0 } : {}} key={log.id}>
              <p>{moment(log.date).format('DD MMM YYYY [at] h:mmA')}</p>
              <div style={{whiteSpace: 'pre-wrap'}}>{log.message}</div>
            </SingleLog>
          </div>
        ))}
      </BodyWrapper>
    </Card>
  )
};

export default ActivityLogView;