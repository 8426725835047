import React, { useState, useEffect, useCallback } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import 'react-datetime/css/react-datetime.css';
import CustomSelect from '../../components/custom-select/custom-select';
import Pagination from '../../components/pagination/pagination';
import { columnsDefinition } from './table-meta';
import { CompoundingTechsRetrievalCompoundingTechs, PagingMetadata } from '../../open-api';
import { Link, useHistory } from 'react-router-dom';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import * as amberSdk from '../../open-api';
import { statusOptionList, getEncodeOrUndefined, getRequestConfig, getSortDirection, adminRoleExists, dispenserRoleExists } from '../../common/utils';
import {
  CreateButton, EntriesDiv, FilterColumn, FilterRow, ListContentWrapper,
  ListHeader, ListHeaderWrapper, ListWrapper, LoaderWrapper, PageFooter, PageHeader,
  SearchDiv, SearchInput, SearchOptionRow
} from '../../common/styles';
import CompoundingTechTable from './compounding-tech-table';
import mainAxios from '../../config/axios-config';

function getSortColumn(selectedColumn: string | undefined) {
  switch (selectedColumn) {
    case ('name'):
      return 'NAME' as const;
    case ('email'):
      return 'EMAIL' as const;
    case ('mobile_contact'):
      return 'MOBILE' as const;
    case ('branch_code'):
      return 'BRANCH_CODE' as const;
    case ('orders_on_hand'):
      return 'ORDERS_ON_HAND' as const;
    case ('status'):
      return 'STATUS' as const;
    default:
      return undefined;
  }
}

function getFilterValue(selectedFilter: 'ACTIVE' | 'INACTIVE' | string | undefined) {
  if (selectedFilter === '' ||
    selectedFilter === undefined) {
    return undefined;
  }
  return selectedFilter as 'ACTIVE' | 'INACTIVE';
}

const entryOptions = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
];

function CompoundingTechList() {

  const [data, setData] = useState<Array<CompoundingTechsRetrievalCompoundingTechs>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [sortColumn, setSortColumn] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<string | undefined>(undefined);
  const [reloadData, setReloadData] = useState<boolean>(true);
  const [pageMeta, setPageMeta] = useState<PagingMetadata>({
    page: 1,
    per_page: 10,
    total_page: 1,
    total_count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const userStore = useUserStore();
  const history = useHistory();

  const setCurrentPageCallback = useCallback((selectedPage: number) => {
    setCurrentPage(selectedPage);
  }, []);

  function onSort(column: string) {
    if (sortColumn === column && sortDirection === 'asc') {
      setSortDirection('desc')
    } else {
      setSortDirection('asc')
    }
    if (sortColumn !== column) {
      setSortColumn(column);
    }
    setReloadData(!reloadData);
  }

  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }

    if (userStore.user.id) {
      if (!(adminRoleExists(userStore.user.roles) || dispenserRoleExists(userStore.user.roles))) {
        history.push("/not-found");
        return;
      }
    }

  }, [userStore.accessToken, userStore.user, history])

  useEffect(() => {

    let isSubscribed = true;

    if (!userStore.accessToken) {
      return;
    }
    // pull the data here and set loading to false when completed
    let ctApi: amberSdk.CompoundingTechApi = new amberSdk.CompoundingTechApi(undefined, undefined, mainAxios);

    ctApi.compoundingTechsGET(currentPage, rowsPerPage,
      getFilterValue(filter),
      getEncodeOrUndefined(searchTerm), getSortColumn(sortColumn), getSortDirection(sortDirection),
      getRequestConfig(userStore.accessToken)
    ).then((e) => {
      if (isSubscribed) {
        setData(e.data.compounding_techs)
        setPageMeta(e.data.paging_metadata)
      }
    }).catch((e) => {
      if (e.response && e.response.status === 500) {
        toast.error('An error has occured. Please try again later.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (isSubscribed)
        setData([])
    }).finally(() => {
      if (isSubscribed)
        setLoading(false)
    });
    
    return () => {
      isSubscribed = false
    };
  }, [userStore.accessToken, currentPage, rowsPerPage, filter,
    searchTerm, sortColumn, sortDirection])

  return (
    <div>
      <PageHeader>
        <h1 className="page-header">Compounding Technicians</h1>
        <Link to='/compounding-tech/create'>
          <CreateButton>
            &#43; Create Compounding Tech
          </CreateButton>
        </Link>
      </PageHeader>
      <ListWrapper>
        <ListHeaderWrapper>
          <ListHeader>List of Compounding Technicians</ListHeader>
        </ListHeaderWrapper>

        <ListContentWrapper>
          {/* filters */}
          <FilterRow>
            <FilterColumn>
              <h3>Account Status</h3>
              <CustomSelect
                onChange={(e: any) => {
                  setFilter(e ? e.value : undefined);
                  setCurrentPage(1);
                }}
                options={statusOptionList}
                isClearable={true}
                placeholder="Select status" />
            </FilterColumn>
          </FilterRow>

          {/* options and search */}
          <SearchOptionRow>
            <div className="col-auto">
              <EntriesDiv>
                <span style={{ paddingRight: "8px" }} className='p1'>Show</span>
                <CustomSelect onChange={(e: any) => {
                  setRowsPerPage(e.value);
                  setCurrentPage(1);
                }} options={entryOptions} defaultValue={{ label: "10", value: 10 }} />
                <span style={{ paddingLeft: "8px" }} className='p1'>entries</span>
              </EntriesDiv>
            </div>
            <div className="col-auto">
              <SearchDiv>
                <span style={{ paddingRight: "8px" }} className='p1'>Search</span>
                <SearchInput
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    let newValue = e.currentTarget.value;
                    setSearchTerm(newValue);
                    setCurrentPage(1);
                  }}
                  placeholder="Search Name"
                />
              </SearchDiv>
            </div>
          </SearchOptionRow>

          {/* main table */}
          <div>
            {
              loading ?
                <LoaderWrapper>
                  <BounceLoader
                    color={"#FFA300"}
                    loading={loading}
                    size={48}
                    speedMultiplier={1}
                  />
                </LoaderWrapper> :
                <CompoundingTechTable data={data} columns={columnsDefinition} onSort={onSort} sortOptions={{
                  sortColumn, sortDirection
                }} />
            }
          </div>

          {/* pagination */}
          <div style={{ marginTop: "16px" }}>
            <Pagination pageChangeHandler={setCurrentPageCallback} pageMeta={pageMeta} />
          </div>
        </ListContentWrapper>
      </ListWrapper>
      <PageFooter>
        <Link to='/compounding-tech/create'>
          <CreateButton>
            &#43; Create Compounding Tech
          </CreateButton>
        </Link>
      </PageFooter>
    </div>
  )
};

export default CompoundingTechList;