import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import SidebarNavList from './sidebar-nav-list.jsx';
import defaultMenus from './menu.jsx';
import { useUserStore } from '../../store/userStore';

function SidebarNav() {
  const [active, setActive] = useState(-1);
  const [clicked, setClicked] = useState(-1);
  const [menu, setMenu] = useState(defaultMenus);
  const userStore = useUserStore();

  useEffect(()=>{
    let highestUserRole = ''
    userStore.user.roles.forEach(element => {
      if(highestUserRole === 'ADMIN') {
        return;
      } else if (highestUserRole === 'DISPENSER' && element === 'PHARMACIST') {
        return;
      } else {
        highestUserRole = element;
      }
    });
    let newMenu = [];
    newMenu = defaultMenus.filter((item) => {
      return item.roles.includes(highestUserRole)
    })
    setMenu(newMenu)
  }, [userStore.user.roles])

  function handleExpand(e, i, match) {
    e.preventDefault();

    if (clicked === -1 && match) {
      setActive(-1)
      setClicked(1)
    } else {
      setActive(active === i ? -1 : i)
      setClicked(1)
    }
  }

    return (
      <div className="menu">
        <div className="menu-header">Navigation</div>
        {menu.map((menu, i) => (
          <Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
            <SidebarNavList
              data={menu}
              key={i}
              expand={(e) => handleExpand(e, i, match)}
              active={i === active}
              clicked={clicked}
            />
          )} />
        ))}
      </div>
    );
}

export default SidebarNav;