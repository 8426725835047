import { OrderNotesRetrieval, OrderNotesRetrievalNotes } from '../../open-api';
import moment from 'moment';
import { Column, useTable } from 'react-table';
import styled from 'styled-components';


const StyledTable = styled.table`
  border-spacing: 0;
  border: 1px solid #E0E0E0;
  border-radius: 40px;
  width: 100%;

  th {
    background-color: #F0F0F0;
    padding: 10px 0px 10px 16px;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #3A3A3A;
  }

  td {
    margin: 0;
    padding: 10px 0px 10px 16px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #4A4A4A;
    word-wrap: break-word;
  }
`

const columns: Array<Column<OrderNotesRetrievalNotes>> = [
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Notes',
    accessor: 'message',
  },
]

interface Props {
  notesWrapper: OrderNotesRetrieval;
}

function NotesTable({ notesWrapper }: Props) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: notesWrapper.notes,
  })

  return (
    <>
      <StyledTable {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} >
                {row.cells.map(cell => {
                  switch (cell.column.id) {
                    case 'date':
                      return (
                        <td
                          {...cell.getCellProps({style: {
                            columnWidth: 150,
                            minWidth: 150
                          }})}
                        >
                          {moment(cell.value).format('DD MMM YYYY [at] h:mmA')}
                        </td>
                      )
                    case 'name':
                      return (
                        <td
                          {...cell.getCellProps({style: {
                            columnWidth: 150,
                            minWidth: 150
                          }})}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    default:
                      return (
                        <td
                          {...cell.getCellProps({style: {
                            paddingRight: 10,
                            columnWidth: 200,
                            minWidth: 200
                          }})}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
    </>
  )
};

export default NotesTable;