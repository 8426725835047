import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import BounceLoader from 'react-spinners/BounceLoader';
import { Col, Container, Row } from 'reactstrap';
import { UserCreationRolesEnum, UserUpdate } from '../../open-api';
import * as amberSdk from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { toast } from 'react-toastify';
import { accountStatusOptionList, adminRoleExists, countryCodeOptionList, IParamTypes, ISelectOptionItem, IsValidEmail, userRoleOptions } from '../../common/utils';
import CustomSelect from '../../components/custom-select/custom-select';
import { getRequestConfig } from '../../common/utils';
import { useHistory, useParams } from 'react-router-dom';
import mainAxios from '../../config/axios-config';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`
const CancelButton = styled.button`
  background-color: #D1D1D1;
  border-radius: 4px;
  color: #4A4A4A;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: none;
  margin-right: 20px;
  padding: 8px 26px;
  width: 100%;
  height: 32px;
`
const UpdateButton = styled.button`
  padding: 8px 0;
  width: 100%;
  background: #FFA300;
  border-radius: 4px;
  border: none;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`
const LoaderWrapper = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const emptyUser: UserUpdate = {
  name: '',
  mobile_contact: {
    country_calling_code: '+65',
    phone_number: '',
  },
  roles: [],
  email: '',
  status: 'ACTIVE',
}

function UpdateUser() {
  const history = useHistory();
  const { id } = useParams<IParamTypes>();

  const [newUser, setNewUser] = useState<UserUpdate>(emptyUser);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const userStore = useUserStore();

  useEffect(() => {
    if (!userStore.accessToken) {
      return;
    }

    if (userStore.user.id) {
      if (!adminRoleExists(userStore.user.roles)) {
        history.push("/not-found");
      }
    }

  }, [userStore.accessToken, userStore.user, history])

  /**
   * load all page data
   */
  useEffect(() => {
    let isSubscribed = true;

    if (!userStore.accessToken) {
      return
    }

    let userApi: amberSdk.UserApi = new amberSdk.UserApi(undefined, undefined, mainAxios)

    userApi.userGET(id, getRequestConfig(userStore.accessToken)).then((e) => {
      if (isSubscribed)
        setNewUser({
          name: e.data.name,
          mobile_contact: {
            country_calling_code: e.data.mobile_contact.country_calling_code,
            phone_number: e.data.mobile_contact.phone_number,
          },
          roles: e.data.roles,
          email: e.data.email,
          status: e.data.status,
        })
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).finally(() => {
      if (isSubscribed)
        setLoading(false);
    });

    return () => {
      isSubscribed = false
    };
  }, [id, userStore.accessToken, reloadData])


  function handleInputChange(field: string, value: any) {
    let tempOrder = update(newUser, {
      [field]: { $set: value }
    })
    setNewUser(tempOrder);
    if (field === 'name') {
      if (value) {
        if (nameError) {
          setNameError(false)
        }
      } else {
        if (!nameError) {
          setNameError(true)
        }
      }
    } else if (field === 'email') {
      if (IsValidEmail(value)) {
        if (emailError) {
          setEmailError(false)
        }
      } else {
        if (!emailError) {
          setEmailError(true)
        }
      }
    }
  }

  function handleMobileChange(field: string, value: any) {
    let tempOrder = update(newUser, {
      mobile_contact: {
        [field]: { $set: value }
      }
    })
    setNewUser(tempOrder);
    if (mobileError) {
      setMobileError(false)
    }
  }

  function validationError(): boolean {
    let haveError = false;
    if (!newUser.name) {
      if (!nameError) {
        setNameError(true)
      }
      haveError = true;
    } else {
      if (nameError) {
        setNameError(false)
      }
    }

    if (!newUser.mobile_contact.phone_number) {
      if (!mobileError) {
        setMobileError(true)
      }
      haveError = true;
    } else {
      if (mobileError) {
        setMobileError(false)
      }
    }

    if (!IsValidEmail(newUser.email)) {
      if (!emailError) {
        setEmailError(true)
      }
      haveError = true;
    } else {
      if (emailError) {
        setEmailError(false)
      }
    }

    return haveError;
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setLoading(true);

    if (validationError()) {
      setLoading(false);
      return;
    }

    let userApi: amberSdk.UserApi = new amberSdk.UserApi(undefined, undefined, mainAxios)

    userApi.userUpdatePOST(id, {
      name: newUser.name,
      mobile_contact: {
        country_calling_code: newUser.mobile_contact.country_calling_code,
        phone_number: newUser.mobile_contact.phone_number,
      },
      roles: newUser.roles,
      email: newUser.email,
      status: newUser.status,
    }, getRequestConfig(userStore.accessToken)).then((e) => {
      if (e.status === 200) {
        toast.success('Successfully updated', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setReloadData(!reloadData);
      }
    }).catch((e) => {
      if (e.response && (e.response.status === 400 || e.response.status === 403)) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (e.response.data.error === 'invalid_mobile_contact') {
          setMobileError(true);
        } else if (e.response.data.error === 'invalid_email') {
          setEmailError(true);
        }
      } else if (e.response && e.response.status === 500) {
        toast.error(e.response.data.error_description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <BounceLoader
          color={"#FFA300"}
          loading={loading}
          size={48}
          speedMultiplier={1}
        />
      </LoaderWrapper>
    )
  }

  return (
    <div>
      <HeaderWrapper>
        <h1>Manage User</h1>
        <span className='p1'>
          Update user's information.
        </span>
      </HeaderWrapper>
      <Container style={{ margin: 0, maxWidth: "none", padding: 0 }}>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            {`User Roles(s)*`}
          </Col>
          <Col md={4}>
            <CustomSelect
              closeMenuOnSelect={false}
              onChange={(e: Array<ISelectOptionItem>) => handleInputChange('roles', e.map(eItem => eItem.value))}
              options={userRoleOptions}
              placeholder='Select User Role(s)'
              isMulti
              value={userRoleOptions.filter(e => newUser.roles.includes(e.value as UserCreationRolesEnum))}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Name*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${nameError ? 'is-invalid' : ''}`}
              placeholder="Name"
              id="name"
              onChange={(e) => handleInputChange('name', e.target.value)}
              value={newUser.name}
            />
            <div className="invalid-feedback">Invalid name</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Email*
          </Col>
          <Col md={4}>
            <input
              type="text"
              className={`form-control fs-13px ${emailError ? 'is-invalid' : ''}`}
              placeholder="Email"
              id="email"
              onChange={(e) => handleInputChange('email', e.target.value)}
              value={newUser.email}
            />
            <div className="invalid-feedback">Invalid email</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            Mobile Number*
          </Col>
          <Col md={4} style={{ display: 'flex' }}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleMobileChange('country_calling_code', e.value)}
              options={countryCodeOptionList}
              value={countryCodeOptionList.find(e => e.value === newUser.mobile_contact.country_calling_code)}
            />
            <input
              type="text"
              className={`form-control fs-13px ${mobileError ? 'is-invalid' : ''}`}
              placeholder="Mobile Number"
              id="mobileNumber"
              onChange={(e) => handleMobileChange('phone_number', e.target.value)}
              value={newUser.mobile_contact.phone_number}
            />
            <div className="invalid-feedback">Invalid mobile contact</div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={2}>
            {`Account Status`}
          </Col>
          <Col md={4}>
            <CustomSelect
              onChange={(e: ISelectOptionItem) => handleInputChange('status', e.value)}
              options={accountStatusOptionList}
              placeholder='Select User Role(s)'
              value={accountStatusOptionList.find(e => e.value === newUser.status) || newUser.status}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={3}>
          </Col>
          <Col md={1}>
            <CancelButton type='button' onClick={history.goBack}>
              Cancel
            </CancelButton>
          </Col>
          <Col md={2}>
            <UpdateButton type='submit' onClick={handleSubmit}>
              + Update User
            </UpdateButton>
          </Col>
        </Row>
      </Container>

    </div>
  )
};

export default UpdateUser;