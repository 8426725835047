export const columnsDefinition = [
  {
    Header: 'Contact Name',
    accessor: 'name',
  },
  {
    Header: 'Mobile Number',
    accessor: 'mobile_contact',
  },
  {
    Header: 'Clinic',
    accessor: 'clinic_name',
  },
  {
    Header: 'Date Created',
    accessor: 'date_created',
  },
  {
    Header: 'Account Status',
    accessor: 'status',
  },
];