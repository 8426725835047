import React, { useState, useEffect } from "react";
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`
const ButtonsWrapper = styled.div`
  display: flex;
  button {
    border-radius: 4px;
    height: 35px;
    font-size: 14px;
    line-height: 16px;
    padding: 6px 8px;
    margin: 5px;
    border: 1px solid #DADADA;

    :disabled {
      background-color: #D7D7D7;
      color: #F1F1F1;
    }
  }
`
const PageButton = styled.button`
  color: #4A4A4A;
  background-color: transparent;
  font-weight: 700;
`
const CurrentPageButton = styled.button`
  color: #FFFFFF;
  background-color: #FFA300;
  font-weight: 700;
`
const SideButton = styled.button`
  color:  #4A4A4A;
  background-color: transparent;
  font-weight: 600;
`
const PaginationDots = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #4A4A4A;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 5px;
`

const FrontDots = ({ pageMeta }) => {
  if (pageMeta.total_page < 6 ||
    pageMeta.page < 3
  ) {
    return (<></>)
  }
  return (<PaginationDots>...</PaginationDots>)
}

const EndDots = ({ pageMeta }) => {
  if (pageMeta.total_page < 6 ||
    pageMeta.page + 2 > pageMeta.total_page) {
    return (<></>)
  }
  return (<PaginationDots>...</PaginationDots>)
}

// pagination start from 1
const Pagination = ({ pageChangeHandler, pageMeta }) => {

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const [pagesArr, setPagesArr] = useState([])

  // Onclick handlers for the butons
  const onNextPage = () => pageChangeHandler(pageMeta.page + 1);
  const onPrevPage = () => pageChangeHandler(pageMeta.page - 1);
  const onPageSelect = (pageNo) => {
    pageChangeHandler(pageNo)
  };

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (pageMeta.total_page === pageMeta.page) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (pageMeta.page === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }

    if (pageMeta.total_page < 6) {
      setPagesArr([...new Array(pageMeta.total_page)])
    }
  }, [pageMeta.total_page, pageMeta.page]);

  return (
    <>
      {pageMeta.total_page > 1 ? (
        <Wrapper>
          <ButtonsWrapper>
            <SideButton
              onClick={onPrevPage}
              disabled={!canGoBack}
              key="previousButton"
            >
              Previous
            </SideButton>
            {
              (pageMeta.page === 1) ? (
                <CurrentPageButton key={1}>
                  {1}
                </CurrentPageButton>
              ) : (
                <PageButton key={1} onClick={() => onPageSelect(1)}>
                  {1}
                </PageButton>
              )
            }
            {<FrontDots pageMeta={pageMeta} />}
            {pageMeta.total_page > 2 && pageMeta.total_page < 6 ? (pagesArr.map((num, index) => {
              if (index === 0 || index + 1 === pageMeta.total_page) {
                return <></>
              }
              else if (index + 1 === pageMeta.page) {
                return (<CurrentPageButton key={`page${index}`}>
                  {index + 1}
                </CurrentPageButton>)
              } else {
                return (<PageButton key={`page${index}`} onClick={() => onPageSelect(index + 1)}>
                  {index + 1}
                </PageButton>)
              }
            })) :
              (((pageMeta.page !== 1) && (pageMeta.page !== pageMeta.total_page)) &&
                <CurrentPageButton key={pageMeta.page}>
                  {pageMeta.page}
                </CurrentPageButton>)
            }
            {<EndDots pageMeta={pageMeta} />}
            {
              (pageMeta.page === pageMeta.total_page) ? (
                <CurrentPageButton key={`page${pageMeta.total_page}`}>
                  {pageMeta.total_page}
                </CurrentPageButton>
              ) : (
                <PageButton key={`page${pageMeta.total_page}`} onClick={() => onPageSelect(pageMeta.total_page)}>
                  {pageMeta.total_page}
                </PageButton>
              )
            }
            <SideButton
              onClick={onNextPage}
              disabled={!canGoNext}
              style={{
                marginRight: 0,
              }}
              key="nextButton"
            >
              Next
            </SideButton>
          </ButtonsWrapper>
        </Wrapper>
      ) : null}
    </>
  );
};

export default Pagination;
