import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { orderStatusEnum, OrderStatusIcon } from '../../components/order-status/order-status';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { StyledTable, ViewButton } from '../../common/styles';
import ThumbnailImage from '../../components/thumbnail/thumbnail';

const DashboardOrdersTable = ({
  columns,
  data,
}) => {
  const rowData = useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: rowData,
    manualSortBy: true,
  }
  )

  return (
    <>
      <StyledTable {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
              <th style={{ backgroundColor: '#F0F0F0' }}
                key="viewButton"
              />
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length ?
            rows.map(row => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} style={row.original.order_status === orderStatusEnum.ORDER_CANCELLED ? { backgroundColor: '#F0F0F0' } : {}} >
                  {row.cells.map(cell => {
                    switch (cell.column.id) {
                      case 'remarks':
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={
                              {
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '20px',
                              }
                            }
                          >
                            {cell.value}
                          </td>
                        )
                      case 'order_status':
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            <OrderStatusIcon status={cell.value} />
                          </td>
                        )
                      case 'image':
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            <ThumbnailImage orderNumber={row.original.order_number} />
                          </td>
                        )
                      case 'date_of_order':
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {moment(cell.value).format('DD MMM YYYY [at] h:mmA')}
                          </td>
                        )
                      case 'date_of_estimated_delivery':
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.value ? moment(cell.value).format('DD MMM YYYY') : '-'}
                          </td>
                        )
                      case 'date_delivered':
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.value ? moment(cell.value).format('DD MMM YYYY') : '-'}
                          </td>
                        )
                      default:
                        return (
                          <td
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                    }
                  })}

                  <td {...row.cells[0].getCellProps()}>
                    <Link to={`/order/${row.original.order_number}`}>
                      <ViewButton>
                        View
                      </ViewButton>
                    </Link>
                  </td>
                </tr>
              )
            })
            :
            <tr>
              <td>
                No records found.
              </td>
            </tr>
          }
        </tbody>
      </StyledTable>
    </>
  )
}

export default DashboardOrdersTable;