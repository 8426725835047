import React, { useState, useEffect } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import 'react-datetime/css/react-datetime.css';
import { toast } from 'react-toastify';
import { pendingCompletionColumns } from './table-meta';
import * as amberSdk from '../../open-api';
import { OrdersRetrievalOrders } from '../../open-api';
import { useUserStore } from '../../store/userStore';
import { getRequestConfig } from '../../common/utils';
import {
  ListContentWrapper, ListHeader, ListHeaderWrapper, ListWrapper, LoaderWrapper,
} from '../../common/styles';
import DashboardOrdersTable from './dashboard-orders-table.jsx';
import { getSortColumn, getStatusFilterArray } from '../order-list/order-list';
import mainAxios from '../../config/axios-config';

function PendingCompletionListing() {
  const [data, setData] = useState<Array<OrdersRetrievalOrders>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const userStore = useUserStore();

  useEffect(() => {

    let isSubscribed = true;

    if (!userStore.accessToken) {
      return;
    }

    // pull the data here and set loading to false when completed
    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios);

    orderApi.ordersGET(1, 5,
      undefined, undefined,
      getStatusFilterArray('DELIVERED'),
      undefined, undefined,
      undefined, getSortColumn('date_of_order'), "DESC",
      getRequestConfig(userStore.accessToken)
    ).then((e) => {
      if (isSubscribed)
        setData(e.data.orders)
    }).catch((e) => {
      if (e.response && e.response.status === 500) {
        toast.error('An error has occured. Please try again later.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (isSubscribed)
        setData([])
    }).finally(() => {
      if (isSubscribed)
        setLoading(false);
    });
    
    return () => {
      isSubscribed = false
    };

  }, [userStore.accessToken])

  return (
    <div>
      <ListWrapper>
        <ListHeaderWrapper>
          <ListHeader>Pending Completion Orders</ListHeader>
        </ListHeaderWrapper>
        <ListContentWrapper>
          {/* main table */}
          <div>
            {
              loading ?
                <LoaderWrapper>
                  <BounceLoader
                    color={"#FFA300"}
                    loading={loading}
                    size={48}
                    speedMultiplier={1}
                  />
                </LoaderWrapper> :
                <DashboardOrdersTable data={data} columns={pendingCompletionColumns} />
            }
          </div>
        </ListContentWrapper>
      </ListWrapper>
    </div>
  )
};

export default PendingCompletionListing;