
export const columnsDefinition = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Mobile',
    accessor: 'mobile_contact',
  },
  {
    Header: 'Orders on Hand',
    accessor: 'orders_on_hand',
  },
  {
    Header: 'Amber Branch',
    accessor: 'branch_code',
  },
  {
    Header: 'Account Status',
    accessor: 'status',
  },
];
