import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useUserStore } from '../../store/userStore';
import * as amberSdk from '../../open-api';
import BounceLoader from 'react-spinners/BounceLoader';
import ThumbnailModal from '../modal/thumbnail-modal';
import mainAxios from '../../config/axios-config';

const ThumbnailStyle = styled.img`
  width: 24px;
  height: 24px;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  cursor: pointer;
`

interface IProps {
  orderNumber: string;
}
function ThumbnailImage({ orderNumber }: IProps) {

  const userStore = useUserStore();
  const [loading, setLoading] = useState(true);
  const [imageBase64, setImageBase64] = useState('');
  const [thumbnail, setThumbnail] = useState({
    blob: "",
    id: ""
  });

  useEffect(() => {

    let isSubscribed = true;

    if (!userStore.accessToken) {
      return
    }
    let orderApi: amberSdk.OrderApi = new amberSdk.OrderApi(undefined, undefined, mainAxios)

    orderApi.orderImageGET(orderNumber,
      {
        headers: {
          Authorization: `Bearer ${userStore.accessToken}`,
        },
        responseType: 'blob'
      }
    ).then((e) => {
      if (isSubscribed)
        setImageBase64(URL.createObjectURL(e.data));
    }).finally(() => {
      if (isSubscribed)
        setLoading(false);
    });

    return () => {
      isSubscribed = false
    };

  }, [userStore.accessToken, orderNumber])

  if (loading) {
    return (
      <BounceLoader
        color={"#66bcff"}
        loading={loading}
        size={20}
        speedMultiplier={1}
      />
    )
  }
  return (
    <>
      <ThumbnailStyle src={imageBase64} onClick={() => setThumbnail({ blob: imageBase64, id: orderNumber })} />
      {
        thumbnail.id && <ThumbnailModal title={'Order Photo'} modalIsOpen={thumbnail.id !== ""} handleClose={() => setThumbnail({
          blob: "",
          id: ""
        })} data={thumbnail} />
      }
    </>
  );
};

export default ThumbnailImage;