import React from 'react';
import styled from 'styled-components'


const Button = styled.button`
    font-weight: 700;
    font-size: 14px; !important
    line-height: 16px;
`

const DefaultButton = (props) => {
    return <Button { ...props } className={props.className}>{props.children}</Button>
}

export default DefaultButton;