import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AccountStatusIcon } from '../../components/account-status/account-status';
import { SortDirectionIcon } from '../../components/sort-arrow/sort-arrow';
import { StyledTable, ViewButton } from '../../common/styles';

const CompoundingTechTable = ({
  columns,
  data,
  onSort,
  sortOptions,
}) => {
  const rowData = useMemo(() => data, [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: rowData,
  })

  return (
    <>
      <StyledTable {...getTableProps()} >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  onClick={() => onSort(
                    column.id
                  )}
                >
                  {column.render('Header')}
                  <span>
                    <SortDirectionIcon sortOptions={sortOptions} column={column.id} />
                  </span>
                </th>
              ))}
              <th style={{ backgroundColor: '#F0F0F0' }}
                key="viewButton"
              />
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.length ?
              rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} style={row.original.status === 'INACTIVE' ? { backgroundColor: '#F0F0F0' } : {}} >
                    {row.cells.map(cell => {
                      switch (cell.column.id) {
                        case 'status':
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              <AccountStatusIcon status={cell.value} />
                            </td>
                          )
                        case 'date_created':
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              {moment(cell.value).format('DD MMM YYYY')}
                            </td>
                          )
                        case 'mobile_contact':
                          if (!cell.value) {
                            return (
                              <td
                                {...cell.getCellProps()}
                              >
                                {``}
                              </td>
                            )
                          }
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              {`${cell.value.country_calling_code} ${cell.value.phone_number}`}
                            </td>
                          )
                        default:
                          return (
                            <td
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          )
                      }
                    })}
                    <td {...row.cells[0].getCellProps()}>
                      <Link to={`/compounding-tech/${row.original.id}`}>
                        <ViewButton>
                          View
                        </ViewButton>
                      </Link>
                    </td>
                  </tr>
                )
              })
              :
              <tr>
                <td>
                  No records found.
                </td>
              </tr>
          }
        </tbody>
      </StyledTable>
    </>
  )
}

export default CompoundingTechTable;