import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import routes, { IRouteArrayItem } from '../../config/app-route';
import { AppSettings } from '../../config/app-settings.js';
import SessionTimeout from '../session/session-timeout';


function setTitle(path: string, routeArray: Array<IRouteArrayItem>) {
  var appTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      appTitle = "Amber's Dispensary | " + routeArray[i].title;
    }
  }
  document.title = (appTitle) ? appTitle : "Amber's Dispensary";
}

type ContentTypeProps = {
  appContentClass: string
}

function ContentComponent({ appContentClass }: ContentTypeProps) {
  const history = useHistory();

  useEffect(() => {
    setTitle(history.location.pathname, routes);
    // list of unprotected paths
    if (history.location.pathname === '/login' ||
      history.location.pathname === '/password/forget' ||
      history.location.pathname.includes('/password/reset')
    ) {
      return;
    }
    let refreshToken = localStorage.getItem('amber_refresh_token') || '';

    if (!refreshToken) {
      history.push('/login');
      return;
    }
  }, [history, history.location.pathname]);


  if (history.location.pathname === '/login' ||
    history.location.pathname === '/password/forget' ||
    history.location.pathname.includes('/password/reset')
  ) {
    return (<div className={'app-content ' + appContentClass}>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </div>)
  } else {
    return (<div className={'app-content ' + appContentClass}>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
      <SessionTimeout />
    </div>)
  }
}

export default class ContentClass extends React.Component<ContentTypeProps, {}> {
  render() {
    return (
      <AppSettings.Consumer>
        {({ appContentClass }) =>
        (
          <ContentComponent appContentClass={appContentClass} />
        )
        }
      </AppSettings.Consumer>
    )
  }
}
