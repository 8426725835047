import React from 'react';
import { Link } from 'react-router-dom';
import DropdownProfile from './dropdown/profile.jsx';

import { AppSettings } from './../../config/app-settings.js';

class Header extends React.Component {

	render() {
		return (
			<AppSettings.Consumer>
				{({ toggleAppSidebarMinify, toggleAppSidebarMobile, toggleAppSidebarEnd, toggleAppSidebarEndMobile, appSidebarTwo, appSidebarNone }) => (
					<div id="header" className={'app-header'}>
						<div className="navbar-header">
							<button type="button" className="navbar-desktop-toggler" onClick={toggleAppSidebarMinify}>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<button type="button" className="navbar-mobile-toggler">
								...
							</button>
							{appSidebarTwo && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarEndMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							<Link to="/" className="navbar-brand">
								<span className="navbar-logo"></span> 
								<h2 style={{marginBottom: 0}}>Amber's Dispensary</h2> 
								</Link>

							{!appSidebarNone && (
								<button type="button" className="navbar-mobile-toggler" onClick={toggleAppSidebarMobile}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
						</div>

						<div className="navbar-nav">
							<div className="navbar-form" />
							<DropdownProfile />

							{appSidebarTwo && (
								<div className="navbar-divider d-none d-md-block"></div>
							)}

							{appSidebarTwo && (
								<div className="navbar-item d-none d-md-block">
									<Link to="/" onClick={toggleAppSidebarEnd} className="navbar-link icon">
										<i className="fa fa-th"></i>
									</Link>
								</div>
							)}
						</div>
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default Header;
