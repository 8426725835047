import styled from 'styled-components';
import Card from '../../components/card/card';
import moment from 'moment';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4A4A4A;
    margin-bottom: 0;
  }
`

interface Props {
  id: string,
  remarks: string,
  dateOfOrder: string,
}

function OrderDetails({ id, remarks, dateOfOrder }: Props) {
  return (
    <Card header="Order Details">
      <BodyWrapper>
        <h5>Order ID</h5>
        <p>
          #{id}
        </p>
        <hr />
        <h5>Remarks</h5>
        <p style={{
    wordWrap: 'break-word'}}>
          {remarks}
        </p>
        <hr />
        <h5>Date of Order</h5>
        <p>
          {moment(dateOfOrder).format('DD MMM YYYY [at] h:mmA')}
        </p>
      </BodyWrapper>
    </Card>
  )
};

export default OrderDetails;