import React from 'react';
// import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import styled from 'styled-components'
import BounceLoader from 'react-spinners/BounceLoader';
import DefaultButton from '../../components/buttons/default-button.jsx';
import { useUserStore } from '../../store/userStore';
import * as amberSdk from '../../open-api';
import { IsValidEmail } from '../../common/utils';
import { getRequestConfig } from '../../common/utils'
import { toast } from 'react-toastify';

const ForgetPasswordDiv = styled.div`
	margin-bottom: 3em;
	float: right;
`
const FooterDiv = styled.div`
	margin-bottom: 3em;
  position: fixed;
  bottom: 0%;
`
const BackButtonDiv = styled.div`
  margin-top: 2em;
  position: fixed;
  top: 0%;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  a {
    color: #4A4A4A;
    text-decoration: none;
  }
`
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const ResendSpan = styled.span`
  text-decoration: underline;
  float: right;
  cursor: pointer;
  margin-bottom: 16px;
`
const PasswordResetDiv = styled.div`
  background: #ECFFEE;
  border: 2px solid #5FAE67;
  border-radius: 6px;
  padding: 12px 16px;
  margin-top: 16px
`
const LoginContent = (props) => {
  const history = useHistory();

  let reset = new URLSearchParams(history.location.search).get('reset-password');

  if (props.isLoading) {
    return (
      <div className="login-container">
        <LoaderWrapper>
          <BounceLoader
            color={"#FFA300"}
            loading={props.isLoading}
            size={48}
            speedMultiplier={1}
          />
        </LoaderWrapper>
      </div>
    );
  }
  return (
    <div className="login-container">
      <img style={{
        height: '140px',
        width: '280px',
        margin: '0 auto',
      }} src='/assets/img/logo/img-amber-logo-login@3x.png' alt='amber logo' />
      <div className="login-header">
        <div className="brand">
          <div className="d-flex align-items-center">
            <span className="logo"></span>
            Login
          </div>
          <span className='p1'>Amber's Dispensary</span>
        </div>
      </div>
      {
        reset &&
        <PasswordResetDiv>
          You have successfully reset your password.
          <br />
          Please log in with your new password.
        </PasswordResetDiv>
      }

      <div className="login-content mt-30px">
        <form onSubmit={(e) => props.handleSubmit(e)} className="fs-13px">
          <div className="form mb-15px">
            <input
              type="text"
              className={`form-control fs-13px ${props.emailError ? 'is-invalid' : ''}`}
              placeholder="Email Address"
              id="emailAddress"
              onChange={(e) => props.handleUpdateStateValue('loginEmail', e.target.value)}
              value={props.loginEmail}
            />
            <div className="invalid-feedback">Invalid email</div>
          </div>
          <div className="form mb-15px">
            <input
              type="password"
              className={`form-control fs-13px ${props.passwordError ? 'is-invalid' : ''}`}
              placeholder="Password"
              id="password"
              onChange={(e) => props.handleUpdateStateValue('loginPassword', e.target.value)}
              value={props.loginPassword}
            />
            <div className="invalid-feedback">Invalid password</div>
          </div>
          <ForgetPasswordDiv>
            <Link to="/password/forget">Forget Password</Link>
          </ForgetPasswordDiv>
          <div className="mb-15px">
            <DefaultButton type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px" onClick={(e) => props.handleSubmit(e)}>Retrieve OTP</DefaultButton>
          </div>
          <FooterDiv>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              Copyright &copy; 2022 Amber Compounding Pharmacy Pte Ltd
            </div>
          </FooterDiv>
        </form>
      </div>
    </div>
  )
}

const OTPContent = (props) => {
  if (props.isLoading) {
    return (
      <div className="login-container">
        <LoaderWrapper>
          <BounceLoader
            color={"#FFA300"}
            loading={props.isLoading}
            size={48}
            speedMultiplier={1}
          />
        </LoaderWrapper>
      </div>
    );
  }
  return (
    <div className="login-container">
      <BackButtonDiv onClick={props.handleBackButtonClick}>
        <img src='/assets/img/svg/ic-back-button.svg' alt='' /> Back
      </BackButtonDiv>
      <div className="login-header mb-30px">
        <div className="brand">
          <div className="d-flex align-items-center">
            <span className="logo"></span>
            OTP Verification
          </div>
          <p className='p1'>An OTP has been sent to your mobile number.</p>
        </div>
      </div>
      <div>
        <ResendSpan onClick={() => props.handleResend()}>
          Resend OTP
        </ResendSpan>
      </div>
      <div className="login-content">
        <form onSubmit={(e) => props.handleSubmit(e)} className="fs-13px">
          <div className="form mb-15px">
            <input type="text"
              className={`form-control fs-13px ${props.OTPError ? 'is-invalid' : ''}`}
              placeholder="OTP"
              id="otp"
              onChange={(e) => props.handleUpdateStateValue('loginOTP', e.target.value)}
              value={props.loginOTP} />
            <div className="invalid-feedback">Invalid OTP</div>
          </div>
          <div className="mb-15px" style={{
            marginTop: '48px'
          }}>
            <DefaultButton type="submit" className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">Login</DefaultButton>
          </div>
          <FooterDiv>
            <hr className="bg-gray-600 opacity-2" />
            <div className="text-gray-600 text-center text-gray-500-darker mb-0">
              Copyright &copy; 2022 Amber Compounding Pharmacy Pte Ltd
            </div>
          </FooterDiv>
        </form>
      </div>
    </div>
  )
}

class LoginV3 extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      loginEmail: '',
      loginPassword: '',
      loginOTP: '',
      OTPNumber: '',
      loginSuccess: false,
      loginLoading: false,
      emailError: false,
      passwordError: false,
      OTPSuccess: false,
      OTPLoading: false,
      OTPError: false,
    }

    this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
    this.handleSubmitOtp = this.handleSubmitOtp.bind(this);
    this.handleResendOtp = this.handleResendOtp.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass('p-0');
    if (this.props.userStore.accessToken) {
      let profileApi = new amberSdk.ProfileApi();
      profileApi.meGET(getRequestConfig(this.props.userStore.accessToken)
      ).then((e) => {
        this.props.userStore.setUser(e.data)
        this.props.history.push('/')
      })
    }
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass('');
  }

  handleSubmitLogin(event) {
    event.stopPropagation()
    event.preventDefault();

    this.setState({
      loginLoading: true,
    })

    let authApi = new amberSdk.AuthApi();
    // login post api
    authApi.loginPOST({
      email: this.state.loginEmail,
      password: this.state.loginPassword,
    }).then((e) => {
      this.setState({
        loginLoading: false,
        loginSuccess: true,
        OTPNumber: `xxxx ${e.data.phone_number_ending}`
      })
      toast.success(`OTP Sent to xxxx ${e.data.phone_number_ending}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch((e) => {
      if (e.response) {
        toast.error(`${e.response.data.error_description}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.setState({
        emailError: true,
        passwordError: true,
      });
    }).finally((e) => {
      this.setState({
        loginLoading: false,
      });
    })
  }

  handleResendOtp() {
    let authApi = new amberSdk.AuthApi();
    console.log(this.state);
    // login post api
    authApi.loginPOST({
      email: this.state.loginEmail,
      password: this.state.loginPassword,
    }).then((e) => {
      toast.success(`OTP Sent to xxxx ${e.data.phone_number_ending}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch((e) => {
      if (e.response) {
        toast.error(`${e.response.data.error_description}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).finally((e) => {
    })
  }

  handleSubmitOtp(event) {
    event.preventDefault();

    this.setState({
      OTPLoading: true,
    })
    let authApi = new amberSdk.AuthApi();
    // login post api
    authApi.loginOtpPOST({
      email: this.state.loginEmail,
      otp: this.state.loginOTP,
    }).then((e) => {
      this.setState({
        OTPSuccess: true,
      });
      this.props.userStore.setAccessToken(e.data.access_token);
      localStorage.setItem('amber_refresh_token', e.data.refresh_token);
      // get profile after otp success
      let profileApi = new amberSdk.ProfileApi();
      profileApi.meGET(getRequestConfig(e.data.access_token)).then((e) => {
        this.props.userStore.setUser(e.data);
        this.props.history.push('/');
      })
    }).catch((e) => {
      if (e.response) {
        toast.error(`${e.response.data.error_description}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.setState({
        OTPError: true,
        OTPLoading: false,
      });
    })
  }

  updateStateValue(id, value) {
    this.setState({
      [id]: value
    })

    if (id === 'loginEmail') {
      if (IsValidEmail(value)) {
        this.setState({
          emailError: false
        })
      } else {
        this.setState({
          emailError: true
        })
      }
    } else if (id === 'loginPassword') {
      if (this.state.passwordError) {
        this.setState({ passwordError: false });
      }
    } else if (id === 'loginOTP') {
      if (this.state.OTPError) {
        this.setState({ OTPError: false });
      }
    }
  }

  handleBackButtonClick() {
    this.setState({
      loginSuccess: false
    })
  }

  render() {
    return (
      <div className="login login-with-news-feed">
        <div className="news-feed">
          <div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/img-admin-bacgkground.jpg)' }}></div>
        </div>
        {this.state.loginSuccess ?
          <OTPContent
            handleSubmit={this.handleSubmitOtp}
            handleResend={this.handleResendOtp}
            handleUpdateStateValue={this.updateStateValue}
            isLoading={this.state.OTPLoading}
            loginOTP={this.state.loginOTP}
            OTPError={this.state.OTPError}
            OTPNumber={this.state.OTPNumber}
            handleBackButtonClick={this.handleBackButtonClick}
          />
          :
          <LoginContent
            handleSubmit={this.handleSubmitLogin}
            handleUpdateStateValue={this.updateStateValue}
            loginEmail={this.state.loginEmail}
            loginPassword={this.state.loginPassword}
            isLoading={this.state.loginLoading}
            emailError={this.state.emailError}
            passwordError={this.state.passwordError}
          />
        }
      </div>
    )
  }
}

const withStore = BaseComponent => props => {
  const userStore = useUserStore();
  return <BaseComponent {...props} userStore={userStore} />;
};

export default withRouter(withStore(LoginV3));