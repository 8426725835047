import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useUserStore } from '../../../store/userStore';

class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	handleLogout() {
		this.props.userStore.unsetUser();
		localStorage.removeItem('amber_refresh_token');
		this.props.history.push('/login');
	}

	render() {
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="navbar-item navbar-user dropdown" tag="div">
				<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
					<span>
						<img src="/assets/img/svg/ic-profile-icon.svg" alt="" />
						<span className="d-none d-md-inline" style={{marginRight: "16px", marginLeft: "16px"}}>{this.props.userStore.user && this.props.userStore.user.name}</span>
						<b className="caret"></b>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
					<Link to="/profile" style={{ textDecoration: 'none' }}>
						<DropdownItem>Your Profile</DropdownItem>
					</Link>
					<div className="dropdown-divider"></div>
					<DropdownItem onClick={() => this.handleLogout()}>Sign Out</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

const withStore = BaseComponent => props => {
	const userStore = useUserStore();
	return <BaseComponent {...props} userStore={userStore} />;
};

export default withRouter(withStore(DropdownProfile));
